import { useState } from "react";
import {ReactComponent as ClickIcon} from '../../assets/click-icon.svg';
import './square-show.styles.scss'
const SquareShow = ({title,color,textColor,showTextBox}) => {
    const [showText,setShowText] = useState(showTextBox);
    return (
        <>
        <div className="square-box" style={{backgroundColor:color}} onClick={() => setShowText(true)}>
           
            {!showText ? <ClickIcon/> :  <h4 style={{color:textColor}}>{title}</h4>}
        </div>
        
        </>
    )
}

export default SquareShow;