import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const StandardAdvertisementtemplate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,1,user.uid,3);
        getUser();
        navigate("../Referrals")
    }
    return (
        <>
          <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Standard advertisement template"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="StandardAdvertisementtemplate"
            prevLink="ApproachAndSteps"
            nextLink="Referrals"
            chapterNumber='6'
            />
        <h1>Standard advertisement template</h1>
        <img style={{width:"100%"}} src={require("../../assets/standard-ad.png")} alt="Standard Advertisement Template" />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default StandardAdvertisementtemplate;