import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const LaboursVsEquipment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(1,1,user.uid);
        getUser();
        navigate("../lrbtvscfw")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Local Resource Based Technology" 
            subTitle="Labour vs Equipment in construction"
            chapterLink="LocalResourceBasedTechnology"
            subLink="Labourvsequipmentinconstruction"
            prevLink="lrbt-video"
            nextLink="lrbtvscfw"
            chapterNumber="2"
            />
        <h1>Labour vs Equipment in construction</h1>
        <p>
        Two main divergent schools apply in the construction sector – Labour intensive, or equipment based methods. The Local Resource Based Methodology combines the best of both worlds to offer an efficient and impactful approach in construction.
        </p>
        <img className="labor-vs-equipment" src={require('../../assets/labourvsequipment-2.png')} alt="" />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default LaboursVsEquipment;