import './chapters.styles.scss'
import { useState } from 'react';
import { Outlet} from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectUserInfo,selectCurrentUser } from '../../store/user/user.selector';
import { Link } from 'react-router-dom';
import { startChapters } from '../../utils/firebase.utils';
import { useEffect } from 'react';
import { getUsers } from '../../utils/firebase.utils';
import { setUserInfo } from '../../store/user/user.action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const Chapters = () => {
    const MyLogo = ({fill,stroke}) => {
        return (
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" fill={fill}/>
<path d="M13.225 19.475C12.975 19.475 12.7375 19.375 12.5625 19.2L9.02499 15.6625C8.66249 15.3 8.66249 14.7 9.02499 14.3375C9.38749 13.975 9.98749 13.975 10.35 14.3375L13.225 17.2125L19.65 10.7875C20.0125 10.425 20.6125 10.425 20.975 10.7875C21.3375 11.15 21.3375 11.75 20.975 12.1125L13.8875 19.2C13.7125 19.375 13.475 19.475 13.225 19.475Z" fill="white"/>
</svg>
 )
    }
    const chapters = [
        {
            "id" : 0,
            "title" : "Introduction to EIIP & LRBT",
            "url" : "IntrotoEIIP&LRBT",
            "number" : 1
        },
        {
            "id" : 1,
            "title" : "Local Resource Based Technology",
            "url":"LocalResourceBasedTechnology",
            "number" : 2
        },
        {
            "id" : 2,
            "title" : "Labour Management and Work Plan",
            "url":"LabourManagementandWorkPlan",
            "number" : 3
        },
        {
            "id" : 3,
            "title" : "Task Rates and Productivity Norms",
            "url":"TaskRatesandProductivityNorms",
            "number" : 4
        },
        {
            "id" : 4,
            "title" : "Unit Rate Analysis and Pricing BoQ for LRB Works",
            "url":"UnitRateAnalysisandPricingBoQforLRBWorks",
            "number" : 5
        },
        {
            "id" : 5,
            "title" : "Environment and Social Safeguard Framework and Social Safeguard Officer",
            "url":"EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer",
            "number" : 6
        },
        {
            "id" : 6,
            "title" : "Contract Administration & Particular Conditions",
            "url":"ContractAdministration&ParticularConditions",
            "number" : 7
        },
        {
            "id" : 7,
            "title" : "Final Quiz",
            "url":"final-quiz",
            "number" : 8
        }
]
    
  
    const userChapters = useSelector(selectUserInfo);
    const a4 = userChapters.chapters;
    
   
    const a3 = chapters.map((t1) => ({ ...t1, ...a4.find((t2) => t2.id === t1.id) })); 
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser)
    const [lastLink, setLastLink] = useState("");
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
      }

      const handleLastLink = () => {
        const a6 = a3[5].questions.map((item => 
            item.questions.find((x)=>  x.status==false )))
            let a8 = [];
     const a7 = a6.map((item) => {
        if(item === undefined){
          
        } else if ( item){
            a8.push(item)
        }
     })
     setLastLink(a8.find((x) => x.status === false).url)
    
      }
     
    
  
    const navigate = useNavigate()
   
    useEffect(() => {
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[user])
    return (
        <>
        
        <div className='chapters-wrapper'>
            <div className='chapters-holder'>
               
                {a3.map((item) => 
                    <div key={item.id} className='chaptersBlock'> 
                    
                        <div >
                        <div className='chapterNumber'>{item.number}</div>
                        <h2> {item.title}</h2>
                        
                        </div>
                    <Link to={(item.url + "/") + (item.id !== 5 ? item.questions.find((x,z) => x.status === false) === undefined ? item.questions.find((x,z) => x.status === true).url : item.questions.find((x,z) => x.status === false).url : "../../chapters/EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer/Overview" )}>
                        <button onClick={() => {if(item.status === 'start'){startChapters();getUser()}}} disabled={!item.canStart} className={item.status === "progress" ? "progress" : item.status === "start" ? "" : item.status === "completed" ? "completed" : ""}>
                            {item.status === "start" ? "GET STARTED" : item.status === "progress" ? "RESUME" : item.status === "completed" ? "COMPLETED" : "Locked" }
                        </button>
                    </Link>
                    </div>
                )}
            </div>
            
        </div>
        <Outlet/>
        
        
        </>
    )
}


export default Chapters;