import RedPolyList from "../red-poly-list/red-poly-list.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const WorkPlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(2,6,user.uid);
        getUser();
        navigate("../SampleofaBoQ")
    }
    const details = [
        {
            id:0,
            text:"BoQ (same BoQ as the tender)"
        },
        {
            id:1,
            text:"Duration of the project on a bi-weekly basis"
        },
        {
            id:2,
            text:"Percentage completion on a bi-weekly basis"
        },

        {
            id:3,
            text:"Number of workers per activity"
        },
        {
            id:4,
            text:"Number of work days per activity"
        },
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Labour Management and Work Plan" 
            subTitle="Work Plan"
            chapterLink="LocalResourceBasedTechnology"
            subLink="WorkPlan"
            prevLink="WorkPlanConsiderations"
            nextLink="SampleofaBoQ"
            chapterNumber='3'
            />
        <h1>Work Plan</h1>
        <p>The work plan must be submitted in excel format as provided along the tender documents for all contractors. The work plan will consist of the following:</p>
        <RedPolyList list={details}/>
        <p>The purpose of the workplan is for the contractor to have a clear plan of project execution and workdays generation. This will help the contractor and ILO team to supervise progress and ensure timely deliverables.</p>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default WorkPlan;