import {ReactComponent as RightDirection} from '../../assets/blue-angle.svg';
import {ReactComponent as EyeIcon} from '../../assets/finger-touch.svg';
import './intensive-infrastructure.styles.scss'
import { useState } from 'react';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { updateChapters } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import RedPolyList from '../red-poly-list/red-poly-list.component';
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const IntensiveInfrastructure = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
      }
        const handleUpdates = () => {
        updateChapters(0,2,user.uid);
        getUser();
        navigate("../eiip-in-lebanon")
    }
    const [circle1, setCircle1] = useState(false);
    const [circle2, setCircle2] = useState(false);
    const [circle3, setCircle3] = useState(false);
    const [circle4, setCircle4] = useState(false);
    const [circleIndex, setCircleIndex] = useState(0);
    const list = [
        {
            id:0,
            text:"Ensuring Decent Working Conditions"
        },
        {
            id:1,
            text:"Promoting inclusion and equal opportunities"
        },
        {
            id:2,
            text:"Operating under the Local Resource Based Technology"
        },
        {
            id:3,
            text:"Working under the umbrella of the Environmental & Social Safeguard Framework"
        },
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Intro to EIIP & LRBT" 
            subTitle="The Employment Intensive Infrastructure Programme (EIIP)"
            chapterLink="IntrotoEIIP&LRBT"
            subLink="the-employment-intensive-infrastructure-programme"
            prevLink="what-is-the-international-labour-organization"
            nextLink="eiip-in-lebanon"
            chapterNumber="1"
            />
       
        <div className='intensive-wrapper'>
            <h1>The Employment Intensive Infrastructure Programme (EIIP)</h1>
            <h2>What is the EIIP</h2>
            <p>The EIIP is a global programme under the ILO, implemented in more than 70 low to middle income countries. Linking together the following:</p>
            <div className="circles-infra">
                <div className="circle-infra">
                   <h1>EIIP</h1>
                </div>
            <RightDirection className='rightIcon'/>
            <div className='all-circles'>
                <div className='big-circles' >
                    {!circle1 ? <div className='eye-holder' onClick={() => {setCircle1(true);setCircleIndex(circleIndex + 1)}}><EyeIcon className='eye'/> </div> : <p>Employment Creation </p>}
                    
                    
                </div>
                <div className='big-circles'>
                     {!circle2 ? <div className='eye-holder'  onClick={() => {setCircle2(true);setCircleIndex(circleIndex + 1)}}><EyeIcon className='eye'/> </div> : <p>Infrastructure development</p>}
                </div>
                <div className='big-circles' >
                   {!circle3 ? <div className='eye-holder' onClick={() => {setCircle3(true);setCircleIndex(circleIndex + 1)}}><EyeIcon className='eye'/> </div> : <p>Local Economic & Social Development</p>}
                </div>
                <div className='big-circles' >
                    {!circle4 ? <div className='eye-holder' onClick={() => {setCircle4(true);setCircleIndex(circleIndex + 1)}}><EyeIcon className='eye'/> </div> : <p>Poverty Reduction</p>}
                </div>
            </div>
            </div>
            <RedPolyList list={list} />
            
           
            <button onClick={handleUpdates} disabled={circleIndex === 4 ? false : true} >Mark As Completed</button>
        </div>
        </>
    )
}

export default IntensiveInfrastructure;