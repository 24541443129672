import './contractor-training.styles.scss';
import {ReactComponent as RedPoly} from '../../assets/polygon.svg';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { updateChapters } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import RedPolyList from '../red-poly-list/red-poly-list.component';
import ShowText from '../show-text/show-text.component';
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const ContractorTraining = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
        navigate("../quiz")
      }
        const handleUpdates = () => {
        updateChapters(0,6,user.uid);
        getUser();
    }
    const list1 = [
        {
            id:0,
            text:"Contract format"
        },
        {
            id:1,
            text:"LRB specifications"
        },
        {
            id:2,
            text:"LRB work approach"
        },
        {
            id:3,
            text:"LRB productivity norms"
        },
        {
            id:4,
            text:"Work programming"
        },
        {
            id:5,
            text:"Costing for LRB items"
        },
        {
            id:6,
            text:"Environmental Social Safeguards Framework"
        },
        {
            id:7,
            text:"Tender preparation and submission"
        },
        {
            id:8,
            text:"Contract procedures"
        },
        {
            id:9,
            text:"Pre-tender meeting and site visit"
        }


    ]
    const list2 = [
        {
            id:0,
            text:"1 day training for the winning bidder"
        },
        {
            id:1,
            text:"Contractors/Managers"
        },
        {
            id:2,
            text:"Site Supervisors"
        },
        {
            id:3,
            text:"LRB work preparations"
        },
        {
            id:4,
            text:"Labour recruitment and employment"
        },
        {
            id:5,
            text:"Labour standards, management and OSH"
        },
        
        {
            id:7,
            text:"Work programming and resource planning"
        },
        {
            id:8,
            text:"Site management, task rate & quality control"
        },
        {
            id:9,
            text:"Work implementation"
        },
        {
            id:10,
            text:"Recording & reporting"
        },
        {
            id:11,
            text:"Contract management"
        }]

        const list3 = [
            {
                id:0,
                text:"Through-out contract implementation"
            },
            {
                id:1,
                text:"Contractors/Managers"
            },
            {
                id:2,
                text:"Site Supervisors & Labourers"
            },
        ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Intro to EIIP & LRBT" 
            subTitle="EIIP Contractor Training Strategy"
            chapterLink="IntrotoEIIP&LRBT"
            subLink="contractor-training-strategy"
            prevLink="contractor-obligations"
            nextLink="quiz"
            chapterNumber="1"
            />
       
        <div className="contractor-training-wrapper">
            <h1>EIIP Contractor Training Strategy</h1>
            
            <h2>LRBT Pre-tender Training</h2>
            <RedPolyList list={list1} />
            <ShowText
                title="Certificate of attendance  is mandatory to submit a tender"
                color="#3264C8"
            />
             <h2>Up-start Training</h2>
            <RedPolyList list={list2} />
            <h2>Supervision & Support</h2>
            <RedPolyList list={list3} />
            <ShowText
                title="Certificate of successful completion of contract"
                color="#3264C8"
            />
            <button onClick={handleUpdates} >Mark As Completed</button>
        </div>
        </>
    )
}

export default ContractorTraining;