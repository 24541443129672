import { Routes , Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo,selectCurrentUser } from "../../store/user/user.selector";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ChaptersNav from "../chapters-nav/chapters-nav.component";
import LabourmanagementforLRBworks from "./labour-management-lrb.component";
import IncentiveschemesforLRBworks from './intensive-schemes.component';
import ExerciseOne from "./exercice-one.component";
import ExerciseTwo from "./exercise-2.component";
import TaskratesandProductivityPart1 from "./task-rates-productivity.component";
import TaskratesandProductivityPart2 from "./task-rates-productivity-2.component";
import ExerciseThree from "./exercice-three.component";
import Exampleofsomelabourproductivitynorms from "./examples-labour-productivity";
import ExamplesApplicationoftaskworkandpieceworksystem from "./examples-application-task.component";
import IncentiveschemesforLRBworks1 from "./incentive-lrb-works.component";
import QuizTemplate from "../quiz-template/quiz-template.component";
import Attempt from "./attempt";
const ChapterFour = () => {
    const userInfo = useSelector(selectUserInfo);
    const questions = userInfo.chapters[3].questions;
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    useEffect(() => {
        if(userInfo.chapters[2].grade < 83){
            navigate("../../chapters")
        }
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[user])
    const details = [
        {
            "id": 0,
            "title":"Labour management for LRB works",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"LabourmanagementforLRBworks"
        },
        {
            "id": 1,
        "title":"Incentive schemes for LRB works",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"IncentiveschemesforLRBworks"
        },
        {
            "id": 2,
        "title":"Exercise 1",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"exercise-1"
        },{
            "id": 3,
            "title":"Task rates and Productivity Part 1",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"TaskratesandProductivityPart1"
        },{
            "id": 4,
            "title":"Exercise 2",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"exercise-2"
        },{
            "id": 5,
            "title":"Task rates and Productivity Part 2",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"TaskratesandProductivityPart2"
        },{
            "id": 6,
            "title":"Exercise 3",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"exercise-3"
        },{
            "id": 7,
            "title":"Example of some labour productivity norms",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"Exampleofsomelabourproductivitynorms"
        },
        {
            "id": 8,
            "title":"Examples: Application of task work and piece work system",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"Examples:Applicationoftaskworkandpieceworksystem"
        },
        {
            "id": 9,
            "title":"Incentive schemes for LRB works",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"IncentiveschemesforLRBworks1"
        },
        {
            "id": 10,
            "title":"Quiz",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"quiz"
        }
    
    ]
    const a3 = details.map((t1) => ({ ...t1, ...questions.find((t2) => t2.id === t1.id) }));
    window.scrollTo(0, 0);
    return (
        <>
        <div className="chapter-wrapper">
           <ChaptersNav title="Task Rates and Productivity Norms" navLinks={a3} chapterTitle="TaskRatesandProductivityNorms"/>
           <div className="chapters-lessons">
          <Routes>
          <Route index path="LabourmanagementforLRBworks" element={<LabourmanagementforLRBworks/>}/>
          <Route  path="IncentiveschemesforLRBworks" element={<IncentiveschemesforLRBworks/>}/>
          <Route  path="exercise-1" element={<ExerciseOne/>}/>
          <Route  path="TaskratesandProductivityPart1" element={<TaskratesandProductivityPart1/>}/>
          <Route  path="exercice-2" element={<ExerciseTwo/>}/>
          <Route  path="TaskratesandProductivityPart2" element={<TaskratesandProductivityPart2/>}/>
          <Route  path="exercise-3" element={<ExerciseThree/>}/>
          <Route  path="Exampleofsomelabourproductivitynorms" element={<Exampleofsomelabourproductivitynorms/>}/>
          <Route  path="Examples:Applicationoftaskworkandpieceworksystem" element={<ExamplesApplicationoftaskworkandpieceworksystem/>}/>
          <Route  path="IncentiveschemesforLRBworks1" element={<IncentiveschemesforLRBworks1/>}/>
          <Route path="quiz" element={<QuizTemplate chapterNumber='4' quizLink='attempt' toPass="80%"
            chapterTitle="Task Rates and Productivity Norms" 
            subTitle="Quiz"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="quiz"
            prevLink="IncentiveschemesforLRBworks1"
            nextLink="../UnitRateAnalysisandPricingBoQforLRBWorks/UnderstandingofcostinganddetailedUnitRateAnalysis"
            chapterGrade={(Math.round(userInfo.chapters[3].grade * 100) / 100).toFixed(2)}
          />}/>
          <Route path="attempt" element={<Attempt/>}/>
       </Routes>
           </div>
         
        </div>
       
         
        </>
    )
}


export default ChapterFour;