import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import {ReactComponent as GreenTick} from '../../assets/tick-circle-green.svg';
import { gsap } from "gsap";
import { useState, useLayoutEffect,useRef,useEffect } from "react";
const ChaptersNavSix = ({title,navLinks,chapterTitle}) => {
    let activeStyle = {
    
    backgroundColor: '#F5F5F7',
    borderLeft: '5px solid #1E2DBE'
   }
   const [reversed,setReversed] = useState(false);
   const tl = useRef();
   const app = useRef();
  const box = useRef()

  const linkRef = useRef();

const [close,setClose] = useState(true);
const handleMenu = (target,target1) => {
    setClose(false)
    const ctx = gsap.context(() => {
      
        tl.current = gsap.timeline()
        .to(`#${target} a`,{opacity:0,duration:0.5,ease:"ease-in"})
            .to("#" + target,{height:0,overflow:"hidden",duration:0.3,ease:"ease-in"})
           
            
    },app);
    return () => ctx.revert();
}
const handleMenuu = (target,target1) => {
    setClose(true)
    const ctx = gsap.context(() => {
      
        tl.current = gsap.timeline()
        .to("#" + target,{height:"fit-content",overflow:"visible",duration:0.5,ease:"ease-in"})
        .to(`#${target} a`,{opacity:1,duration:0.5,ease:"ease-in",delay:-0.2})
            
           
            
    },app);
    return () => ctx.revert();
}
const subMenu = useRef();
const handleFew = (target) => {
 const ctx = gsap.context(() => {
   
     tl.current = gsap.timeline({
         paused:true
     })
     tl.current = gsap.timeline()
     
     .to(target,{x:0,duration:0.5,ease:"ease-in"})
     
         
         
 },app);
 return () => ctx.revert();
}
const handleFeww = (target) => {
 const ctx = gsap.context(() => {
   
     tl.current = gsap.timeline({
         paused:true
     })
     tl.current = gsap.timeline()
     
     .to(target,{x:"-100%",duration:0.5,ease:"ease-out"})
     
         
 },app);
 return () => ctx.revert();
}

    return (
        <>
        <div ref={app} className="chapters-nav">
            
            <div id="nav"  className="navlinks-holder" >
           
            {navLinks.map((item) => 
                <div key={item.id}>
             
                 <h4 onClick={() => {close ? handleMenu(item.secondId) : handleMenuu(item.secondId)}} >{item.title}</h4>
                <div id={item.secondId} ref={box} >
               
                {item.questions.map((loer) => {
                        return (
                            <NavLink ref={linkRef} key={loer.id} id={loer.id} style={({ isActive }) =>
              isActive ? activeStyle : undefined
            } to={`../../../home/chapters/${chapterTitle}/${loer.url}`}>

                 
                 {loer.status ? <GreenTick/>  : <img src={loer.mode === "reading" ? require('../../assets/reading-icon.png') : loer.mode ==="video" ? require('../../assets/video-icon.png') : loer.mode === "quiz" ? require("../../assets/quiz-icon.png") : null} alt="" srcSet="" />}
                 {loer.title}
                
            </NavLink>
                        )
                    })}
                    
              
            </div>
            </div>
            )}
            </div>
            
            
        </div>
        <div ref={app} className="chapters-nav-phone">
        <div onClick={() => handleFew(subMenu.current)} className="menu-burger">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h3 onClick={() => handleFew(subMenu.current)}>Menu</h3>
            <div id="nav" ref={subMenu}  className="navlinks-holder" >
           <h3 onClick={() => handleFeww(subMenu.current)}>Close</h3>
            {navLinks.map((item) => 
                <div key={item.id}>
             
                 <h4 onClick={() => {close ? handleMenu(item.secondId) : handleMenuu(item.secondId)}} >{item.title}</h4>
                <div id={item.secondId} ref={box} >
               
                {item.questions.map((loer) => {
                        return (
                            <NavLink onClick={() => handleFeww(subMenu.current)} ref={linkRef} key={loer.id} id={loer.id} style={({ isActive }) =>
              isActive ? activeStyle : undefined
            } to={`../../../home/chapters/${chapterTitle}/${loer.url}`}>

                 
                 {loer.status ? <GreenTick/>  : <img src={loer.mode === "reading" ? require('../../assets/reading-icon.png') : loer.mode ==="video" ? require('../../assets/video-icon.png') : loer.mode === "quiz" ? require("../../assets/quiz-icon.png") : null} alt="" srcSet="" />}
                 {loer.title}
                
            </NavLink>
                        )
                    })}
                    
              
            </div>
            </div>
            )}
            </div>
            
            
        </div>
        </>
    )
}
export default ChaptersNavSix;