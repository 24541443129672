import { Routes , Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo,selectCurrentUser } from "../../store/user/user.selector";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ChaptersNav from "../chapters-nav/chapters-nav.component";
import ContractAdministration from "./contract-administration.component";
import KeyContractDocuments from "./key-contract-doc.component";
import ParticularConditions from "./particular-conditions.component";
import BidClarificationandSubmission from "./bid-clarification.component";
const ChapterSeven = () => {
    const userInfo = useSelector(selectUserInfo);
    const questions = userInfo.chapters[6].questions;
    const navigate= useNavigate();
    const user = useSelector(selectCurrentUser);
    window.scrollTo(0, 0);
    useEffect(() => {
        if(userInfo.chapters[5].grade < 70){
            navigate("../../chapters")
        }
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[user])
    const details = [
        {
            "id": 0,
            "title":"Contract Administration",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"ContractAdministration"
        },
        {
            "id": 1,
        "title":"Key Contract Documents",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"KeyContractDocuments"
        },
        {
            "id": 2,
        "title":"Particular Conditions - highlights - please see Annex 4b!",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"ParticularConditions"
        },{
            "id": 3,
            "title":"Bid Clarification and Submission: ITB 36-2022",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"BidClarificationandSubmission"
        }
         
    
    ]
    const a3 = details.map((t1) => ({ ...t1, ...questions.find((t2) => t2.id === t1.id) }));
    return (
        <>
        <div className="chapter-wrapper">
           <ChaptersNav title="Contract Administration & Particular Conditions" navLinks={a3} chapterTitle="ContractAdministration&ParticularConditions"/>
           <div className="chapters-lessons">
          <Routes>
          <Route index path="ContractAdministration" element={<ContractAdministration/>}/>
          <Route  path="KeyContractDocuments" element={<KeyContractDocuments/>}/>
          <Route  path="ParticularConditions" element={<ParticularConditions/>}/>
          <Route  path="BidClarificationandSubmission" element={<BidClarificationandSubmission/>}/>
       </Routes>
           </div>
         
        </div>
       
         
        </>
    )
}


export default ChapterSeven;