import RedPolyList from "../red-poly-list/red-poly-list.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const ImplementationofOccupationalSafetyHealth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,6,user.uid,4);
        getUser();
        navigate("../WorkersResponsibility")
    }
    const list = [
        {
            id:0,
            text:"Accident insurance (including 3rd party liability insurance)"
        },
        {
            id:1,
            text:"Contractors’ staff are well trained on OSH standards"
        },
        {
            id:2,
            text:"Proper briefing about safe working practices"
        },
        {
            id:3,
            text:"Provision of adequate safety gear during work"
        },
        {
            id:4,
            text:"Provision of adequate hand-tools"
        },
        {
            id:5,
            text:"Provision of first Aid kits on site",
            textt:"First Aid Kit: At least 2 people onsite should know how to use it, and workers should know where it is located – for large sites more than one kit should be present within easy access to workers at all times",
            circleId:"aidKits",
            mainCircleId:"aidKitsMain",
            boxId:"aidKitsBox",
            color:"#FB636F",
            circle:true
        },
        {
            id:6,
            text:"Sufficient level of hygiene on-site camp"
        },
        {
            id:7,
            text:"Provision of toilets/ mobile toilets",
            textt:"Toilets/Mobile Toilets:Separate toilets to males and females should be provided at all times to all workers, they should be clean and equipped at all times. They should have doors that lock and within an accessible distance to workers at all times",
            circleId:"toilets",
            mainCircleId:"toiletsMain",
            boxId:"toiletsBox",
            color:"#FB636F",
            circle:true
        },
        {
            id:8,
            text:"Provision of adequate drinking water"
        },
        {
            id:9,
            text:"Allowance for adequate rest periods",
            textt:"Rest Periods:Rest periods should be provided to all workers, it should be in a shaded area where they can rest, eat and drink. No worker under any circumstance should be denied a break for resting",
            circleId:"periodsKits",
            mainCircleId:"periodsKitsMain",
            boxId:"periodsKitsBox",
            color:"#FB636F",
            circle:true
        },
        {
            id:10,
            text:"Prohibition of alcohol and drugs on the worksite"
        },
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Implementation of Occupational Safety & Health"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="ImplementationofOccupationalSafetyHealth"
            prevLink="RiskManagementMatrix"
            nextLink="WorkersResponsibility"
            chapterNumber='6'
            />
        <h1>Implementation of Occupational Safety & Health</h1>
        <RedPolyList list={list} />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default ImplementationofOccupationalSafetyHealth;