import { Routes , Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo,selectCurrentUser} from "../../store/user/user.selector";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChaptersNav from "../chapters-nav/chapters-nav.component";

import QuizTemplate from "../quiz-template/quiz-template.component";
import Attempt from "./attempt";
const ChapterEight = () => {
    const userInfo = useSelector(selectUserInfo);
    const questions = userInfo.chapters[7].questions;
    const navigate= useNavigate();
    const user = useSelector(selectCurrentUser)
    useEffect(() => {
        if(userInfo.chapters[6].grade < 80){
            navigate("../../chapters")
       }
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[user])
    const details = [
        {
            "id": 0,
            "title":"Quiz",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"quiz"
        }
    ]
    const a3 = details.map((t1) => ({ ...t1, ...questions.find((t2) => t2.id === t1.id) }));
    return (
        <>
        <div className="chapter-wrapper">
           <ChaptersNav title="Final Quiz" navLinks={a3} chapterTitle="final-quiz"/>
           <div className="chapters-lessons">
          <Routes>
         
          <Route path="quiz" element={<QuizTemplate chapterNumber='8' quizLink='attempt' toPass="93%"
           chapterTitle="Final Quiz" 
           subTitle="Bid Clarification and Submission"
           chapterLink="final-quiz"
           subLink="quiz"
           prevLink="../ContractAdministration&ParticularConditions/BidClarificationandSubmission"
           chapterGrade={(Math.round(userInfo.chapters[7].grade * 100) / 100).toFixed(2)}
          />}/>
          <Route path="attempt" element={<Attempt/>}/>
       </Routes>
           </div>
         
        </div>
       
         
        </>
    )
}


export default ChapterEight;