import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import MiniCircles from "../mini-circles/mini-circles.component";
const ReportingAccidentsonSite = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,8,user.uid,4);
        getUser();
        navigate("../WomensParticipationVIDEO")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Reporting Accidents on-Site"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="ReportingAccidentsonSite"
            prevLink="WorkersResponsibility"
            nextLink="WomensParticipationVIDEO"
            chapterNumber='6'
            />
        <h1>Reporting Accidents on-Site</h1>
        <MiniCircles text="Accident" color1="#049393" color2="#D2FBFB" width="20%" height="20%" />
        <MiniCircles text="Supervisor/ Team Leader" color1="#049393" color2="#D2FBFB" width="30%" height="30%" />
        <MiniCircles text="Contractor OSH Focal Point & SSO" color1="#049393" color2="#D2FBFB" width="50%" height="50%" />
        <MiniCircles text="Referral as needed and as case requires" color1="#049393" color2="#D2FBFB" width="60%" height="60%" />
        <MiniCircles text="Submit accident report with details of accident (causes, results) and mitigation measures" color1="#049393" color2="#D2FBFB" width="70%" height="70%" />
        
        
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}


export default ReportingAccidentsonSite;