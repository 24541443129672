import { useState } from "react";
import {ReactComponent as WellDoneIcon} from '../../assets/well-done.svg';
import {ReactComponent as OhNoIcon} from '../../assets/oh-no.svg';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { updateChapters } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import './international-labour.styles.scss'
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const InternationalLabourOrganization = () =>{
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
      }
        const handleUpdates = async () => {
    await    updateChapters(0,1,user.uid);
      
        getUser();
        navigate("../the-employment-intensive-infrastructure-programme")
    }
    const circles = [
        {
            id:0,
            date: "1923",
            isCorrect: false,
            color: "#3264C8",
            text:"Well No!",
            isWhat:false,
            isVisible:true
        },{
            id:1,
            date: "1919",
            isCorrect: true,
            color: "#AB3B77",
            text:"Well Done!",
            isWhat:false,
            isVisible:true
        },
        {
            id:2,
            date: "1917",
            isCorrect: false,
            color: "#FAB428",
            text:"Well No!",
            isWhat:false,
            isVisible:true
        },{
            id:3,
            date: "1925",
            isCorrect: false,
            color: "#FB636F",
            text:"Well No!",
            isWhat:false,
            isVisible:true
        },
]
const [answer, setAnswer] = useState();
const [showAnswer, setShowAnswer] = useState();
const [circleInfo, setCircleInfo] = useState(circles);
 const handleAction = (id) => {
    setCircleInfo(circleInfo => {
        circleInfo.map((obj) =>{
            if(obj.id === id){
                return {...obj, date:"Wew"}
            }
            
           
        })
        return circleInfo
    })
 }
 const handleThe = (od) => {
    setCircleInfo(circles => 
        circles.map(obj => {
            if (obj.id === od){
                return {...obj, isWhat:true}
            }else{
                return {...obj, isVisible:false}
            }
        return obj;
    }))
    setShowAnswer(true)
 }
 
    return (
        <>
        <BreadCrumb 
            chapterTitle="Intro to EIIP & LRBT" 
            subTitle="What is the International Labour Organization?"
            chapterLink="IntrotoEIIP&LRBT"
            subLink="what-is-the-international-labour-organization"
            prevLink="video-eiip"
            nextLink="the-employment-intensive-infrastructure-programme"
            chapterNumber="1"
            />
        
        <div className="international-labour-wrapper">
            <h1>What is the International Labour Organization?</h1>
            <p>The ILO is a specialized UN Agency advancing social justice and promoting the Decent Work Agenda.</p>
            <h2>When do you think ILO was created?</h2>
            <div className="circles-holder">
                 <div className="circles-holder"> {
                    circleInfo.map((item) => 
                    
                   <div key={item.id}>
                    {item.isVisible ? <div className="circle"  style={{backgroundColor:item.color}} onClick={() => handleThe(item.id)} >
                     
                    {item.isWhat ? <h3>{item.text}</h3>  :  <h1>{item.date}</h1> }
                    
                   </div> : ""}
                   </div>
                   
                    )
                }</div>
               
                {
                    showAnswer ? <div>
                      
                        <h1>The ILO was created in 1919</h1>
                        <p>The ILO is the only UN agency, since 1919, bringing together governments, workers and employers of 187 member states, to set labour standards, develop policies and devise programmes promoting decent work for all.The ILO has extensive experience in implementing employment programmes in the infrastructure sector job creation, decent & productive work, enterprise promotion, & quality infrastructure </p>
                        <button onClick={handleUpdates} >Mark As Completed</button>
                        </div> : null
                }
               
                
            </div>
        </div>
        </>
    )
}

export default InternationalLabourOrganization;