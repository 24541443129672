import { useState } from "react";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import './exercice.styles.scss'
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const ExerciceWorkPlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(2,4,user.uid);
        getUser();
        navigate("../WorkPlanConsiderations")
    }
    const [answer1,setAnswer1] = useState(null);
    const [answer2, setAnswer2] = useState(null)
    const [answer3, setAnswer3] = useState(null)
    const [finalResult,setFinalResult] = useState(null);
    const [showfinalResult,setshowFinalResult] = useState(null);
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(answer1 && answer3){
            setFinalResult(true);
        }else{
            setFinalResult(false);
        }
    
       setshowFinalResult(true)
      
    }

    return (
        <>
         <BreadCrumb 
            chapterTitle="Labour Management and Work Plan" 
            subTitle="Exercise"
            chapterLink="LocalResourceBasedTechnology"
            subLink="exercice-1"
            prevLink="ImportanceofawelldevelopedWorkPlan"
            nextLink="WorkPlanConsiderations"
            chapterNumber='3'
            />
        <h1>Exercise</h1>
        <h2>What are, in your opinion, the main points that the ILO/EIIP Team look for in the submitted work plan? (Select all that apply)</h2>
        <form className="exercice-form" onSubmit={handleSubmit}>
        <input onClick={() => setAnswer1(true)}   type="checkbox" name="Project Time Frame" id="Project Time Frame" value="Project Time Frame"  /><label for="Project Time Frame">Project Time Frame</label><br />
        <input onClick={() => setAnswer2(true)}   type="checkbox" name="Total Number of work Days" id="Total Number of work Days" value="Total Number of work Days"  /><label for="Total Number of work Days">Total Number of work Days</label><br />
        <input onClick={() => setAnswer3(true)}   type="checkbox" name="Sequencing of Activities" id="Sequencing of Activities" value="Sequencing of Activities"  /><label for="Sequencing of Activities">Sequencing of Activities</label><br />
        <button type="submit">Submit</button>
        </form>
        {
        showfinalResult ?  finalResult ? 
            <div>
            <h2>Well Done!</h2>
            <button onClick={handleUpdates}>Mark As Completed</button> 
        </div> :  <div>
            <h2>Well No!</h2>
            <h3>The main points are : Project Time Frame, Sequencing of Activities</h3>
            <button onClick={handleUpdates}>Mark As Completed</button> 
        </div> : null
        }
        
       
        </>
    )
}


export default ExerciceWorkPlan;