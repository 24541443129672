import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const ScreeningandAnalysisEnvironmentalScreening = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,5,user.uid,7);
        getUser();
        navigate("../Facilitationthroughtraining")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Screening and Analysis - Environmental Screening"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="ScreeningandAnalysisEnvironmentalScreening"
            prevLink="ScreeningandAnalysisSocialFeasibility"
            nextLink="Facilitationthroughtraining"
            chapterNumber='6'
            />
        <h1>Social and Environmental Safeguards - Screening and Analysis- Environmental Screening</h1>
        <h2>Step by Step process
</h2>
<p>(i) The proponent of the intended project completes a Project Screening Form (PSF), Annex 4 of the EIA decree for submission to the MoE for screening</p>
       
       <p>(ii) Screening is made based on two positive lists Annex I and Annex II, as well as Annex III which takes into account the project location
	 Annex 1 projects for which an EIA is required
	 Annex 2 projects for which only an IEE is required
	 If not listed in Annex 1 or 2 no further analysis is needed unless located in an Annex 3 in which case an IEE is required</p>
        <p>(iii) Duration of the MoE response is 15 days; if no response within this period is issued by MoE, the project proponent may proceed on the basis of the above screening rules</p>
        <p>(iv) Scoping is required for projects subject to an EIA study; no scoping is required for projects requiring an IEE.</p>
        <p>(v) Environmental Management Plans of approved EIA/IEE studies should be integrated in project design (Annex 6 of the decree). Notably, costs of the EMP should be taken into consideration in the project’s feasibility study and mitigation and monitoring measures shall be integral parts of the project design
</p>
<p>(vi) A technical committee reviews the report and a decision is issued by the Ministry within two months for EIA and within 30 days for IEE. If no decision is obtained within the stipulated review period the proponent can consider the EIA or IEE report, whichever is applicable, approved and go ahead with the project on the basis of the Environmental Management Plan included in the report
</p>
<img src={require("../../assets/screen-anal-evir.jpg")} className="boq-ch4-img" alt="" />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default ScreeningandAnalysisEnvironmentalScreening;