import RedPolyList from "../red-poly-list/red-poly-list.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const CostofEquipmentandhandtools = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,7,user.uid);
        getUser();
        navigate("../TypicalhandtoolsforLRBTworks")
    }
    const list = [
        {
            id:0,
            text:"Hire costs, or"
        },
        {
            id:1,
            text:"Owner costs (purchase, insurance, licence, etc.)  including, depreciation and replacement costs"
        },
        {
            id:2,
            text:"Operational costs (plant operator, fuel, lubricants, maintenance, repair)"
        },
    ]
    return (
        <>
         <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Cost of Equipment and hand tools"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="CostofEquipmentandhandtools"
            prevLink="LabourCost"
            nextLink="TypicalhandtoolsforLRBTworks"
            chapterNumber='5'
            />
        <h1>Cost of Equipment and hand tools</h1>
        <h2>Equipment rate includes</h2>
        <RedPolyList list={list}/>
        <img src={require('../../assets/cost-equipment-1-2.jpg')} style={{marginBottom:"30px"}} className="boq-ch3-img" alt=""  />
        <img src={require('../../assets/cost-equipment-2-2.jpg')} className="boq-ch3-img" alt=""  />
        <h2>Examples of some equipment task rates</h2>
        <img src={require('../../assets/cost-equipments-3.jpg')} className="boq-ch3-img" alt=""  />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}
export default CostofEquipmentandhandtools;