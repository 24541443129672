
import FalseAndFalse from "../trueorfalse/false-or-false.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import { useState } from "react";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const WhyaGrievanceMechanism = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,0,user.uid,6);
        getUser();
        navigate("../GrievanceChannels")
    }
    const  [exerc1,setExerc1] = useState(false);
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Why a Grievance Mechanism?"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="WhyaGrievanceMechanism"
            prevLink="PromotingInclusiveParticipation"
            nextLink="GrievanceChannels"
            chapterNumber='6'
            />
        <h1>Why a Grievance Mechanism?</h1>
      <div onClick={() => setExerc1(true)}><FalseAndFalse
                question="If a worker reported a complaint against the contractor, the contractor has the right to terminate their contract given that this worker may have a negative impact on the rest of the team which may affect the project negatively."
                answer="Workers have the full right to voice their complaints and grievances. Moreover, the workers who raise a complaint are fully protected against any retaliation measures that might be taken against them by anyone."
        /></div>
        
        <p>Employment Intensive Infrastructure Programmes aim to achieve high levels of positive impact within communities where EIIP projects are implemented; this is applicable for direct and indirect beneficiaries. 
Promoting the decent work principles and ensuring a safe space for everyone to sound their concerns, grievances, and complaints, the EIIP have developed a grievance mechanism that is open to workers, community members, contractors, and contractors’ team. 
</p>
{
    exerc1 ? <button onClick={handleUpdates}>Mark As Completed</button> : null
}

        </>
    )
}
export default WhyaGrievanceMechanism;