import { USER_ACTION_TYPES } from "./user.types";


const INITIAL_STATE = {
    currentUser: null,
    userInfo: null,
    allUsers:[]
};

 export const userReducer = (state = INITIAL_STATE , action) => {
   
    const {type,payload} = action;
    
    switch(type) {
        case USER_ACTION_TYPES.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: payload
            }
            case USER_ACTION_TYPES.SET_ALL_USERS:
            return {
                ...state,
                allUsers: payload
            }
            case USER_ACTION_TYPES.SET_USER_INFO:
                return {
                    ...state,
                    userInfo: payload
                }
        default:
            return state;

    }
 };

