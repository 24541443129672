import './contractor-obligations.styles.scss';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { updateChapters } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const ContractorObligations = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
        navigate("../contractor-training-strategy")
      }
        const handleUpdates = () => {
        updateChapters(0,5,user.uid);
        getUser();
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Intro to EIIP & LRBT" 
            subTitle="EIIP Contractor Training Strategy"
            chapterLink="IntrotoEIIP&LRBT"
            subLink="contractor-obligations"
            prevLink="eiip-strategy"
            nextLink="contractor-training-strategy"
            chapterNumber="1"
            />
       
        <div className="contractors-wrappers">
            <h1>Contractor Obligations</h1>
            <div className='tables-holder'>
            <table>
                <tr>
                    <th style={{width:"25%"}}>Outcome</th>
                    <th style={{width:"50%"}}>In accordance with</th>
                    <th style={{width:"25%"}}>Measured by</th>
                </tr>
                <tr>
                    <td><h4>Provide decent work opportunities to Syrian refugees, vulnerable Lebanese citizens, for Women and PWD</h4>
                        </td>
                    <td><h4>Project Targets Social and Environmental Safeguard Framework. EIIP gender Strategy</h4></td>
                    <td><h4>Social Safeguard Officers M&E Officer Decent Work & Gender Advisor</h4></td>
                </tr>
                 <tr>
                    <td><h4>Construct a quality infrastructure on time and to budget</h4></td>
                    <td><h4>Workplan</h4></td>
                    <td><h4>Social Safeguard Officers Engineers</h4></td>
                </tr>
                 <tr>
                    <td><h4>Construct a quality infrastructure on time and to budget</h4></td>
                    <td><h4>Contract</h4></td>
                    <td><h4>Social Safeguard Officers Engineers</h4></td>
                </tr>
                <tr>
                    <td><h4>Maintain adequate OSH and Covid-19 measures</h4></td>
                    <td><h4>Contract,  ILO OSH standards ILO Covid-19 measures</h4></td>
                    <td><h4>Social Safeguard Officers Engineers Decent Work & Gender Advisor</h4></td>
                </tr>
                <tr>
                    <td><h4>Provide On the Job Training</h4></td>
                    <td><h4>ILO requirements</h4></td>
                    <td><h4>Social Safeguard Officers</h4></td>
                </tr>
            </table>
            </div>
            
            <button onClick={handleUpdates} >Mark As Completed</button>
        </div>
        </>
    )
}

export default ContractorObligations;