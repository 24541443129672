import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const GrievanceChannels = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,1,user.uid,6);
        getUser();
        navigate("../GrievanceProcedure")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Grievance Channels"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="GrievanceChannels"
            prevLink="WhyaGrievanceMechanism"
            nextLink="GrievanceProcedure"
            chapterNumber='6'
            />
        <h1>Grievance Channels</h1>
        <ShowText
            title="Hotline"
            text="A WhatsApp number is available to the project and general public in each project location. Anyone can contact the ILO on this number, including workers on the project, contractor’s staff or the community at large. The information will be strictly confidential and only shared on a needs basis. The Hotline will be managed by the ILO EIIP team centrally and monitored daily. Complaints that need an action will be logged and forwarded to the relevant official for action. The action taken and outcome will be listed against the complaint ticket           "
            color="#771449"
            textColor="white"
        />
        <ShowText
            title="Social Safeguard Officer"
            text="The SSOs build a relation of trust and confidence between them and the project beneficiaries. Project beneficiaries are advised to approach the SSO and inform them in case of any grievance or issue on site they might be facing (female beneficiaries will be given the option to talk to a female SSO). The SSO will then proceed to fill out a standardized grievance form, and send it to the EIIP M&E Officer, who will in turn take the necessary measures to address the issue promptly and confidentially."
            color="#FAB428"
            textColor="#230050"
        />
        <ShowText
            title="Complaint Box On Site"
            text="Workers and contractor’s staff are made aware of the onsite complaints’ mechanism at the start of the project (or start of employment). Anyone who has a complaint can fill out a grievance form and anonymously drop it into the complaints box placed in a strategic location. The EIIP SSO, will forwarded all grievances submitted to the central ILO team which will follow up and take the necessary actions."
            color="#69E4E4"
            textColor="#230050"
        />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}
export default GrievanceChannels;