import RedPolyList from "../red-poly-list/red-poly-list.component";
import BlueShowText from "../blue-show-text/blue-show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const RecruitmentOfLabour = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(2,1,user.uid);
        getUser();
        navigate("../LogicalsequencingofLRBactivities")
    }
    const details = [
        {
            id: 0,
            text : "The number of labourers required for different activities varies with the type of works and the expected productivity."
        },
        {
            id: 1,
            text : "The Contractor is responsible for recruiting workers by adopting different outreach modalities including coordinating with municipalities."
        },
        {
            id: 2,
            text : "Ensuring all workers are informed before commencing the work. The information should be widely available and include:"
        }
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Labour Management and Work Plan" 
            subTitle="Recruitment of Labour"
            chapterLink="LocalResourceBasedTechnology"
            subLink="RecruitmentofLabour"
            prevLink="TypicalsiteorganizationforLRBworks"
            nextLink="LogicalsequencingofLRBactivities"
            chapterNumber='3'
            />
        <RedPolyList title="Recruitment of Labour" list={details}/>
        <BlueShowText text='Type of work to be carried out' status={true}/>
        <BlueShowText text='Number of labourers to be recruited, and the time and place of recruitment' status={true}/>
        <BlueShowText text='Employment duration and other conditions of work' status={false}/>
       
        
        <BlueShowText text='Offered daily wage' status={true}/>
        <BlueShowText text='Incentive scheme' status={true}/>
        <BlueShowText text='Payment process' status={false}/>
        <BlueShowText text='Women and men including persons with disabilities are equally eligible' status={true}/>
        <BlueShowText text='Minimum working age' status={true}/>
        <BlueShowText text='Safety and health regulations' status={true}/>
        <BlueShowText text='Rotation policy' status={false}/>
        <BlueShowText text='Registration procedure and employment contract' status={true}/>
        <BlueShowText text='Responsibilities' status={true}/>
        <BlueShowText text='Complaints mechanism' status={false}/>
        <BlueShowText text='Understanding the labour contract and the workers insurance' status={true}/>
        <p>To include, rotation to ensure that we outreach for the most number of workers and achieve the most number of jobs.</p>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default RecruitmentOfLabour;