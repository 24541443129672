import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const Facilitationthroughtraining = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,6,user.uid,7);
        getUser();
        navigate("../Compliance")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Facilitation through training"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="Facilitationthroughtraining"
            prevLink="ScreeningandAnalysisEnvironmentalScreening"
            nextLink="Compliance"
            chapterNumber='6'
            />
        <h1>Social and Environmental Safeguards Facilitation through training </h1>
        <ShowText
            title="Why"
            color="#AB3B77"
            textColor="white"
            text="To ensure that stakeholders have sufficient capacities, as well as technical and financial resources to comply with the social safeguards requirements."
        />
        
        <ShowText
            title="When"
            color="#3264C8"
            textColor="white"
            text="• During the preparation of Designs"
            text2="• Pre bid"
            text3="• Tendering"
            text4="• Contractor Training"
            text5="• Launching of works"
        />
       
        
        <ShowText
            title="How"
            color="#69E4E4"
            textColor="#230050"
            text="• Mitigation measures during planning and design phase"
            text2="• Meetings with stakeholders"
            text3="• Prebid trainings"
            text4="• Local development statement in the bid"
            text5="• Training of contractor"
            text6="• Meeting with Community members"
        />
        
        <button onClick={handleUpdates}>Mark As Completed</button>

        </>
    )
}

export default Facilitationthroughtraining;