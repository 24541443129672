import './mini-circles.styles.scss';
const MiniCircles = ({text,color1,color2,width,height}) => {
    return (
        <div className='mini-circles-holder'>
            <div className="mini-circles" style={{backgroundColor:color1}}>
                <div className='mini-circle' style={{backgroundColor:color2,width:width,height:height}}></div>
            </div>
            <h2>{text}</h2>
        </div>
    )
}

export default MiniCircles;