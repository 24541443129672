import TrueAndFalse from "../trueorfalse/true-or-false.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import FalseAndTrue from "../trueorfalse/false-or-true.component";
import { useState } from "react";
const ExerciceTwo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(1,8,user.uid);
        getUser();
        navigate("../LocalResource-based(LRB)WorkMethod")
    }
    const [exerc1,setExerc1] = useState(false)
    const [exerc2,setExerc2] = useState(false)
    return (
        <>
        <BreadCrumb 
            chapterTitle="Local Resource Based Technology" 
            subTitle="Exercise"
            chapterLink="Socio-economic advantages of LRBT"
            subLink="exercice-2"
            prevLink="ChoiceoftechnologyLRBTvsequipment"
            nextLink="LocalResource-based(LRB)WorkMethod"
            chapterNumber="2"
            />
            <h1>Exercise</h1>
            <div onClick={() => setExerc1(true)}>
            <FalseAndTrue 
                question='LRBT projects are slower paced than equipment based approaches'
                answer ="LRBT projects are faster paced than equipment based approaches"
                />
            </div>
           <div onClick={() => setExerc2(true)}>
           <FalseAndTrue 
                question='Output quality is expected to be lower in LRBT projects, since no machines are used to enhance precision'
                answer ="Output quality is expected to be higher in LRBT projects, since no machines are used to enhance precision"
                />
           </div>
           
                {exerc1 && exerc2 ? <button onClick={handleUpdates}>Mark As Completed</button> : null }
            
        </>
    )
}

export default ExerciceTwo;