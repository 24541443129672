import ReactPlayer from "react-player/youtube";
import {ReactComponent as PlayIcon} from '../../assets/play.svg';
import { useState } from "react";
import { updateChapters } from "../../utils/firebase.utils";
import './video-chapter-one.styles.scss';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const VideoChapterOne = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser)
    const [time, setTime] = useState('');
    const [play ,setPLay] = useState(false);
    const [videoEnd, setVideoEnd] = useState(false);
    const navigate = useNavigate();
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
      }
        const handleUpdates = async () => {
        await   updateChapters(0,0,user.uid);
                getUser();
                navigate("../what-is-the-international-labour-organization")
    }
    const handleProgress = async (item) => {
        
      setTime(item.playedSeconds);
       
       if(time >= 6){
        setPLay(false);
       }
    }
    return (
      <>
        <BreadCrumb 
            chapterTitle="Intro to EIIP & LRBT" 
            subTitle="Video : EIIP"
            chapterLink="IntrotoEIIP&LRBT"
            subLink="video-eiip"
            nextLink="what-is-the-international-labour-organization"
            chapterNumber="1"
            />
        <div className="video-wrapper">
          <h1>Video: EIIP</h1>
             <ReactPlayer 
        playing={play}
        
        onEnded={() => setVideoEnd(true)}
        controls={true}
        
        onProgress={handleProgress}
        url='https://www.youtube.com/watch?v=d8jTLfvmPuY&t=2s&ab_channel=InternationalLabourOrganization'
        config={{
    youtube: {
      playerVars: { showinfo: 1 }
    }
  }}
        
        />
        {
            !videoEnd ? undefined :  <div className="video-end">
                
                <h4>Up Next</h4>
            <h2>What is the International Labour Organization ?</h2>
            <button onClick={handleUpdates}>Mark As Completed</button>
        </div>
        }
       
        </div>
        </>
    )
}

export default VideoChapterOne;