import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import './chapters-nav.styles.scss';
import {ReactComponent as GreenTick} from '../../assets/tick-circle-green.svg';
import { gsap } from "gsap";
import { useState, useLayoutEffect,useRef,useEffect } from "react";
const ChaptersNav = ({title,navLinks,chapterTitle}) => {
    let activeStyle = {
    
    backgroundColor: '#F5F5F7',
    borderLeft: '5px solid #1E2DBE'
   }
   const [reversed,setReversed] = useState(false);
   const tl = useRef();
   const app = useRef();
   const subMenu = useRef();
   const handleFew = (target) => {
    const ctx = gsap.context(() => {
      
        tl.current = gsap.timeline({
            paused:true
        })
        tl.current = gsap.timeline()
        
        .to(target,{x:0,duration:0.5,ease:"ease-in"})
        
            
            
    },app);
    return () => ctx.revert();
   }
   const handleFeww = (target) => {
    const ctx = gsap.context(() => {
      
        tl.current = gsap.timeline({
            paused:true
        })
        tl.current = gsap.timeline()
        
        .to(target,{x:"-100%",duration:0.5,ease:"ease-out"})
        
            
    },app);
    return () => ctx.revert();
   }
    return (
        <>
        <div ref={app} className="chapters-nav">
            <h3 >{title}</h3>
            <div id="nav" className="navlinks-holder" >
                {navLinks.map((item) => 
                <NavLink key={item.id} style={({ isActive }) =>
              isActive ? activeStyle : undefined
            } to={`../../../home/chapters/${chapterTitle}/${item.url}`}>

                 
                 {item.status ? <GreenTick/>  : <img src={item.imageUrl} alt="" srcSet="" />}
                 {item.title}
            </NavLink>
            )}
            </div>
            
            
        </div>
        <div ref={app} className="chapters-nav-phone">
            <div onClick={() => handleFew(subMenu.current)} className="menu-burger">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h3 onClick={() => handleFew(subMenu.current)}>Menu</h3>
            <div ref={subMenu}  id="nav" className="navlinks-holder" >
                <h3 onClick={() => handleFeww(subMenu.current)}>Close</h3>
                {navLinks.map((item) => 
                <NavLink onClick={() => handleFeww(subMenu.current)} key={item.id} style={({ isActive }) =>
              isActive ? activeStyle : undefined
            } to={`../../../home/chapters/${chapterTitle}/${item.url}`}>

                 
                 {item.status ? <GreenTick/>  : <img src={item.imageUrl} alt="" srcSet="" />}
                 {item.title}
            </NavLink>
            )}
            </div>
            
            
        </div>
        </>
    )
}
export default ChaptersNav;