import {ReactComponent as ClickIcon} from '../../assets/click-icon.svg';
import './general-principles.styles.scss';
import { useState } from 'react';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const GeneralPrinciples = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,1,user.uid,4);
        getUser();
        navigate("../PersonalProtectiveEquipment")
    }
    const [square1,setSquare1] = useState(true);
    const [square2,setSquare2] = useState(false);
    const [square3,setSquare3] = useState(true);
    const [square4,setSquare4] = useState(false);
    const [square5,setSquare5] = useState(true);
    const [square6,setSquare6] = useState(true);
    const [square7,setSquare7] = useState(true);
    const [square8,setSquare8] = useState(false);
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Socio-economic advantages of LRBT"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="GeneralPrinciples"
            prevLink="OSHVideo"
            nextLink="PersonalProtectiveEquipment"
            chapterNumber='6'
            />
        <h1>General Principles</h1>
        
        <div className="general-box-holder">
            <div className="general-square" style={{backgroundColor: square1 ? "#E0B6CC" : "#AB3B77"}}  onClick={()=>setSquare1(true)}>
                <div className="general-number" style={{backgroundColor: square1 ? "#AB3B77" : "white" ,color: square1 ? "white" : "#AB3B77"}}>
                    1
                </div>
                {
                 square1 ? <h4>Avoid Risks</h4> : null
                }
                {
                    !square1 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="general-square" style={{backgroundColor: square2 ? "#E0B6CC" : "#AB3B77"}} onClick={()=>setSquare2(true)}>
                <div className="general-number" style={{backgroundColor: square2 ? "#AB3B77" : "white" ,color: square2 ? "white" : "#AB3B77"}}>
                    2
                </div>
                {
                 square2 ? <h4>Evaluate the risks that cannot be avoided
                 </h4> : null
                }
                {
                    !square2 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="general-square" style={{backgroundColor: square3 ? "#E0B6CC" : "#AB3B77"}} onClick={()=>setSquare3(true)}>
                <div className="general-number" style={{backgroundColor: square3 ? "#AB3B77" : "white" ,color: square3 ? "white" : "#AB3B77"}}>
                    3
                </div>
                {
                 square3 ? <h4>Combat the risks at source</h4> : null
                }
                {
                    !square3 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="general-square extra" style={{backgroundColor: square4 ? "#E0B6CC" : "#AB3B77"}} onClick={()=>setSquare4(true)}>
                <div className="general-number" style={{backgroundColor: square4 ? "#AB3B77" : "white" ,color: square4 ? "white" : "#AB3B77"}}>
                    4
                </div>
                {
                 square4 ? <h4>Replace the dangerous by the non-dangerous or less dangerous</h4> : null
                }
                {
                    !square4 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="general-square" style={{backgroundColor: square5 ? "#E0B6CC" : "#AB3B77"}} onClick={()=>setSquare5(true)}>
                <div className="general-number" style={{backgroundColor: square5 ? "#AB3B77" : "#E0B6CC" ,color: square5 ? "white" : "#E0B6CC"}}>
                    5
                </div>
                {
                 square5 ? <h4>Develop a coherent overall prevention policy</h4> : null
                }
                {
                    !square5 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="general-square extra" style={{backgroundColor: square6 ? "#E0B6CC" : "#AB3B77"}}  onClick={()=>setSquare6(true)}>
                <div className="general-number" style={{backgroundColor: square6 ? "#AB3B77" : "white" ,color: square6 ? "white" : "#AB3B77"}}>
                    6
                </div>
                {
                 square6 ? <h4>Give collective protective measures priority over individual protective measure</h4> : null
                }
                {
                    !square6 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="general-square" style={{backgroundColor: square7 ? "#E0B6CC" : "#AB3B77"}}   onClick={()=>setSquare7(true)}>
                <div className="general-number" style={{backgroundColor: square7 ? "#AB3B77" : "white" ,color: square7 ? "white" : "#AB3B77"}}>
                    7
                </div>
                {
                 square7 ? <h4>Give appropriate instructions to the workers </h4> : null
                }
                {
                    !square7 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="general-square" style={{width:'calc(100% - 10px)',backgroundColor: square8 ? "#E0B6CC" : "#AB3B77"}}   onClick={()=>setSquare8(true)}>
                <div className="general-number" style={{backgroundColor: square8 ? "#AB3B77" : "white" ,color: square8 ? "white" : "#AB3B77"}}>
                    8
                </div>
                {
                 square8 ? <h4>Continuously communicate about the OSH measures to all those involved</h4> : null
                }
                {
                    !square8 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
        </div>
        <button disabled={!square2 && !square8} onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default GeneralPrinciples;