import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters,updateGrade } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const ParticularConditions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(6,2,user.uid);
        updateGrade(user.uid,100,6)
        getUser();
        navigate("../BidClarificationandSubmission")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Contract Administration..." 
            subTitle="Particular Conditions"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="ParticularConditions"
            prevLink="KeyContractDocuments"
            nextLink="BidClarificationandSubmission"
            chapterNumber="7"
            />
        <h1>Particular Conditions - highlights - please see Annex 4b!</h1>
        <ShowText
        title="4.1.1 Labour-based Methods"
        text="The works will generally be carried out using local-resource-based methods; giving preference to the use of local labour, Materials and services."
        color="#AB3B77"
        textColor="white"
        />
        <ShowText
        title="4.1.5 Labour Standards"
        text="The Contractor undertakes to respect, at all times and in all circumstances relevant to the performance of the Contract and in relation to all its personnel, the following principles concerning international labour standards of the International Labour Organization …"
        color="#FB636F"
        textColor="white"
        />
         <ShowText
        title="4.1.6 Responsible business practices"
        text="The Contractor and its personnel undertake to support and respect the protection of internationally proclaimed human rights, and observe the highest ethical standards throughout its supply chain …"
        color="#3264C8"
        textColor="white"
        />
        <ShowText
        title="4.1.7 Recruitment of Labour"
        text="The Contractor will reach out to vulnerable groups previously identified by the Employer. Workers will be recruited from the poorer segments of the community of the Project area and each worker will be offered 40 days of employment. Both male and female workers will have equal opportunity to participate in the Project Works …"
        color="#69E4E4"
        textColor="#230050"
        />
        <ShowText
        title="4.1.13 Environment, Safety and Health"
        text="…the Contractor will ensure compliance with applicable requirements of the EIIP Lebanon Social & Environmental Safeguards Framework, included in this Contract as Annex 4c ... The Contractor will ensure compliance with applicable requirements of the EIIP Lebanon COVID-19 Guidelines, included in the Contract as Annex 4d …"
        color="#FAB428"
        textColor="#230050"
        />
        <ShowText
        title="4.1.14 Minimum Wage"
        text="The currency and minimum daily wage rate is stated in the Appendix. During the execution of the Contract, price adjustments may be considered by the Employer in accordance with Sub-Clause 11.1.2 PC."
        color="#70B450"
        textColor="white"
        />
        <ShowText
        title="4.1.15 Terrorism/UN and EU sanctions lists"
        text="The Contractor agrees to undertake all reasonable efforts to ensure that no funds received under the Contract are used, directly or indirectly, to provide support to individuals or entities associated with terrorism, and that recipients of any funds provided hereunder do not appear on any sanctions list issued by the United Nations Security Council or by the European Union … This provision must be included in all subcontracts or sub-agreements entered into under the Contract."
        color="#AB3B77"
        textColor="white"
        />
         <ShowText
        title="4.1.17 Electronic payment of wages"
        text="Wages will be paid in the form of electronic cash transfers, made directly to each individual worker through a third party financial service provider in the currency stated in 4.1.14 PC … As soon as the name and relevant information of each individual worker is known by the financial service provider and at the latest prior to the processing of any payment, the financial service provider will conduct mandatory security screening … Payment receipts will be made available to the Employer following each transfer … The terms of the subcontract will require the approval of the Employer before its signature."
        color="#FB636F"
        textColor="white"
        />
        <ShowText
        title="4.1.19 Contractor’s Site Office and Site facilities"
        text="The Contractor will establish a Site Office."
        color="#3264C8"
        textColor="white"
        />
        <ShowText
        title="4.3.1 Conditions for subcontracting"
        text="In the event that the Contractor requires the services of any subcontractor, the Contractor will obtain the prior written authorization to subcontract and the approval of the Employer of the subcontractor selected …"
        color="#69E4E4"
        textColor="#230050"
        />
        <ShowText
        title="7.2.3 Number of worker days per activity"
        text="The Contractor is required to indicate in the original Work Programme the number of worker days needed for each activity, which will be reviewed by the Employer and agreed by the Parties … Failure to achieve the agreed number of worker days will attract a penalty as stipulated in Sub-Clause 11.6.1 PC"
        color="#FAB428"
        textColor="#230050"
        />
        <ShowText
        title="11.1.2 Price Adjustment"
        text="…  Adjustments to the Contract price may be considered by the Employer and reflected in an addendum to the Contract, following sharp increase/decrease in the costs of local labour or Materials."
        color="#70B450"
        textColor="white"
        />
        <ShowText
        title="11.4 Payment of 2/3 of Retention"
        text="Two thirds of the retention (10%) will be paid by the Employer to the Contractor within fourteen (14) days after issuing the notice under Sub-Clause 8.2 GC upon successful completion of the Works."
        color="#AB3B77"
        textColor="white"
        />
        <ShowText
        title="11.5 Payment of remaining - 1/3 of Retention"
        text="The remainder of the retention (5%) will be paid by the Employer to the Contractor within fourteen (14) days upon approval by the Employer of the successful completion of the Defects and Liability Period stated in the Appendix and the receipt of the Defects and Liability certificate to be submitted by the Contractor."
        color="#FB636F"
        textColor="white"
        />
        <ShowText
        title="11.6.1 Penalty for failure in achieving number of Workdays"
        text="In the event that the Contractor fails to achieve the agreed target number of worker days stated in the Work Programme (see Sub-Clause 7.2.3 PC),  as verified through the muster roll, a penalty for a maximum up to 10% of the Contract Price will be applied to the final payment …"
        color="#3264C8"
        textColor="white"
        />
        <ShowText
        title="11.7.1 Exchange from USD into LBP"
        text="The Contract Price will be expressed entirely in United States Dollars (USD) and payments to the Contractor will be made in USD. To convert USD into LBP, the Contractor will use the conversion rate established by the Bank du Liban through the ‘Sayrafa’ electronic platform at the time of the transfer ..."
        color="#69E4E4"
        textColor="#230050"
        />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default ParticularConditions;