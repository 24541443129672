import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const SampleofaBoQ = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,1,user.uid);
        getUser();
        navigate("../Pricecomponents")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Sample of BoQ"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="SampleofaBoQ"
            prevLink="UnderstandingofcostinganddetailedUnitRateAnalysis"
            nextLink="Pricecomponents"
            chapterNumber='5'
            />
        <h1>Sample of BoQ</h1>
        <p>The typical EIIP BoQ is split into several columns to show the price breakdown per unit rate, including labour content</p>
        <img src={require('../../assets/sample-a-boq-2.jpg')} className="boq-ch3-img" alt=""  />
        <button style={{display:"block"}}><a  href={require("../../assets/BoQ-Sample.xlsx")} download>Download File</a></button>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}


export default SampleofaBoQ;