import "./dashboard.styles.scss";
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo,selectCurrentUser,selectAllUsers} from "../../store/user/user.selector";
import { getAllUsers } from "../../utils/firebase.utils";
import { setAllUsers } from "../../store/user/user.action";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
const Dashboard = () => {
    const navigate = useNavigate();
    const userInfo = useSelector(selectUserInfo);
    const user = useSelector(selectCurrentUser);
    const allUserss = useSelector(selectAllUsers);
    const dispatch = useDispatch();
  
    const [sortOrder, setSortOrder] = useState('asc');
    const [allUsers, setAllUserss] = useState(allUserss)
 
    useEffect(() => {
      const getUser = async () => {
       
        if(user){
          
          const userr = await getAllUsers();
          dispatch(setAllUsers(userr));
          setAllUsers(userr)
      
        }
    }
    getUser();
    },[])
  
  
    useEffect(() => {
        
        if(!userInfo.isAdmin){
            navigate("../")
       }
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[user])
  
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortedUsers, setSortedUsers] = useState(allUsers);
    
    const handleNameClick = () => {
        const sorted = sortedUsers.sort((a, b) => {
          if (!a.displayName || !b.displayName) {
            return 0;
          }
          if (sortDirection === 'asc') {
            return a.displayName.localeCompare(b.displayName);
          } else {
            return b.displayName.localeCompare(a.displayName);
          }
        });
        setFilteredUsers(sorted);
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      };
      const handleCreationDateClick = () => {
        const sorted = sortedUsers.sort((a, b) => {
          if (!a.createdAt || !b.createdAt) {
            return 0;
          }
          if (sortDirection === 'asc') {
            return new Date(a.createdAt) - new Date(b.createdAt);
          } else {
            return new Date(b.createdAt) - new Date(a.createdAt);
          }
        });
        setFilteredUsers(sorted);
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      };

    const handleCertificate = () => {
        const sorted = sortedUsers.sort((a, b) => {
         
          if (sortDirection === 'asc') {
            return a.certificate ? -1 : 1;
          } else {
            return b.certificate ? -1 : 1;
          }
    
        });
        setFilteredUsers(sorted);
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      };
      const [filter, setFilter] = useState('all');
      const handleFilterClick = (filterType) => {
        setFilter(filterType);
        let filteredUserrs = allUsers;
        if (filterType === 'certified') {
          filteredUserrs = allUsers.filter(user => user.certificate);
        } else if (filterType === 'notcertified'){
            filteredUserrs = allUsers.filter(user => !user.certificate);
        }

        setFilteredUsers(filteredUserrs);
      };
   
      const [searchQuery, setSearchQuery] = useState('');

      const handleSearch = (event) => {
        setSearchQuery(event.target.value);
      };
    const [filteredUsers,setFilteredUsers] = useState([])

    useEffect(() => {
      const filteredUserss = sortedUsers.filter((user) => {
        const displayName = user.displayName ? user.displayName.toLowerCase() : '';
        const email = user.email ? user.email.toLowerCase() : '';
        const createdAt = user.createdAt
          ? new Date(user.createdAt)
              .toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
              })
              .toUpperCase()
              .replace(/ /g, '/')
          : '';
      
        const query = searchQuery.toLowerCase();
        return (
          displayName.includes(query) ||
          email.includes(query) ||
          createdAt.includes(query)
        );
      });
      setFilteredUsers(filteredUserss)
    },[searchQuery])
     
      
      function handleSvgClick(event) {
        const clickedSvg = event.currentTarget;
        const clickedTr = clickedSvg.closest('tr');
        const td = clickedTr.querySelector('.long');
        const arrow = clickedTr.querySelector('.icon-holder>svg');
        console.log(arrow)
        const allTds = clickedTr.parentNode.querySelectorAll('.long');
        
        allTds.forEach((otherTd) => {
            if (otherTd !== td) {
                otherTd.style.height = '0px';
            }
        });
        
        if (td.style.height === '0px' || !td.style.height) {
            td.style.height = '300px';
            arrow.style.transform = "rotate(0deg)"
        } else {
            td.style.height = '0px';
            arrow.style.transform = "rotate(-90deg)"
        }
    }
      const [currentPage, setCurrentPage] = useState(1);
      const [itemsPerPage,setItemsPerPage] = useState(10)
      
      const getPaginatedData = (data) => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
      };
      const paginatedData = getPaginatedData(filteredUsers.filter((x) => x.id));
      const handlePrevClick = () => {
        setCurrentPage((prevPage) => prevPage - 1);
      };
      
      const handleNextClick = () => {
        setCurrentPage((prevPage) => prevPage + 1);
      };
      
  const handleRowsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
  };
  function ExportButton({ data }) {
    const handleExport = () => {
      // create a new workbook
      const workbook = XLSX.utils.book_new();
  
      // convert data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(data);
  
      // add worksheet to workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
      // generate Excel file and download
      XLSX.writeFile(workbook, "data.xlsx");
    };
  
    return <button className="export-button" onClick={handleExport}>Export For Excel</button>;
  }
    return (
        <div className="dashboard-wrapper">
          <div className="dashboard-top">
          <ExportButton data={paginatedData}/>
          <h1>ILO Training Users</h1>
        <div className="filters-holder">
            <button className={filter === 'all' ? "active" : ""} onClick={() => handleFilterClick('all')}>All</button>
            <button className={filter === 'certified' ? "active" : ""}  onClick={() => handleFilterClick('certified')}>Certified</button>
            <button className={filter === 'notcertified' ? "active" : ""} onClick={() => handleFilterClick('notcertified')}>In Progress</button>
        </div>
          </div>
     <div className="table-holder">
     {allUsers &&   
        <table>
        
      <thead>
      <tr className="search-bar">
        <td colSpan="2" style={{position:"relative"}}>
        <svg className="search-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4097 14.8822C11.7399 16.1799 9.63851 16.7922 7.53338 16.5942C5.42824 16.3963 3.47766 15.403 2.07881 13.8166C0.679961 12.2303 -0.0619809 10.1701 0.00405863 8.05565C0.0700982 5.94118 0.939153 3.9314 2.43427 2.43552C3.92939 0.939633 5.93814 0.0701341 8.05152 0.00406071C10.1649 -0.0620127 12.224 0.680308 13.8096 2.07987C15.3951 3.47944 16.3879 5.43102 16.5857 7.53723C16.7836 9.64345 16.1717 11.7459 14.8745 13.4166L19.6936 18.2201C20.1016 18.6267 20.1022 19.2872 19.695 19.6946C19.2878 20.1021 18.6273 20.1017 18.2204 19.6939L13.4201 14.8822H13.4097ZM8.31916 14.5495C9.13773 14.5495 9.94829 14.3882 10.7045 14.0748C11.4608 13.7614 12.148 13.302 12.7268 12.7229C13.3056 12.1438 13.7647 11.4563 14.078 10.6996C14.3913 9.94298 14.5525 9.13201 14.5525 8.31302C14.5525 7.49403 14.3913 6.68306 14.078 5.92641C13.7647 5.16976 13.3056 4.48225 12.7268 3.90314C12.148 3.32402 11.4608 2.86465 10.7045 2.55123C9.94829 2.23782 9.13773 2.07651 8.31916 2.07651C6.66598 2.07651 5.08051 2.73356 3.91153 3.90314C2.74256 5.07271 2.08583 6.659 2.08583 8.31302C2.08583 9.96705 2.74256 11.5533 3.91153 12.7229C5.08051 13.8925 6.66598 14.5495 8.31916 14.5495Z" fill="#6E889C"/>
</svg>

        <input placeholder="Search Users by Name, Email or Date" type="text" value={searchQuery} onChange={handleSearch} />
        </td>
       </tr>
        <tr>
          <th width="5%" className="empty-th"></th>
          <th width="30%" onClick={handleNameClick} >
            Name 
          </th>
          <th width="20%"  onClick={handleCreationDateClick}>
            Creation Date
          </th>
          <th onClick={handleCertificate}>
            Certificate Status
          </th>
        </tr>
      </thead>
      <tbody>
  {paginatedData.filter((x) => x.id).map(user => (
    <tr key={user.id}>
     
      <td className="icon-td">
        <div className="icon-holder">
        <svg  onClick={handleSvgClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.30526 13.1368C9.66316 13.4947 10.3158 13.4947 10.6737 13.1368L14.4632 9.34737C14.8421 8.96842 14.8421 8.35789 14.4632 7.97895C14.0842 7.6 13.4737 7.6 13.0947 7.97895L10 11.0737L6.90526 7.97895C6.71579 7.78947 6.46316 7.68421 6.21053 7.68421C5.95789 7.68421 5.70526 7.78947 5.51579 7.97895C5.13684 8.35789 5.13684 8.96842 5.51579 9.34737L9.30526 13.1368Z" fill="#6E889C"/>
<path d="M10 20C15.5158 20 20 15.5158 20 10C20 4.48421 15.5158 0 10 0C4.48421 0 0 4.48421 0 10C0 15.5158 4.48421 20 10 20ZM10 1.93684C14.4421 1.93684 18.0632 5.55789 18.0632 10C18.0632 14.4421 14.4421 18.0632 10 18.0632C5.55789 18.0632 1.93684 14.4421 1.93684 10C1.93684 5.55789 5.55789 1.93684 10 1.93684Z" fill="#6E889C"/>
</svg>
        </div>
      
</td>
      <td className="user-name"><h2>{user.displayName}</h2><h3>{user.email}</h3> </td>
      <td className="user-date">{new Date(user.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).toUpperCase().replace(/ /g, '/')}
</td>
      <td>{user.certificate ? <div className="certified-box"><span className="certified"><span className="certified-dot"></span> Certified</span><span className="certificate-date">on {new Date(user.certificateDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).toUpperCase().replace(/ /g, '/')}</span> </div> : <div className="inprogress-box"><span className="certified"><span className="certified-dot"></span> In Progress</span> </div>} </td>
    <div className="long">
       <h4>Occupation: <span>{user.Occupation}</span> </h4>
       <h4>Company: <span>{user.company}</span> </h4>
       <h4>Education Degree: <span>{user.educationDegree}</span> </h4>
       <h4>Experience Level: <span>{user.experienceLevel}</span> </h4>
       <h4>Field: <span>{user.field}</span> </h4>
       <h4>University: <span>{user.university}</span> </h4>
    </div>
    </tr>
  ))}
</tbody>
<tfoot>
  <tr>
    <td colSpan="4" style={{padding:0}}>
      <div className="pagination">
      Rows per page:
      <select id="rowsPerPage" value={itemsPerPage} onChange={handleRowsPerPageChange}>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
      </select>
      
      <button
        disabled={currentPage === 1}
        onClick={handlePrevClick}
      >
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.79971 1.10636C6.42812 0.51287 5.43313 -0.426818 4.80472 0.216126L0.196378 4.51891C-0.0654595 4.7662 -0.0654595 5.21131 0.196378 5.4586L4.80472 9.81084C5.43313 10.4043 6.42812 9.46464 5.79971 8.87115L1.71504 5.01348L5.79971 1.10636Z" fill="#1E2DBE"/>
</svg>

      </button>
      <button
        disabled={
          currentPage === Math.ceil(filteredUsers.length / itemsPerPage)
        }
        onClick={handleNextClick}
      >
      <svg style={{transform:"rotate(180deg)"}} width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.79971 1.10636C6.42812 0.51287 5.43313 -0.426818 4.80472 0.216126L0.196378 4.51891C-0.0654595 4.7662 -0.0654595 5.21131 0.196378 5.4586L4.80472 9.81084C5.43313 10.4043 6.42812 9.46464 5.79971 8.87115L1.71504 5.01348L5.79971 1.10636Z" fill="#1E2DBE"/>
</svg>

      </button>
      </div>
     
    </td>
  </tr>
</tfoot>
    </table>}
     </div>
    
      
        </div>
    )
}

export default Dashboard;