import RedPolyList from "../red-poly-list/red-poly-list.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const WhatisDecentWork = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,0,user.uid,1);
        getUser();
        navigate("../QuizQuestion")
    }
    const list = [
        {
            id:0,
            text:"Fair income"
        },
        {
            id:1,
            text:"Occupational safety and Health"
        },
        {
            id:2,
            text:"Protection against Child Labour"
        },
        {
            id:3,
            text:"Working days and hours"
        },
        {
            id:4,
            text:"Supporting Legality of status"
        },
        {
            id:5,
            text:"Employment Contracts"
        }
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="What is Decent Work"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="WhatisDecentWork"
            prevLink="ESSFArticlesintheContractTermsConditions"
            nextLink="QuizQuestion"
            chapterNumber='6'
            />
        <h1>What is Decent Work ?</h1>
        <h2>Decent Work Principles include:</h2>
        <RedPolyList list={list} />
        <p>All actors involved in the implementation of employment intensive projects have a responsibility to ensure compliance with Lebanese labour law, core international labour standards and decent work principles.</p>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}
export default WhatisDecentWork;