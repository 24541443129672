import './home.styles.scss'
import { Link } from 'react-router-dom';
import {ReactComponent as AngleIcon} from '../../assets/arrow-right.svg';
import { selectCurrentUser, selectUserInfo } from '../../store/user/user.selector';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUsers } from '../../utils/firebase.utils';
import { setUserInfo } from '../../store/user/user.action';
import { useState } from 'react';
const Home = () => {
    const user = useSelector(selectCurrentUser);
    const userInfo = useSelector(selectUserInfo)
   
    const [cont,setCont] = useState();
    const navigate = useNavigate()
   const dispatch = useDispatch()
    useEffect(() => {
        const getUser = async () => {
            if(user){
              const userr = await getUsers(user.uid);
              dispatch(setUserInfo(userr));
              setCont(userr.chapters[0].questions[1].status)
            }
           
           
           
          }
          getUser();
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[user])
    return (
        <div className="home-wrapper">
           
            <div className='home-info'>
                <h2>EIIP Training for Contractors</h2>
                <h1>Mandatory pre-qualification training for contractors wishing to bid on ILO EIIP projects</h1>
                <Link to="../chapters"><AngleIcon/> {!cont ? "Begin Your Training" : "Continue Your Training"}</Link>
            </div>
            <img src={require('../../assets/home-banner.png')} alt="" srcset="" />
        </div>
    )
}


export default Home;