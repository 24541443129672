import {ReactComponent as RedPoly} from '../../assets/polygon.svg';
import './red-poly-list.styles.scss';
import CircleShow from '../circle-show/circle-show.component';
import { Link } from 'react-router-dom';
const RedPolyList = ({title,list}) => {
    return (
        <>
        <h1>{title}</h1>
       
        {list.map((item) => {
            return (
                <div key={item.id} className='redpoly-list'>
                <RedPoly/>
                <p> {item.text} <br /> {item.link ? <a target="_blank" href={item.link}><p>{item.link2}</p></a> : null}</p>
                
                
                {item.circle ?  <CircleShow 
                        color={item.color}
                        circleId={item.circleId}
                        mainCircleId={item.mainCircleId}
                        boxId={item.boxId}
                        text={item.textt}
                       
                        /> : null}
               
                </div>
            )
        })}
        </>
    )
}

export default RedPolyList;