import FormInput from '../form-input/form-input.component';
import { Link , useNavigate} from 'react-router-dom';
import Button from '../button/button.component';
import { useState } from 'react';
import { signInAuthUserWithEmailAndPassword,generateResetPassword } from '../../utils/firebase.utils';
import './sign-in.styles.scss';

const defaultFormFields = {
   
    email:'',
    password:"",
    
}
const SignInForm = () => {
    const [formFields ,setFormFields] = useState(defaultFormFields);
    const {email,password} =formFields;
    const [errorMessage,setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const {user} = await signInAuthUserWithEmailAndPassword(email,password);
            if(user.emailVerified){
                navigate('../../home');
            } else {
                navigate('../../send-verify-email');
            }
            
        } catch (error) {
            if(error.code === "auth/user-not-found"){
               setErrorMessage("User Not Found")
            }
            if(error.code === "auth/wrong-password"){
                setErrorMessage("Wrong Password")
            }
            
        }
    }

    const handleChange = (event) => {
        const {name,value} = event.target;
        setFormFields({...formFields, [name]:value})
    }
    const handleReset = () => {
       navigate('/reset-password')
       
    }
    return (
        <>
        <div className="sign-in-wrapper">
            <div>
                <h1>Sign In</h1>
                <span>
                    <p>New User ?</p>
                    <Link to='/sign-up'>Create an account</Link>
                </span>
                <form onSubmit={handleSubmit}>
                    <FormInput label='Email address' placeholder="Please enter your email" type='email' onChange={handleChange} name="email" value={email}/>
                    <FormInput label='Password' placeholder="Password" type='password' onChange={handleChange} name="password" value={password}/>
                    <p style={{color:"red",fontWeight:"700"}}>{errorMessage}</p>
                    <div className='forgot-holder'>
                    <p onClick={handleReset}>Forgot Password ?</p>
                     <Button children='Sign in' style={{width:'30%'}}/>
                    </div>
                 
                </form>

            </div>
         
        </div>
       
        </>
    )
}

export default SignInForm;