import { useState, useRef } from "react";
import {ReactComponent as BlackLine} from '../../assets/line-black.svg';
import './true-or-false.styles.scss';
import gsap from "gsap";
const TrueAndTrue = ({question,answer}) => {
    const [showAnswer ,setShowAnswer] = useState(false);
    const [showInfo, setShowInfo] = useState(null);
    const circle1 = useRef();
    const circle2 = useRef();
    const dashh = useRef();
    const textRef = useRef();
    const tl = useRef();
    const app = useRef();
    const handleFeww = (target,target1,target3,target4) => {
        const ctx = gsap.context(() => {
           tl.current = gsap.timeline()
                .to(target,{scale:1.2,duration:0.3,ease:"ease-in"})
                .to(target1,{scale:1.2,duration:0.3,ease:"ease-in",delay:-0.5})
                .to(target,{scale:0,duration:0.5,ease:"ease-out"})
                .to(target1,{scale:0,duration:0.5,ease:"ease-out",delay:-0.5})
                .to(target3,{scale:0,duration:0.5,ease:"ease-out",delay:-0.5})
                .to(target,{display:"none",duration:0})
                .to(target1,{display:"none",duration:0})
                .to(target3,{display:"none",duration:0})
                .to(target4,{display:"block",duration:0,ease:"ease-in"})
                .to(target4,{y:0,opacity:1,duration:0.5,ease:"ease-in"})
         },app);
        return () => ctx.revert();
       }
    return (
        <>
        <h2>{question}</h2>
        <div ref={app} className='eiip-in-lebanon-circles'>
                <div ref={circle1} onClick={()=>{handleFeww(circle1.current,circle2.current,dashh.current,textRef.current);setShowInfo(true)}} className='eiip-in-lebanon-circle'><h3>True</h3></div>
                <div ref={dashh} id="dash">  <BlackLine className="black-line"/></div>
               
                 <div ref={circle2} onClick={()=>{handleFeww(circle1.current,circle2.current,dashh.current,textRef.current);setShowInfo(false)}} className='eiip-in-lebanon-circle'><h3>False</h3></div>
                 <p ref={textRef} style={{display:"none",opacity:0,transform:"translateY(100px)"}}>
                    <div style={{display:"flex",flexDirection:"row",alignItems:"flex-start",justifyContent:"start"}}>
                    {showInfo ? <h2 style={{color:"#70B450"}}>Well Done! <br /></h2> : <h2 style={{color:"red"}}>Well No! </h2> }
                 
                    </div>
                    {showInfo ? <p>{answer}</p> : <p>{answer}</p>}
            </p>
            </div>
        
           
        </>
    )
}

export default TrueAndTrue;