import './auth-banner.styles.scss'
import {ReactComponent as WhiteLogo} from '../../assets/logo-white.svg';

const AuthBanner = () => {
    return (
            <>
           <div className="auth-banner-wrapper">
            <div>
                <WhiteLogo/>
                <h1>EIIP Training for Contractors</h1>
                <h3>Prebid Training</h3>
                <p>Mandatory pre-qualification training for contractors wishing to bid on ILO EIIP projects</p>
            </div>

           </div>
         
        </>
    )
}

export default AuthBanner;