import { useState } from "react";
import './protectice-equipment.styles.scss';
import {ReactComponent as GreenTick} from '../../assets/tick-circle-green.svg';
import {ReactComponent as RedTick} from '../../assets/red-tick.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const PersonalProtectiveEquipment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,2,user.uid,4);
        getUser();
        navigate("../RiskAssessment")
    }
    const equipments = [
        {
            id:0,
            title:"Hard hats (helmets)",
            imgUrl :require("../../assets/hat.png"),
            options :[
                {
                    id:0,
                    text:"Risk of falling stones, etc.",
                    answer:true,
                    showAnswer:false
                },
                {
                    id:1,
                    text:"At all times",
                    answer:false
                },
                {
                    id:2,
                    text:"Dust from traffic and work",
                    answer:false
                },
                {
                    id:3,
                    text:"Risk of damage to hands",
                    answer:false
                },
                {
                    id:4,
                    text:"When raining",
                    answer:false
                },
                {
                    id:5,
                    text:"Flying stone chips, dust ",
                    answer:false
                },
                {
                    id:6,
                    text:"Traffic control ",
                    answer:false
                },
            ]
        },
        {
            id:1,
            title:"Reflective jackets",
            imgUrl :require("../../assets/jackets.png"),
            options :[
                {
                    id:0,
                    text:"Risk of falling stones, etc.",
                    answer:false,
                    showAnswer:false
                },
                {
                    id:1,
                    text:"At all times",
                    answer:true
                },
                {
                    id:2,
                    text:"Dust from traffic and work",
                    answer:false
                },
                {
                    id:3,
                    text:"Risk of damage to hands",
                    answer:false
                },
                {
                    id:4,
                    text:"When raining",
                    answer:false
                },
                {
                    id:5,
                    text:"Flying stone chips, dust ",
                    answer:false
                },
                {
                    id:6,
                    text:"Traffic control ",
                    answer:false
                },
            ]
        },
        {
            id:2,
            title:"Pairs of safety boots",
            imgUrl :require("../../assets/boots.png"),
            options :[
                {
                    id:0,
                    text:"Risk of falling stones, etc.",
                    answer:true,
                    showAnswer:false
                },
                {
                    id:1,
                    text:"At all times",
                    answer:false
                },
                {
                    id:2,
                    text:"Dust from traffic and work",
                    answer:false
                },
                {
                    id:3,
                    text:"Risk of damage to hands",
                    answer:false
                },
                {
                    id:4,
                    text:"When raining",
                    answer:false
                },
                {
                    id:5,
                    text:"Flying stone chips, dust ",
                    answer:false
                },
                {
                    id:6,
                    text:"Traffic control ",
                    answer:false
                },
            ]
        },
        {
            id:3,
            title:"Masks",
            imgUrl :require("../../assets/mask.png"),
            options :[
                {
                    id:0,
                    text:"Risk of falling stones, etc.",
                    answer:false,
                    showAnswer:false
                },
                {
                    id:1,
                    text:"At all times",
                    answer:false
                },
                {
                    id:2,
                    text:"Dust from traffic and work",
                    answer:true
                },
                {
                    id:3,
                    text:"Risk of damage to hands",
                    answer:false
                },
                {
                    id:4,
                    text:"When raining",
                    answer:false
                },
                {
                    id:5,
                    text:"Flying stone chips, dust ",
                    answer:false
                },
                {
                    id:6,
                    text:"Traffic control ",
                    answer:false
                },
            ]
        },
        {
            id:4,
            title:"Gloves",
            imgUrl :require("../../assets/gloves.png"),
            options :[
                {
                    id:0,
                    text:"Risk of falling stones, etc.",
                    answer:false,
                    showAnswer:false
                },
                {
                    id:1,
                    text:"At all times",
                    answer:false
                },
                {
                    id:2,
                    text:"Dust from traffic and work",
                    answer:false
                },
                {
                    id:3,
                    text:"Risk of damage to hands",
                    answer:true
                },
                {
                    id:4,
                    text:"When raining",
                    answer:false
                },
                {
                    id:5,
                    text:"Flying stone chips, dust ",
                    answer:false
                },
                {
                    id:6,
                    text:"Traffic control ",
                    answer:false
                },
            ]
        },
        {
            id:5,
            title:"Raincoats",
            imgUrl :require("../../assets/raincoats.png"),
            options :[
                {
                    id:0,
                    text:"Risk of falling stones, etc.",
                    answer:false,
                    showAnswer:false
                },
                {
                    id:1,
                    text:"At all times",
                    answer:false
                },
                {
                    id:2,
                    text:"Dust from traffic and work",
                    answer:false
                },
                {
                    id:3,
                    text:"Risk of damage to hands",
                    answer:false
                },
                {
                    id:4,
                    text:"When raining",
                    answer:true
                },
                {
                    id:5,
                    text:"Flying stone chips, dust ",
                    answer:false
                },
                {
                    id:6,
                    text:"Traffic control ",
                    answer:false
                },
            ]
        },
        {
            id:6,
            title:"Safety Goggles",
            imgUrl :require("../../assets/goggles.png"),
            options :[
                {
                    id:0,
                    text:"Risk of falling stones, etc.",
                    answer:false,
                    showAnswer:false
                },
                {
                    id:1,
                    text:"At all times",
                    answer:false
                },
                {
                    id:2,
                    text:"Dust from traffic and work",
                    answer:false
                },
                {
                    id:3,
                    text:"Risk of damage to hands",
                    answer:false
                },
                {
                    id:4,
                    text:"When raining",
                    answer:false
                },
                {
                    id:5,
                    text:"Flying stone chips, dust ",
                    answer:true
                },
                {
                    id:6,
                    text:"Traffic control ",
                    answer:false
                },
            ]
        },
        {
            id:7,
            title:"Traffic cones",
            imgUrl :require("../../assets/cones.png"),
            options :[
                {
                    id:0,
                    text:"Risk of falling stones, etc.",
                    answer:false,
                    showAnswer:false
                },
                {
                    id:1,
                    text:"At all times",
                    answer:false
                },
                {
                    id:2,
                    text:"Dust from traffic and work",
                    answer:false
                },
                {
                    id:3,
                    text:"Risk of damage to hands",
                    answer:false
                },
                {
                    id:4,
                    text:"When raining",
                    answer:false
                },
                {
                    id:5,
                    text:"Flying stone chips, dust ",
                    answer:false
                },
                {
                    id:6,
                    text:"Traffic control ",
                    answer:true
                },
            ]
        },
        {
            id:8,
            title:"Warning signs ",
            imgUrl :require("../../assets/attention.png"),
            options :[
                {
                    id:0,
                    text:"Risk of falling stones, etc.",
                    answer:false,
                    showAnswer:false
                },
                {
                    id:1,
                    text:"At all times",
                    answer:false
                },
                {
                    id:2,
                    text:"Dust from traffic and work",
                    answer:false
                },
                {
                    id:3,
                    text:"Risk of damage to hands",
                    answer:false
                },
                {
                    id:4,
                    text:"When raining",
                    answer:false
                },
                {
                    id:5,
                    text:"Flying stone chips, dust ",
                    answer:false
                },
                {
                    id:6,
                    text:"Traffic control ",
                    answer:true
                },
            ]
        },
        {
            id:9,
            title:"Stop/Go",
            imgUrl :require("../../assets/stop-sign.png"),
            options :[
                {
                    id:0,
                    text:"Risk of falling stones, etc.",
                    answer:false,
                    showAnswer:false
                },
                {
                    id:1,
                    text:"At all times",
                    answer:false
                },
                {
                    id:2,
                    text:"Dust from traffic and work",
                    answer:false
                },
                {
                    id:3,
                    text:"Risk of damage to hands",
                    answer:false
                },
                {
                    id:4,
                    text:"When raining",
                    answer:false
                },
                {
                    id:5,
                    text:"Flying stone chips, dust ",
                    answer:false
                },
                {
                    id:6,
                    text:"Traffic control ",
                    answer:true
                },
            ]
        },
    ]
    const [equip,setEquip] = useState(equipments)
    
    const handleChange = async (id,idd) =>  {
        await setEquip(equipments => 
            equipments.map(obj => {
                if (obj.id === id){
                   obj.options.map((objj) => {
                    if(objj.id === idd){
                        if(objj.answer){
                            objj.showAnswer = true
                        }else{
                            objj.showAnswer = true
                          const wer =  obj.options.find((x) => x.answer === true)
                          wer.showAnswer = true
                        }
                       
                    }
                   })
                    return {...obj  }
                }
               
                return obj;
                
            })
            )
     }
  
    return (
        <>
         <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Personal Protective Equipment (PPE)"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="PersonalProtectiveEquipment"
            prevLink="GeneralPrinciples"
            nextLink="RiskAssessment"
            chapterNumber='6'
            />
        <h1>Personal Protective Equipment (PPE)</h1>
        <p>Personal protection and safety equipment for one Maintenance Group</p>
        <h2>Select the right risk factor of each item</h2>
        <div className="protective-slider">
        <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      
    >
      {
            equip.map((item) => {
                return (
                    <SwiperSlide > <div className="equipments-box">
                    <h2>{item.id +1} of {equipments.length}:</h2>
                    <img src={item.imgUrl} alt="" />
                    <h2>{item.title}</h2>
                    {item.options.map((opt) => {
                        return (
                            <div className={opt.answer ? opt.showAnswer ? "options-box true" : "options-box" : "options-box"} onClick={() => {handleChange(item.id,opt.id)}}>
                            <h4>{opt.text}</h4>
                            {
                                opt.showAnswer ?  opt.answer ? <GreenTick/> : <RedTick/> : ""
                            }
                           
                            
                        </div>
                        )
                        
                    })}
                </div></SwiperSlide>
                   
                )
                
            })
        }
     
    </Swiper>
   
        </div>
        <p>In addition to all the above safety equipment, the First aid kit, plus replenishment is a must, containing:
</p>
<ol>
    <li>
        <p>plasters</p>
    </li>
    <li>
        <p>bandages</p>
    </li>
    <li>
        <p>disinfectant</p>
    </li>
    <li>
        <p>antiseptic cream</p>
    </li>
    <li>
        <p>clean fresh water for washing eyes
</p>
    </li>
    <li>
        <p>saline	</p>
    </li>
    <li>
        <p>sterile dressings</p>
    </li>
    <li>
        <p>adhesive tape</p>
    </li>
    <li>
        <p>disposable gloves</p>
    </li>
    <li>
        <p>irrigation syringe</p>
    </li>
    <li>
        <p>scissors</p>
    </li>
    <li>
        <p>tweezers</p>
    </li>
</ol>
        
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}
export default PersonalProtectiveEquipment;