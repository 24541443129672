import './risk-management-matrix.styles.scss';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const RiskManagementMatrix = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,5,user.uid,4);
        getUser();
        navigate("../ImplementationofOccupationalSafetyHealth")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Risk Management Matrix"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="RiskManagementMatrix"
            prevLink="DeterminingtheRisk"
            nextLink="ImplementationofOccupationalSafetyHealth"
            chapterNumber='6'
            />
            <h1>Example of a Risk Management Matrix</h1>
            <p>Determining the Risk = Estimate the Probability of Occurrence and Severity – and then identify mitigation measures </p>
            <div className='tables-holder'>
            <table className='matrix-risk-table'>
                <tr>
                    <th>Identified Risk</th>
                    <th>Likelihood <br /> (H/M/L)</th>
                    <th>Severity <br /> (H/M/L)</th>
                    <th>Risk level <br /> (R/Y/G)</th>
                    <th>Risk level <br /> (R/Y/G)</th>
                </tr>
                <tr>
                    <td><h4>Describe the risk here</h4></td>
                    <td><h4>M</h4></td>
                    <td><h4>M</h4></td>
                    <td style={{backgroundColor:"#FFCD2D"}}><h4>Colour coded</h4></td>
                    <td><h4>Describe preventive measures, mitigation measures and response in the event the accident occur</h4></td>
                </tr>
                <tr>
                    <td><h4>etc.</h4></td>
                    <td><h4>M</h4></td>
                    <td><h4>H</h4></td>
                    <td style={{backgroundColor:"#FA3C4B"}}></td>
                    <td></td>
                </tr>
            </table>
            </div>
            
            <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default RiskManagementMatrix;