import RedPolyList from "../red-poly-list/red-poly-list.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const LocalResourceBased = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(1,3,user.uid);
        getUser();
        navigate("../exercice-1")
    }
    const details = [
        {
            id: 0,
            text : "Includes work methods and technologies where the use of local resources and local labour is favoured and optimized in the delivery and maintenance of infrastructure assets, i.e. employ as many people as possible from the vicinity of the project."
        },
        {
            id: 1,
            text : "Local resources, capacities and materials are used to the greatest possible extent, but without adversely affecting the costs or duration of the specified works."
        },
        {
            id: 2,
            text : "Appropriate (light) equipment is used for support activities"
        },
        {
            id: 3,
            text : "Work is carried out with the above resources in a cost effective manner without compromising on the quality"
        },
    ]
    return (
        <>
         <BreadCrumb 
            chapterTitle="Local Resource Based Technology" 
            subTitle="Local Resource-based (LRB) Work Methods"
            chapterLink="LocalResourceBasedTechnology"
            subLink="LocalResource-based(LRB)WorkMethods"
            prevLink="lrbtvscfw"
            nextLink="exercice-1"
            chapterNumber="2"
            />
        <RedPolyList title="Local Resource-Based (LRB) Work Methods" list={details}/>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default LocalResourceBased;