import { Routes , Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo,selectCurrentUser } from "../../store/user/user.selector";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ChaptersNav from "../chapters-nav/chapters-nav.component";
import UnderstandingofcostinganddetailedUnitRateAnalysis from "./understanding-the-costing.component";
import SampleofaBoQ from "./sample-of-boq.component";
import Pricecomponents from "./price-components.component";
import UnitRateComposition from "./unit-rate-composition.component";
import ExerciceOne from "./exercise-one.component";
import DirectCost from "./direct-cost.component";
import LabourCost from "./labour-cost.component";
import CostofEquipmentandhandtools from "./const-equipments-one.component";
import TypicalhandtoolsforLRBTworks from "./typical-hand-tools.component";
import MaterialCost from "./material-cost.component";
import TotalDirectCost from "./total-direct-cost.component";
import ExerciceTwo from "./exercise-two.component";
import ExerciseThree from "./exercice-three.component";
import IndirectCosts from "./indirect-cost.component";
import ExerciseFour from "./exercise-four.component";
import UnitRate from "./unit-rate.component";
import QuizTemplate from "../quiz-template/quiz-template.component";
import Attempt from "./attempt";
const ChapterFive = () => {
    const userInfo = useSelector(selectUserInfo);
    const questions = userInfo.chapters[4].questions;
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    useEffect(() => {
        if(userInfo.chapters[3].grade < 80){
            navigate("../../chapters")
        }
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[user])
    const details = [
        {
            "id": 0,
            "title":"Understanding of costing and detailed Unit Rate Analysis",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"UnderstandingofcostinganddetailedUnitRateAnalysis"
        },
        {
            "id": 1,
        "title":"Sample of a BoQ",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"SampleofaBoQ"
        },
        {
            "id": 2,
        "title":"Price components",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"Pricecomponents"
        },{
            "id": 3,
            "title":"Unit Rate Composition",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"UnitRateComposition"
        },{
            "id": 4,
            "title":"Exercise 1",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"exercise-1"
        },{
            "id": 5,
            "title":"Direct Cost",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"DirectCost"
        },{
            "id": 6,
            "title":"Labour Cost",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"LabourCost"
        },{
            "id": 7,
            "title":"Cost of Equipment and hand tools",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"CostofEquipmentandhandtools"
        },
        {
            "id": 8,
            "title":"Typical hand tools for LRBT works",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"TypicalhandtoolsforLRBTworks"
        },
        
        {
            "id": 9,
            "title":"Exercise 2",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"exercise-2"
        },
        {
            "id": 10,
            "title":"Material Cost",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"MaterialCost"
        },
        {
            "id": 11,
            "title":"Total Direct Cost",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"TotalDirectCost"
        },
        {
            "id": 12,
            "title":"Exercise 3",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"exercise-3"
        },
        {
            "id": 13,
            "title":"Indirect Costs",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"IndirectCosts"
        },
        {
            "id": 14,
            "title":"Exercise 4",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"exercise-4"
        },
        {
            "id": 15,
            "title":"Unit Rate",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"UnitRate"
        },
        {
            "id": 16,
            "title":"Quiz",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"quiz"
        },
    
    ]
    window.scrollTo(0, 0);
    const a3 = details.map((t1) => ({ ...t1, ...questions.find((t2) => t2.id === t1.id) }));
    return (
        <>
        <div className="chapter-wrapper">
           <ChaptersNav title="Unit Rate Analysis and Pricing/BoQ for LRB Works" navLinks={a3} chapterTitle="UnitRateAnalysisandPricingBoQforLRBWorks"/>
           <div className="chapters-lessons">
          <Routes>
          <Route index path="UnderstandingofcostinganddetailedUnitRateAnalysis" element={<UnderstandingofcostinganddetailedUnitRateAnalysis/>}/>
          <Route  path="SampleofaBoQ" element={<SampleofaBoQ/>}/>
          <Route  path="Pricecomponents" element={<Pricecomponents/>}/>
          <Route path="UnitRateComposition" element={<UnitRateComposition/>}/>
          <Route path="exercise-1" element={<ExerciceOne/>}/>
          <Route path="DirectCost" element={<DirectCost/>}/>
          <Route path="LabourCost" element={<LabourCost/>}/>
          <Route path="CostofEquipmentandhandtools" element={<CostofEquipmentandhandtools/>}/>
          <Route path="exercise-2" element={<ExerciseThree/>}/>
          <Route path="TypicalhandtoolsforLRBTworks" element={<TypicalhandtoolsforLRBTworks/>}/>
          <Route path="MaterialCost" element={<MaterialCost/>}/>
          <Route path="TotalDirectCost" element={<TotalDirectCost/>}/>
          <Route path="exercise-3" element={<ExerciceTwo/>}/>
          <Route path="IndirectCosts" element={<IndirectCosts/>}/>
          <Route path="exercise-4" element={<ExerciseFour/>}/>
          <Route path="UnitRate" element={<UnitRate/>}/>
          <Route path="quiz" element={<QuizTemplate chapterNumber='5' quizLink='attempt' toPass="80%"
           chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
           subTitle="Quiz"
           chapterLink="Quiz"
           subLink="quiz"
           prevLink="UnitRate"
           chapterGrade={(Math.round(userInfo.chapters[4].grade * 100) / 100).toFixed(2)}
           nextLink="../EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer/Overview"
          />}/>
          <Route path="attempt" element={<Attempt/>}/>
       </Routes>
           </div>
         
        </div>
       
         
        </>
    )
}


export default ChapterFive;