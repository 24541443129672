import './choice-technology.styles.scss';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const ChoiceTechnology = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(1,7,user.uid);
        getUser();
        navigate("../exercice-2")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Local Resource Based Technology" 
            subTitle="Choice of technology - LRBT vs equipment"
            chapterLink="Socio-economic advantages of LRBT"
            subLink="ChoiceoftechnologyLRBTvsequipment"
            prevLink="LRB(perceived)Limitations"
            nextLink="exercice-2"
            chapterNumber="2"
            />
        <h1>Choice of technology - LRBT vs equipment</h1>
        <p>LRBT methods offer a minimal environmental impact by performing only the exact required task, due to the controlled and precise output of using hand tools</p>
        <div className="choice-holder">
            <div className='lf-choice'>
            <div className='choice-box'>
                <img src={require('../../assets/choice-1.jpg')} alt="" srcset="" />
                <h3>Common soil excavation work</h3>
                <span>- by Excavator</span>
            </div>
            <img className='replaced' src={require('../../assets/replaced.jpg')} alt="" srcset="" />
            </div>
            <div className='rf-choice'>
            <div className='choice-box'>
                <img src={require('../../assets/choice-2.png')} alt="" srcset="" />
                <h3>Common soil excavation work</h3>
                <span>- by Labourers</span>
            </div>
            </div>
        </div>
        <p>
        The LRBT approach must be subject to meticulous site preparation and quality control mechanisms in order to guarantee output quality

        </p>
        <div className="choice-holder">
            <div className='lf-choice'>
            <div className='choice-box'>
                <img src={require('../../assets/choice-3.jpg')} alt="" srcset="" />
                <h3>Grading gravel for road surface an of agricultural road </h3>
                <span>- by Grader</span>
            </div>
            <img className='replaced' src={require('../../assets/replaced.jpg')} alt="" srcset="" />
            </div>
            <div className='rf-choice'>
            <div className='choice-box'>
                <img src={require('../../assets/choice-4.jpg')} alt="" srcset="" />
                <h3>Spreading gravel for road surface of an agricultural road </h3>
                <span>- by Labourers</span>
            </div>
            </div>
        </div>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
        
    )
}

export default ChoiceTechnology;