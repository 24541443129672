import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const LrbLimitations = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(1,6,user.uid);
        getUser();
        navigate("../ChoiceoftechnologyLRBTvsequipment")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Local Resource Based Technology" 
            subTitle="LRB(perceived) Limitations"
            chapterLink="Socio-economic advantages of LRBT"
            subLink="LRB(perceived)Limitations"
            prevLink="Socio-economicadvantagesofLRBT"
            nextLink="ChoiceoftechnologyLRBTvsequipment"
            chapterNumber="2"
            />
        <h1>LRB(perceived) Limitations</h1>
        <p>The same limitations and challenges that affect the LRBT methods apply to any construction approach and any project management scheme. 
           <br /> The only ingredient to success is proper planning and monitoring, with attention to details and emphasis on human resources management (Labour)</p>
        <ShowText 
            title="Poor quality outputs" 
            text="Experiences have convincingly demonstrated that LRB projects can provide good quality results provided that there is effective control and that quality standards are adhered to (for example by using compaction equipment where necessary)."
            color="#3264C8"
            textColor="white"
            />
        <ShowText 
            title="Slow speed/progress and low outputs" 
            text="Productivity of labour can be increased several times if labour on site is effectively organised and supervised, motivated, and provided with good hand tools and appropriate support equipment."
            color="#AB3B77"
            textColor="white"
            />
        <ShowText 
            title="LRB projects are expensive" 
            text="Naturally, a lot depends on the productivity (output per average workday) achieved. It must be appreciated how essential it is to organise, manage and administrate labour-based, equipment-supported projects if they are to be successful."
            color="#FAB428"
            textColor="#230050"
            />
        <ShowText 
            title="Supervision and administrative problems" 
            text="It is certainly true that different management skills are required to effectively run LRB projects and that these skills are essential at both the engineer’s and the supervisory levels. The LRB training/orientation for manager and supervisory staff (engineer and supervisors) is a crucial prior to commencing the LRB works."
            color="#FB636F"
            textColor="white"
            />
            <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default LrbLimitations;