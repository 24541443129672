import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const ContractAdministration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(6,0,user.uid);
        getUser();
        navigate("../KeyContractDocuments")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Contract Administration..." 
            subTitle="Contract Administration"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="ContractAdministration"
            prevLink="../EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer/quiz-1"
            nextLink="KeyContractDocuments"
            chapterNumber="7"
            />
        <h1>Contract Administration</h1>
        <ShowText
        title="Initiation"
        color="#AB3B77"
        textColor="white"
        text="• Contract Award"
        text2="• Upstart meeting"
        text3="• Work Plan"
        />
       
        <ShowText
        title="Implementation"
        color="#FAB428"
        textColor="#230050"
        text="• All contractual communication bey-procurement@ilo.org"
        text2="• Performance monitoring"
        text3="• Adherence to contract conditions"
        text4="• Verification of IPCs"
        text5="• Checking Variation Orders & Preparation of Amendments"
        text6="• Monitoring complaints and managing disputes"
        />
        
         <ShowText
        title="Close out"
        color="#69E4E4"
        textColor="#230050"
        text="• Verify deliverables, Final Payment"
        text2="• Contract Evaluation KPI"
        />
        
        
         
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default ContractAdministration;