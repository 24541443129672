import RedPolyList from '../red-poly-list/red-poly-list.component';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const UnitRate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,15,user.uid);
        getUser();
        navigate("../quiz")
    }
    const list1 = [
        {
            id:0,
            text:"Decide which activities need to be carried out for the particular BoQ item"
        },
        {
            id:1,
            text:"Decide on the productivity rates (task rate for labour and production rate for equipment) to be used."
        },
        {
            id:2,
            text:"Enter the daily rates from your basic cost data sheets."
        },
        {
            id:3,
            text:"Identify the material requirements for the particular BoQ item and enter the respective rates"
        },
        {
            id:4,
            text:"Calculate the percentage for indirect costs"
        },
        {
            id:5,
            text:"Decide percentage Profit"
        },
        {
            id:6,
            text:"Calculate the total Unit Rate for each item of work included in the contract"
        },
    ]
    const list2 = [
        {
            id:0,
            text:"In general, indirect costs are relatively higher (as percentage of the direct costs) for smaller contracts than for larger contracts."
        },
        {
            id:1,
            text:"Depending on the size and complexity of the contract, supervision costs and company costs may range somewhere between 7% (for relatively large contracts) to 10% (for relatively small contracts) of the direct costs."
        },
        {
            id:2,
            text:"Depending on the Contractor’s assessment of possible unforeseen risks and the size of the contract, it is suggested that an allowance for risks in the range of 1% to 3% of the direct costs is included."
        },
        {
            id:3,
            text:"The companies should consider a Profit margin in the range from 7% to 10% of the direct and Indirect costs. Usually, profit margins are higher for relatively small contracts than for relatively large contracts."
        }
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Unit Rate"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="UnitRate"
            prevLink="exercise-4"
            nextLink="quiz"
            chapterNumber='5'
            />
        <h1>Unit Rate</h1>
        <h2>1 m<sup>3 </sup>of concrete</h2>
        <p>The indirect cost is to be added to the table as an additional percentage, on top of the direct implementation cost</p>
        <img src={require('../../assets/total-direct-cost.jpg')} className="boq-ch3-img" alt=""  />
        <h2>Compiling Unit Rate</h2>
        <h3>Steps to fill unit rate sheets:</h3>
        <RedPolyList list={list1}/>
        <h2>Fill the unit rates</h2>
        <p>It is essential to list the breakdown as per this BoQ format, especially to showcase labour intensity and WDs to be generated by each activity</p>
        <img src={require('../../assets/total-direct-cost.jpg')} className="boq-ch3-img" alt=""  />
        <h2>Remember</h2>
        <h4 className='red-title'>Here are some guidelines that bidders may want to consider in determining the percentage of indirect costs.</h4>
        <RedPolyList list={list2}/>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}


export default UnitRate;