import { useEffect, useState } from "react";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const ExerciseTwo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(3,4,user.uid);
        getUser();
        navigate("../TaskratesandProductivityPart2")
    }
    const [answer1,setAnswer1] = useState(null);
    const [answer2, setAnswer2] = useState(null)
    const [answer3, setAnswer3] = useState(null)
    const [answer4, setAnswer4] = useState(null)
    const [finalResult,setFinalResult] = useState(null);
    const [showfinalResult,setshowFinalResult] = useState(null);
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(answer1 && answer3 && !answer4 && answer2){
            setFinalResult(true);
        }else{
            setFinalResult(false);
        }
        setshowFinalResult(true)
       
       
      
    }
    const words = [
        {
            id:0,
            text:"The",
            order:0,
            visible:false,
            color:"#5F0037",
            orderNum: 21,
        },{
            id:1,
            text:"volume",
            order:1,
            visible: false,
            color:"#70B450",
            orderNum: 78,
        }
        ,{
            id:2,
            text:"of",
            order:2,
            visible: true,
            color:"#5A87CD",
            orderNum: 26,
        }
        ,{
            id:3,
            text:"work",
            order:16,
            visible: true,
            color:"#FB636F",
            orderNum: 15,
        },{
            id:4,
            text:"that",
            order:16,
            visible: true,
            color:"#049393",
            orderNum: 0,
        }
        ,{
            id:5,
            text:"can",
            order:16,
            visible: true,
            color:"#FAB428",
            orderNum: 109,
        }
        ,{
            id:6,
            text:"be",
            order:16,
            visible: true,
            color:"#5F0037",
            orderNum: 8,
        }
        ,{
            id:7,
            text:"achieved",
            order:16,
            visible: true,
            color:"#5A87CD",
            orderNum: 79,
        }
        ,{
            id:8,
            text:"by",
            order:16,
            visible: true,
            color:"#FB636F",
            orderNum: 15,
        }
        ,{
            id:9,
            text:"one",
            order:16,
            visible: true,
            color:"#049393",
            orderNum: 9,
        }
        ,{
            id:10,
            text:"worker",
            order:16,
            visible: true,
            color:"#FAB428",
            orderNum: 913,
        }
        ,{
            id:11,
            text:"/",
            order:16,
            visible: true,
            color:"#5F0037",
            orderNum: 14,
        }
        ,{
            id:12,
            text:"equipment",
            order:16,
            visible: true,
            color:"#70B450",
            orderNum: 59,
        }
        ,{
            id:13,
            text:"during",
            order:16,
            visible: true,
            color:"#5A87CD",
            orderNum: 311,
        }
        ,{
            id:14,
            text:"one",
            order:16,
            visible: true,
            color:"#FB636F",
            orderNum: 879,
        }
        ,{
            id:15,
            text:"day",
            order:2,
            visible: true,
            color:"#FAB428",
            orderNum:31
        }
        
    ]
    const [wordOrder,setWordOrder] = useState(3)
    const [wordsArray,setWordsArray] = useState(words);
    const [sentenceArray, setSentenceArray] = useState(words);
    const [answer,setAnswer] = useState(false);
    const [showAnswer,setShowAnswer] = useState(false);

   
    
   
      
    const handleWords = (id) => {
        setWordsArray(words => 
            words.map((word) => {
                if(word.id === id){
                   word.visible = !word.visible
                }
                return word
        }
        ))
       
        setSentenceArray(words => 
            
            words.map((word) => {
                if(word.id === id ){
                    const num = 0;
                    
                    setWordOrder(order => order + 1)
                   word.order = wordOrder + 1
                }
                return word
        }
        ))
    }
  
    const handleValidate = () => {
        let sentence = ""
        sentenceArray.sort((x,y) => {return x.order - y.order}).map((item) => {
           sentence += ` ${item.text}`
            
        })
        
        if(sentence === " The volume of work that can be achieved by one worker / equipment during one day"){
            setAnswer(true);
            setShowAnswer(true);
        }else{
            setAnswer(false);
            setShowAnswer(true);
        }
    }
   
    
    return (
        <>
         <BreadCrumb 
            chapterTitle="Task Rates and Productivity Norms" 
            subTitle="Exercise"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="exercice-2"
            prevLink="TaskratesandProductivityPart1"
            nextLink="TaskratesandProductivityPart2"
            chapterNumber='4'
            />
        <h1>Exercise</h1>
        <h2>20 WDs can refer to:</h2>
        <form className="exercice-form" onSubmit={handleSubmit}>
        <input onClick={() => setAnswer1(true)}   type="checkbox" name="5 labours working for 4 days" id="5 labours working for 4 days" value="5 labours working for 4 days"  /><label for="5 labours working for 4 days">5 labours working for 4 days</label><br />
        <input onClick={() => setAnswer2(true)}   type="checkbox" name="10 labours working for 2 days" id="10 labours working for 2 days" value="10 labours working for 2 days"  /><label for="10 labours working for 2 days">10 labours working for 2 days</label><br />
        <input onClick={() => setAnswer3(true)}   type="checkbox" name="1 labour working for 20 days" id="1 labour working for 20 days" value="1 labour working for 20 days"  /><label for="1 labour working for 20 days">1 labour working for 20 days</label><br />
        <input onClick={() => setAnswer4(true)}   type="checkbox" name="20 labours working for 20 days" id="20 labours working for 20 days" value="20 labours working for 20 days"  /><label for="20 labours working for 20 days">20 labours working for 20 days</label><br />
        <button type="submit">Submit</button>
        </form>
        {
        showfinalResult ?  finalResult ? 
            <div>
            <h2>Well Done!</h2>
           
        </div> :  <div>
            <h2>Well No!</h2>
            <h3>20 WDS can refer to : 5 labours working for 4 days ,10 labours working for 2 days, 1 labour working for 20 days </h3>
          
        </div> : null
        }
        <h2>Click on the words in order, to continue the sentence</h2>
        
        <div className="sentence-words-box">
        {wordsArray.sort((x,y) => {return x.orderNum -+ y.orderNum}).map((item) =>{
            return (
                <div key={item.id}  className="sentence-words-holder" >
                    <p className={item.visible ? "" : "scaleBox"} style={{border:`2px solid ${item.color}`,color:item.color}} onClick={()=> handleWords(item.id)}> {item.text}</p>
               
            </div>
            )
            
        })}
        </div>
        <h3>Labour and Equipment Task Rates are essential for :</h3>
        <div className="sentence-div">
        
        {sentenceArray.sort((x,y) => {return x.order - y.order}).map((item) =>{
            return (
                <div key={item.id} style={{order:item.order}} className={`sentence-words-holder ${!item.visible ? " showBox" : " hideBox"}`} >
                    <p onClick={()=>{
                        if(item.id === 0 || item.id===1){
                            return
                            }else{handleWords(item.id)}
                            }} className={!item.visible ? "scaleBoxIn" : "scaleBox"} style={{border:`2px solid ${item.color}`,color:item.color}}  > {item.text}</p>
               </div>
            )
            
        })}
        </div>
        {
            showAnswer ? null : <button onClick={handleValidate}>Validate</button>
        }
        {
            showAnswer ? answer ? 
            <div>
                <p>You got it right</p>
                <button onClick={handleUpdates}>Mark As Completed</button>
                </div> : 
            
            <div><h2>Well No! 'The Volume of work that can be achieved by one work / equipment during one day'</h2><button onClick={handleUpdates}>Mark As Completed</button></div> : null
        }
        </>
    )
}


export default ExerciseTwo;