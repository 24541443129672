import './big-rectangle.styles.scss';
const BigRectangle = ({numbre,title,color,textColor}) => {
    return (
        <div style={{backgroundColor:color}} className="big-rectangle">
            <div  style={{color:color}} className="big-rectangle-number">{numbre}</div>
            <h2 style={{color:textColor}}>{title}</h2>
        </div>
    )
}

export default BigRectangle;