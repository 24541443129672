import { useState } from "react";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const QuizQuestion = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
            updateChapterss(5,1,user.uid,1);
        getUser();
        navigate("../DecentWorkConditions")
    }
    const [answer1,setAnswer1] = useState(false);
    const [answer2, setAnswer2] = useState(false)
    const [answer3, setAnswer3] = useState(false)
    const [answer4, setAnswer4] = useState(false)
    const [answer5, setAnswer5] = useState(false)
    const [answer6, setAnswer6] = useState(false)
    const [finalResult,setFinalResult] = useState(false);
    const [showfinalResult,setshowFinalResult] = useState(false);
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(answer1 && answer3 && answer4 && !answer2 && !answer5 && !answer6){
            setFinalResult(true);
        }else{
            setFinalResult(false);
        }
        setshowFinalResult(true)
       
      
    }

    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Quiz question"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="QuizQuestion"
            prevLink="WhatisDecentWork"
            nextLink="DecentWorkConditions"
            chapterNumber='6'
            />
        <h1>Exercise 2</h1>
        <h2>Which of the below items are among the Decent Work principles? (Select all that apply)</h2>
        <form className="exercice-form" onSubmit={handleSubmit}>
        <input onClick={() => setAnswer1(true)}   type="checkbox" name="Fair recruitment" id="Fair recruitment" value="Fair recruitment"  /><label for="Fair recruitment">Fair recruitment</label><br />
        <input onClick={() => setAnswer2(true)}   type="checkbox" name="Recruitment of women only (prioritizing women over men)" id="Recruitment of women only (prioritizing women over men)" value="Recruitment of women only (prioritizing women over men)"  /><label for="Recruitment of women only (prioritizing women over men)">Recruitment of women only (prioritizing women over men)</label><br />
        <input onClick={() => setAnswer3(true)}   type="checkbox" name="Provide safe and free of charge transportation" id="Provide safe and free of charge transportation" value="Provide safe and free of charge transportation"  /><label for="Provide safe and free of charge transportation">Provide safe and free of charge transportation</label><br />
        <input onClick={() => setAnswer4(true)}   type="checkbox" name="Provide training and capacity building" id="Provide training and capacity building" value="Provide training and capacity building"  /><label for="Provide training and capacity building">Provide training and capacity building</label><br />

        <input onClick={() => setAnswer5(true)}   type="checkbox" name="Work without signing any contract" id="Work without signing any contract" value="Work without signing any contract"  /><label for="Work without signing any contract">Work without signing any contract</label><br />
        <input onClick={() => setAnswer6(true)}   type="checkbox" name="Recruit only skilled workers and those that are knowledgeable" id="Recruit only skilled workers and those that are knowledgeable" value="Recruit only skilled workers and those that are knowledgeable"  /><label for="Recruit only skilled workers and those that are knowledgeable">Recruit only skilled workers and those that are knowledgeable</label><br />
        <button type="submit">Submit</button>
        </form>
        {
        showfinalResult ?  finalResult ? 
            <div>
            <h2>Well Done!</h2>
            <button onClick={handleUpdates}>Mark As Completed</button> 
        </div> :  <div>
            <h2>Well No!</h2>
            <h3>The main points are : Fair recruitment, Provide safe and free of charge transportation
, Provide training and capacity building
</h3>
            <button onClick={handleUpdates}>Mark As Completed</button> 
        </div> : null
        }
        </>
    )
}


export default QuizQuestion;