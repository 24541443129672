import RedPolyList from "../red-poly-list/red-poly-list.component"
import TrueAndFalse from "../trueorfalse/true-or-false.component";
import FalseAndTrue from "../trueorfalse/false-or-true.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import { useState } from "react";
const Whatcanbedone = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,1,user.uid,5);
        getUser();
        navigate("../PromotingInclusiveParticipation")
    }
    const list = [
        {
            id:0,
            text:"Equal Opportunity Approach"
        },
        {
            id:1,
            text:"Set Milestones for the effective employment of women"
        },
        {
            id:2,
            text:"Set Milestones for the effective employment of persons with disabilities"
        },
        {
            id:3,
            text:"Project team to monitor women participation and the participation of PWD regularly"
        },
    ]
    const [exerc1,setExerc1] = useState(false)
    return (
        <>
         <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="What can be done ?"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="Whatcanbedone"
            prevLink="WomensParticipationVIDEO"
            nextLink="PromotingInclusiveParticipation"
            chapterNumber='6'
            />
        <h1>What can be done ?</h1>
        <RedPolyList list={list}/>
        <p>Employers in Lebanon are prohibited from discriminating between men and women with regards to type of work, salary, recruitment, or promotion</p>
        <div onClick={() => setExerc1(true)}>
        <FalseAndTrue
                question="Is the following statement true or false? “It is okay for men and women to be paid differently even if they were performing the same task because men are more experienced than women and can perform better.”"
                answer="Equal pay for Equal work is a basic principle of decent work and must be always implemented. If a worker requires additional capacity building to perform better, it is the responsibility of the contractor to provide them with the needed on-the-job training. 
                "
        />
        </div>
       {
        exerc1 ?  <button onClick={handleUpdates}>Mark As Completed</button> : null
       }
       
        </>
    )
}

export default Whatcanbedone;