import {ReactComponent as RedPoly} from '../../assets/polygon.svg';
import './task-rates-productivity.styles.scss';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const TaskratesandProductivityPart1 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(3,3,user.uid);
        getUser();
        navigate("../exercice-2")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Task Rates and Productivity Norms" 
            subTitle="Task rates and Productivity"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="TaskratesandProductivityPart1"
            prevLink="exercise-1"
            nextLink="exercice-2"
            chapterNumber='4'
            />
        <h1>Task rates and Productivity</h1>
        <p>Task rates are the essential foundation that allows for an accurate pricing and planning</p>
        <img src={require('../../assets/taskratesproductivity-2.png')} className="boq-ch3-img" />
        <p>It is fundamental to understand the difference between:</p>
        <p>Day: time reference of a day on a schedule <br /> Working Day (WD): Quantitative reference of a Worker that has performed 1 full Day of Work</p>
        <h3><RedPoly className='task-rates-poly'/> Definition of Task rate:</h3>
        <ul>
            <li><p><b> Labour/equipment Task Rate:</b> The amount of work that can be completed by one labourer/equipment in a standard working day.</p></li>
            <li><p><b> Task rate example 1: </b>If 1 unskilled labourer can complete the excavation of 2 m<sup>3 </sup> of soil in one day, so the  Labour task rate for the excavation is: 2 m<sup>3 </sup> / Wd (Work-day)</p></li>
        </ul>
        <h3><RedPoly className='task-rates-poly'/> Work-day (Wd) Example</h3>
        <ul>
            <li><p>1 Wd = 1 worker working for 1 day</p></li>
            <li><p>10 Wds	= 1 worker working for 10 days <b>OR</b> <br />
                 10 Workers working for 1 day <b>OR</b><br />
                 5 workers working for 2 days</p></li>
        </ul>
        <h3><RedPoly className='task-rates-poly'/>Definition of Productivity:</h3>
        <ul>
            <li><p><b> Labour/equipment Productivity:</b> The number of labour/equipment Work-day (Wd) required to complete 1 unit of work in a standard working day.</p></li>
         </ul>
         <div className='task-rates-red-border'>
            <p>EXAMPLE 1: If 1 unskilled labourer can complete the excavation of 2 m<sup>3 </sup>of soft soil in one day →   So the  Labour task rate for the excavation is: 2 m<sup>3 </sup>/Wd (Work-day)</p>
<p>In the EXAMPLE 1, Labour task rate for the excavation is: 2 m<sup>3 </sup>/Wd → So labour productivity for the excavation is =  1/2 Wd / m<sup>3 </sup>(or 0.5 Wd/m3)</p>
         </div>
         <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default TaskratesandProductivityPart1;