import { useState } from "react";
import { useSelector } from "react-redux";
import './exercice-three.styles.scss';
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import { useDispatch } from "react-redux";
import { getUsers, updateChapters } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { setUserInfo } from "../../store/user/user.action";
import { selectCurrentUser } from "../../store/user/user.selector";
const ExerciseThree = () => {
    const words = [
        {
            id:0,
            text:"the",
            order:1,
            visible:false,
            color:"#5F0037",
            orderNum:0,
        },{
            id:1,
            text:"volume",
            order:2,
            visible: false,
            color:"#70B450",
            orderNum:1,
        }
        ,{
            id:2,
            text:"of",
            order:16,
            visible: true,
            color:"#5A87CD",
            orderNum:2,
        }
        ,{
            id:3,
            text:"work",
            order:16,
            visible: true,
            color:"#FB636F",
            orderNum:13,
        },{
            id:4,
            text:"that",
            order:16,
            visible: true,
            color:"#049393",
            orderNum:17,
            
        }
        ,{
            id:5,
            text:"can",
            order:16,
            visible: true,
            color:"#FAB428",
            orderNum:3,
        }
        ,{
            id:6,
            text:"be",
            order:16,
            visible: true,
            color:"#5F0037",
            orderNum:8,
        }
        ,{
            id:7,
            text:"achieved",
            order:16,
            visible: true,
            color:"#5A87CD",
            orderNum:1,
        }
        ,{
            id:8,
            text:"by",
            order:16,
            visible: true,
            color:"#FB636F",
            orderNum:12,
        }
        ,{
            id:9,
            text:"a",
            order:16,
            visible: true,
            color:"#049393",
            orderNum:3,
        }
        ,{
            id:10,
            text:"machine",
            order:16,
            visible: true,
            color:"#FAB428",
            orderNum:11,
        }
        ,{
            id:11,
            text:"/",
            order:16,
            visible: true,
            color:"#5F0037",
            orderNum:2,
        }
        ,{
            id:12,
            text:"equipment",
            order:16,
            visible: true,
            color:"#70B450",
            orderNum:18,
        }
        ,{
            id:13,
            text:"and",
            order:16,
            visible: true,
            color:"#5A87CD",
            orderNum:14,
        }
        ,{
            id:14,
            text:"price",
            order:16,
            visible: true,
            color:"#FB636F",
            orderNum:9,
        }
        ,{
            id:15,
            text:"Calculating",
            order:0,
            visible: false,
            color:"#FAB428",
            orderNum: 5,
        }
        ,{
            id:16,
            text:"the",
            order:16,
            visible: true,
            color:"#049393",
            orderNum:16,
        }
        ,{
            id:17,
            text:"items",
            order:16,
            visible: true,
            color:"#FAB428",
            orderNum:15,
        }
        ,{
            id:18,
            text:"accordingly",
            order: 3,
            visible: true,
            color:"#FAB428",
            orderNum:6
        }
    ]
    const [wordOrder,setWordOrder] = useState(3)
    const [wordsArray,setWordsArray] = useState(words);
    const [sentenceArray, setSentenceArray] = useState(words);
    const [answer,setAnswer] = useState(false);
    const [showAnswer,setShowAnswer] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,9,user.uid);
        getUser();
        navigate("../MaterialCost")
    }
    const handleWords = (id) => {
        setWordsArray(words => 
            words.map((word) => {
                if(word.id === id){
                   word.visible = !word.visible
                }
                return word
        }
        ))
       
        setSentenceArray(words => 
            
            words.map((word) => {
                if(word.id === id ){
                    const num = 0;
                    
                    setWordOrder(order => order + 1)
                   word.order = wordOrder + 1
                }
                return word
        }
        ))
    }
    const handleWordss = (id) => {
        setWordsArray(words => 
            words.map((word) => {
                if(word.id === id){
                   word.visible = !word.visible
                }
                return word
        }
        ))
       
        setSentenceArray(words => 
            
            words.map((word) => {
                if(word.id === id){
                    const num = 0;
                    setWordOrder(order => order + 1)
                   word.order = wordOrder + 1
                   word.visible = !word.visible
                }
                return word
        }
        ))
    }
    
    const handleValidate = () => {
        let sentence = ""
        sentenceArray.sort((x,y) => {return x.order - y.order}).map((item) => {
           sentence += ` ${item.text}`
            
        })
       
        if(sentence === " Calculating the volume of work that can be achieved by a machine / equipment and price the items accordingly"){
            setAnswer(true);
            setShowAnswer(true);
        }else{
            setAnswer(false);
            setShowAnswer(true);
        }
    }

    return (
        <>
        <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Exercise"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="exercise-2"
            prevLink="TypicalhandtoolsforLRBTworks"
            nextLink="MaterialCost"
            chapterNumber='5'
            />
        <h1>Exercise</h1>
        <h2>Click on the words in order, to continue the sentence</h2>
        
        <div className="sentence-words-box">
        {wordsArray.sort((x,y) => {return x.orderNum - y.orderNum}).map((item) =>{
            return (
                <div key={item.id}  className="sentence-words-holder" >
                    <p className={item.visible ? "" : "scaleBox"} style={{border:`2px solid ${item.color}`,color:item.color}} onClick={()=> handleWords(item.id)}> {item.text}</p>
               
            </div>
            )
            
        })}
        </div>
        <h3>Labour and Equipment Task Rates are essential for :</h3>
        <div className="sentence-div">
        
        {sentenceArray.sort((x,y) => {return x.order - y.order}).map((item) =>{
            return (
                <div key={item.id} style={{order:item.order}} className={`sentence-words-holder ${!item.visible ? " showBox" : " hideBox"}`} >
                    <p onClick={()=>handleWords(item.id)} className={!item.visible ? "scaleBoxIn" : "scaleBox"} style={{border:`2px solid ${item.color}`,color:item.color}}  > {item.text}</p>
               </div>
            )
            
        })}
        </div>
        {
            showAnswer ? null : <button style={{display:"block"}} onClick={handleValidate}>Validate</button>
        }
       
        {
            showAnswer ? answer ? 
            <div>
                <h2>Well Yes!</h2>
                <button onClick={handleUpdates}>Mark As Completed</button>
                </div> : 
            
            <div><h2>Well No! Calculating the volume of work that can be achieved by a machine / equipment and price the items accordingly </h2>  <button onClick={handleUpdates}>Mark As Completed</button></div> : null
        }
        </>
    )
}


export default ExerciseThree;