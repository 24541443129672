import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const ScreeningandAnalysis = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,3,user.uid,7);
        getUser();
        navigate("../ScreeningandAnalysisSocialFeasibility")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Screening and Analysis"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="ScreeningandAnalysis"
            prevLink="SocialandEnvironmentalSafeguards"
            nextLink="ScreeningandAnalysisSocialFeasibility"
            chapterNumber='6'
            />
        <h1>Social and Environmental Safeguards - Screening and Analysis
</h1>
<ShowText
    title="Why"
    color="#AB3B77"
    text="• To identify mitigation measures to environmental and social related concerns"
    textColor="white"
/>

<ShowText
    title="When"
    color="#3264C8"
    textColor="white"
    text="• During the Identification, Prioritization, Inspection, Selection and Detailed Surveying of the project"
/>

<ShowText
    title="How"
    color="#69E4E4"
    textColor="#230050"
    text="• Social Feasibility Analysis"
    text2="• Environmental Screening"
/>

<button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default ScreeningandAnalysis;