import SquareShow from "../square-show/square-show.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import { useState } from "react";
const Overview = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,0,user.uid,0);
        getUser();
        navigate("../LegalContext")
    }
    const [exerc1,setExerc1] = useState(false)
    return (
        <>
          <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Overview"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="Overview"
            prevLink="../UnitRateAnalysisandPricingBoQforLRBWorks/quiz"
            nextLink="LegalContext"
            chapterNumber='6'
            />
        <h1>What is the Environment and Social Safeguard Framework (ESSF)?</h1>
        <p>The ESSF is based on Laws and regulations of the Government of Lebanon, together with policies and guidelines of the International Labour Organization (ILO) related to social and environmental safeguards as well as relevant International Conventions of the ILO and the United Nations. <br /> 
            The ESSF is developed for guidance to be used by Contractors and Contracts Supervisors, as well as by designated Social & Environmental Safeguards Officers (SSOs) on employment intensive projects.</p>
        <p>The ESSF is designed to guide implementation of inclusive and rights-based practices in infrastructure & mitigate negative social & environmental impacts. The ESSF governs all EIIP Projects, it is contractually binding, and mandatory for the contractors to work on its basis.</p>
        <h2>The ESSF:</h2>
        <div className="squares-holder" onClick={() => setExerc1(exerc1=> exerc1+ 1)}>
             <SquareShow color="#FAB428" title="Increases the likelihood that outcomes of the infrastructure projects are as inclusive & equitable as possible"/>
       
        <SquareShow color="#FB636F" textColor="white" title="Ensures that concerns of different stakeholders are adequately addressed in EIPs"/>
        <SquareShow color="#3264C8" textColor="white" title="Establishes mitigation practices & policies to social & env. risks which could hinder the implementation of the EIPs"/>
        </div>
        {exerc1 >= 3 ? <button onClick={handleUpdates}>Mark As Completed</button> : null}
        
        </>
    )
}


export default Overview;