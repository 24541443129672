import TrueAndFalse from "../trueorfalse/true-or-false.component";
import FalseAndTrue from "../trueorfalse/false-or-true.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import { useState } from "react";
const ExerciseThree = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(3,6,user.uid);
        getUser();
        navigate("../Exampleofsomelabourproductivitynorms")
    }
  
    const [exerc1,setExerc1]= useState(false)
    const [exerc2,setExerc2]= useState(false)
    return (
        <>
        <BreadCrumb 
            chapterTitle="Task Rates and Productivity Norms" 
            subTitle="Exercise"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="exercise-3"
            prevLink="TaskratesandProductivityPart2"
            nextLink="Exampleofsomelabourproductivitynorms"
            chapterNumber='4'
            />
        <h1>Exercise</h1>
        <div onClick={() => setExerc1(true)}>
        <FalseAndTrue 
            question="Task rates are set theoretical figures that are not affected by local circumstances"
            answer="Task rates are set theoretical figures that are affected by local circumstances"
            />
        </div>
        <div onClick={() => setExerc2(true)}>
        <FalseAndTrue 
            question="Task rates are only needed to estimate the cost of labour while bidding"
            answer="Task rates are not only needed to estimate the cost of labour while bidding"
            />
        </div>
        {
            exerc1 && exerc2 ? <button onClick={handleUpdates}>Mark As Completed</button> : null
        }
        
        </>
    )
}


export default ExerciseThree;