import { initializeApp } from 'firebase/app';
import { sendPasswordResetEmail,sendEmailVerification } from 'firebase/auth';
import {signInWithEmailAndPassword,createUserWithEmailAndPassword, getAuth , onAuthStateChanged, signOut} from 'firebase/auth';
import {getDatabase, ref, get, child, set, update} from 'firebase/database';
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
   
};
const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getDatabase();


//Sign Up New User
export const createAuthUserWithEmailAndPassword = async(email, password) => {
    if (!email || !password) return;

     return await createUserWithEmailAndPassword(auth, email, password);
}

export const createUserDocumentFromAuth = async(userAuth, displayName) => {

    if (!userAuth) return;
    const dbRef = ref(getDatabase());
    const userSnapshott = await get(child(dbRef,`users/${userAuth.uid}`));

    if(!userSnapshott.exists()){
        const usersData = ref(db,'users');
        const userData = child(usersData,userAuth.uid);
        const createdAt = Date();
        try {
            await set(userData,{
                createdAt,
                email: userAuth.email,
                displayName,
                id: userAuth.uid
                
                
            })
        } catch (error) {
           
        }
    }
    return userSnapshott;
}
const actionCodeSettings = {
    url:"https://www.creativecouple.com",
    handleCodeInApp: true,
}
export const generateResetPassword = async (email) => {
    sendPasswordResetEmail(auth,email,{url:"https://www.lrbttraining.eiiplebanon.com"})
}
export const generateVerificationEmail = async (user) => {
    sendEmailVerification(user,{url:"https://www.lrbttraining.eiiplebanon.com"})
}
// Update personal info

export const updatePersonalInfo = async (occupation,experienceLevel,company,educationDegree,university,field,id) => {
    const updateRef = ref(db,'users/');
    const updateRefChild = child(updateRef,id);
    update(updateRefChild, {
        Occupation: occupation,
        experienceLevel : experienceLevel,
        company: company,
        educationDegree : educationDegree,
        university: university,
        field: field,
        certificate:false,
        chapters : [
            {
                id :0,
                status: "start",
                canStart:true,
                grade:0,
                questions: [
                    {
                        id :0,
                        status:false,
                        url:"video-eiip"
                    },
                    {
                        id :1,
                        status:false,
                        url:"what-is-the-international-labour-organization"
                    },
                    {
                        id :2,
                        status:false,
                        url:"the-employment-intensive-infrastructure-programme"
                    },
                    {
                        id :3,
                        status:false,
                        url:"eiip-in-lebanon"
                    },
                    {
                        id :4,
                        status:false,
                        url:"eiip-strategy"
                    },
                    {
                        id :5,
                        status:false,
                        url:"contractor-obligations"
                    },
                    {
                        id :6,
                        status:false,
                        url:"contractor-training-strategy"
                    },
                    {
                        id :7,
                        status:false,
                        url:"quiz"
                    },
                ]
            },
            {
                id :1,
                canStart:false,
                status: 'locked',
                grade:0,
                questions: [
                    {
                        id :0,
                        status:false,
                        url:"lrbt-video"
                    },
                    {
                        id :1,
                        status:false,
                        url:"Labourvsequipmentinconstruction"
                    },
                    {
                        id :2,
                        status:false,
                        url:"lrbtvscfw"
                    },
                    {
                        id :3,
                        status:false,
                        url:"LocalResource-based(LRB)WorkMethods"
                    },
                    {
                        id :4,
                        status:false,
                        url:"exercice-1"
                    },
                    {
                        id :5,
                        status:false,
                        url:"Socio-economicadvantagesofLRBT"
                    },
                    {
                        id :6,
                        status:false,
                        url:"LRB(perceived)Limitations"
                    },
                    {
                        id :7,
                        status:false,
                        url:"ChoiceoftechnologyLRBTvsequipment"
                    },
                    {
                        id :8,
                        status:false,
                        url:"exercice-2"
                    },
                    {
                        id :9,
                        status:false,
                        url:"LocalResource-based(LRB)WorkMethod"
                    },
                    {
                        id :10,
                        status:false,
                        url:"EmploymentIntensiveInfrastructureProgrammeinLebanon"
                    },
                    {
                        id :11,
                        status:false,
                        url:"WhentoapplyLRBTimportantconsiderationsatplanningstage"
                    },
                ]
            },
            {
                id :2,
                canStart:false,
                status: 'locked',
                grade:0,
                questions: [
                    {
                        id :0,
                        status:false,
                        url:"TypicalsiteorganizationforLRBworks"
                    },
                    {
                        id :1,
                        status:false,
                        url:"RecruitmentofLabour"
                    },
                    {
                        id :2,
                        status:false,
                        url:"LogicalsequencingofLRBactivities"
                    },
                    {
                        id :3,
                        status:false,
                        url:"ImportanceofawelldevelopedWorkPlan"
                    },
                    {
                        id :4,
                        status:false,
                        url:"exercice-1"
                    },
                    {
                        id :5,
                        status:false,
                        url:"WorkPlanConsiderations"
                    },
                    {
                        id :6,
                        status:false,
                        url:"WorkPlan"
                    },
                    {
                        id :7,
                        status:false,
                        url:"SampleofaBoQ"
                    },
                    {
                        id :8,
                        status:false,
                        url:"PreparingaWorkPlan"
                    },
                    {
                        id :9,
                        status:false,
                        url:"Overallplanningreportingandmonitoringsystem"
                    }
                ]
            },
            {
                id :3,
                canStart:false,
                status: 'locked',
                grade:0,
                questions: [
                    {
                        id :0,
                        status:false,
                        url:"LabourmanagementforLRBworks"
                    },
                    {
                        id :1,
                        status:false,
                        url:"IncentiveschemesforLRBworks"
                    },
                    {
                        id :2,
                        status:false,
                        url:"exercise-1"
                    },
                    {
                        id :3,
                        status:false,
                        url:"TaskratesandProductivityPart1"
                    },
                    {
                        id :4,
                        status:false,
                        url:"exercice-2"
                    },
                    {
                        id :5,
                        status:false,
                        url:"TaskratesandProductivityPart2"
                    },
                    {
                        id :6,
                        status:false,
                        url:"exercise-3"
                    },
                    {
                        id :7,
                        status:false,
                        url:"Exampleofsomelabourproductivitynorms"
                    },
                    {
                        id :8,
                        status:false,
                        url:"Examples:Applicationoftaskworkandpieceworksystem"
                    },
                    {
                        id :9,
                        status:false,
                        url:"IncentiveschemesforLRBworks1"
                    }
                ]
            },
            {
                id :4,
                canStart:false,
                status: 'locked',
                grade:0,
                questions: [
                    {
                        id :0,
                        status:false,
                        url:"UnderstandingofcostinganddetailedUnitRateAnalysis"
                    },
                    {
                        id :1,
                        status:false,
                        url:"SampleofaBoQ"
                    },
                    {
                        id :2,
                        status:false,
                        url:"Pricecomponents"
                    },
                    {
                        id :3,
                        status:false,
                        url:"UnitRateComposition"
                    },
                    {
                        id :4,
                        status:false,
                        url:"exercise-1"
                    },
                    {
                        id :5,
                        status:false,
                        url:"DirectCost"
                    },
                    {
                        id :6,
                        status:false,
                        url:"LabourCost"
                    },
                    {
                        id :7,
                        status:false,
                        url:"CostofEquipmentandhandtools"
                    },
                    {
                        id :8,
                        status:false,
                        url:"TypicalhandtoolsforLRBTworks"
                    },
                    
                    {
                        id :9,
                        status:false,
                        url:"exercise-2"
                    },
                    {
                        id :10,
                        status:false,
                        url:"MaterialCost"
                    },
                    {
                        id :11,
                        status:false,
                        url:"TotalDirectCost"
                    },
                    {
                        id :12,
                        status:false,
                        url:"exercise-3"
                    },
                    {
                        id :13,
                        status:false,
                        url:"IndirectCosts"
                    },
                    {
                        id :14,
                        status:false,
                        url:"exercise-4"
                    },
                    {
                        id :15,
                        status:false,
                        url:"UnitRate"
                    },
                    {
                        id :16,
                        status:false,
                        url:"quiz"
                    },
                ]
            },
            {
                id :5,
                canStart:false,
                status: 'locked',
                grade:0,
                questions: [
                    {
                        id:0,
                        title:"Environment and Social Safeguard Framework and Social Safeguard Officer",
                        questions: [
                    
                                {
                                    id: 0,
                                 mode:"reading", 
                                status:false,
                                url :"Overview",
                                title:"Overview"
                                },
                                {
                                    id: 1,
                                    status:false,
                                    mode:"reading", 
                                url:"Legal Context",
                                title:"LegalContext"
                                },
                                {
                                    id: 2,
                                    status:false,
                                    mode:"reading", 
                                url :"ESSFArticlesintheContractTermsConditions",
                                title:"ESSF Articles in the Contract Terms & Conditions"
                                }
                            
                        ]
                    },
                    {
                        id:1,
                        title:"Decent Work",
                        questions: [
                    
                            {
                                id: 0,
                                mode:"reading", 
                                status:false,
                            url :"WhatisDecentWork",
                            title:"What is Decent Work?"
                            },
                            {
                                id: 1,
                                mode:"quiz", 
                                status:false,
                            url :"QuizQuestion",
                            title:"Exercise 1"
                            },
                            {
                                id: 2,
                                status:false,
                                mode:"reading", 
                            url :"DecentWorkConditions",
                            title:"Decent Work Conditions"
                            }
                            
                        ]
                    },
                    {
                        id:2,
                        title:"Role of the Social Safeguard Officer",
                        questions: [
                            {
                                id: 0,
                                status:false,
                                mode:"video", 
                            url :"SSOVideo",
                            title:"SSO Video"
                            },
                            {
                                id: 1,
                                status:false,
                                mode:"reading", 
                            url :"RoleofILOsSocialSafeguardOfficer",
                            title:"Role of ILO’s Environmental Social Safeguard Officers"
                            }
                            
                        ]
                    },
                    {
                        id:3,
                        title:"Outreach Mechanism",
                        questions: [
                            {
                                id: 0,
                                status:false,
                                mode:"reading", 
                            url :"ApproachAndSteps",
                            title:"Approach & Steps"
                            },
                            {
                                id: 1,
                                status:false,
                                mode:"reading", 
                            url :"StandardAdvertisementtemplate",
                            title:"Standard Advertisement template"
                            },
                            {
                                id: 2,
                                status:false,
                                mode:"reading", 
                                url :"Referrals",
                                title:"Referrals"
                            }
                            
                        ]
                    },
                    {
                        id:4,
                        title:"Occupational Safety & Health",
                        questions: [
                            {
                                id: 0,
                                status:false,
                            url :"OSHVideo",
                            mode:"video", 
                            title:"OSH Video"
                            },
                            {
                                id: 1,
                                status:false,
                            url :"GeneralPrinciples",
                            mode:"reading", 
                            title:"General Principles"
                            },
                            {
                                id: 2,
                                status:false,
                            url :"PersonalProtectiveEquipment",
                            mode:"reading", 
                            title:"Personal Protective Equipment (PPE)"
                            },
                            {
                                id: 3,
                                status:false,
                            url :"RiskAssessment",
                            mode:"reading", 
                            title:"Risk Assessment"

                            },
                            {
                                id: 4,
                                status:false,
                            url :"DeterminingtheRisk",
                            mode:"reading", 
                            title:"Determining the Risk"
                            },
                            {
                                id: 5,
                                status:false,
                            url :"RiskManagementMatrix",
                            mode:"reading", 
                            title:"Risk Management Matrix"
                            },
                            {
                                id: 6,
                                status:false,
                            url :"ImplementationofOccupationalSafetyHealth",
                            mode:"reading", 
                            title:"Implementation of Occupational Safety & Health"
                            },
                            {
                                id: 7,
                                status:false,
                            url :"WorkersResponsibility",
                            mode:"reading", 
                            title:"Workers’ Responsibility"
                            },
                            {
                                id: 8,
                                status:false,
                            url :"ReportingAccidentsonSite",
                            mode:"reading", 
                            title:"Reporting Accidents on-Site"
                            }
                            
                        ]
                    },
                    {
                        id:5,
                        title:"Effective Inclusion",
                        questions: [
                            {
                                id: 0,
                                status:false,
                            url :"WomensParticipationVIDEO",
                            mode:"video", 
                            title:"Women’s Participation VIDEO"
                            },
                            {
                                id: 1,
                                status:false,
                                mode:"reading", 
                            url :"Whatcanbedone",
                            title:"What can be done?"
                            },
                            {
                                id: 2,
                                status:false,
                                mode:"reading", 
                            url :"PromotingInclusiveParticipation",
                            title:"Promoting Inclusive Participation"
                            }
                            
                        ]
                    },
                    {
                        id:6,
                        title:"Grievance Mechanism",
                        questions: [
                            {
                                id: 0,
                                status:false,
                                mode:"reading", 
                            url :"WhyaGrievanceMechanism",
                            title:"Why a Grievance Mechanism?"
                            
                            },
                            {
                                id: 1,
                            status:false,
                            mode:"reading", 
                            url :"GrievanceChannels",
                            title:"Grievance Channels"
                            },
                            {
                                id: 2,
                                status:false,
                                mode:"reading", 
                            url :"GrievanceProcedure",
                            title:"Grievance Procedure"
                            }
                            
                        ]
                    },
                    {
                        id:7,
                        title:"Environmental Safeguarding",
                        questions: [
                            {
                                id: 0,
                                status:false,
                                mode:"quiz", 
                            url :"Quiz",
                            title:"Exercise 2"
                            },
                            {
                                id: 1,
                                status:false,
                                mode:"reading", 
                            url :"EnvironmentalSafeguardsProvisions",
                            title:"Environmental Safeguards Provisions"
                            
                            },
                            {
                                id: 2,
                                status:false,
                                mode:"reading", 
                            url :"SocialandEnvironmentalSafeguards",
                            title:"Social and Environmental Safeguards"
                            },
                            {
                                id: 3,
                                status:false,
                                mode:"reading", 
                            url :"ScreeningandAnalysis",
                            title:"Screening and Analysis"
                            },
                            {
                                id: 4,
                                status:false,
                                mode:"reading", 
                            url :"ScreeningandAnalysisSocialFeasibility",
                            title:"Screening and Analysis-Social Feasibility"
                            },
                            {
                                id: 5,
                                status:false,
                                mode:"reading", 
                            url :"ScreeningandAnalysisEnvironmentalScreening",
                            title:"Screening and Analysis-Environmental Screening"
                            },
                            {
                                id: 6,
                                mode:"reading", 
                                status:false,
                            url :"Facilitationthroughtraining",
                            title:"Facilitation through training"
                            },
                            {
                                id: 7,
                                mode:"reading", 
                                status:false,
                            url :"Compliance",
                            title:"Compliance"
                            },
                            {
                                id: 8,
                            url:"quiz-1",
                            mode:"quiz", 
                            status:false,
                        title:"Quiz"
                            }
                            
                        ]
                    }
                ]
                },
            {
                id :6,
                canStart:false,
                status: 'locked',
                grade:0,
                questions: [
                    {
                        id :0,
                        status:false,
                        url:"ContractAdministration"
                    },
                    {
                        id :1,
                        status:false,
                        url:"KeyContractDocuments"
                    },
                    {
                        id :2,
                        status:false,
                        url:"ParticularConditions"
                    },
                    {
                        id :3,
                        status:false,
                        url:"BidClarificationandSubmission"
                    }
                    
                ]
            },
            {
                id :7,
                canStart:false,
                status: 'locked',
                grade:0,
                questions: [
                    {
                        id :0,
                        status:false,
                        url:"quiz"
                    }
                    
                ]
            }
        ]
    })
}
export const updateProfileInfo = async (occupation,experienceLevel,company,educationDegree,university,field,id ) => {
    const updateRef = ref(db,'users/');
    const updateRefChild = child(updateRef,id);
    update(updateRefChild, {
        Occupation: occupation,
        experienceLevel : experienceLevel,
        company: company,
        educationDegree : educationDegree,
        university: university,
        field: field,
        
    })
}
export const updateChapters = async (chapterId,questionId,userId) => {
    
    const updateRef = ref(db,`users/${userId}/chapters/${chapterId}/questions/${questionId}`);
    update(updateRef,{
        id : questionId,
        status : true
    })
}
export const updateChapterss = async (chapterId,questionId,userId,subQuestId) => {
    
    const updateRef = ref(db,`users/${userId}/chapters/${chapterId}/questions/${subQuestId}/questions/${questionId}`);
    update(updateRef,{
        id : questionId,
        status : true
    })
}

export const startChapters = (currentChapter,nextChapter, userId) => {
    const updateReff = ref(db, `/users/${userId}/chapters/${currentChapter}`);
    update(updateReff,{
        status:"completed",
    })
    
    const updateRef= ref(db,`/users/${userId}/chapters/${nextChapter}`);
    update(updateRef,{
       status :"start",
       canStart: true
    })

}
export const startChapterss = (currentChapter, userId) => {
    const updateReff = ref(db, `/users/${userId}/chapters/${currentChapter}`);
    update(updateReff,{
        status:"completed",
    })
    
    

}
export const updateGrade = (userId,grade,chapterIdd) => {
    const updateRef = ref(db, `/users/${userId}/chapters/${chapterIdd}`);
    update(updateRef,{
        grade:grade,
    })
}
export const certificatePass = (userId,) => {
    const updateRef = ref(db, `/users/${userId}/`);
    const date = new Date();
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const year = date.getFullYear();
    const month = months[date.getMonth()]
    const day = date.getDate();
    update(updateRef,{
        certificate:true,
        certificateDate:`${day} / ${month} / ${year}`,
        certificateDateEnd :`${day} / ${month} / ${year + 1}`
    })
}
export const endChapters = () => {
    const updateRef = ref(db, `/users/g2hbOvAMXGUFfYrmiOCoadBumrA3/chapters/0`);
    update(updateRef,{
        status:"completed",
    })
}
//Fetch user
export const getUsers = async (idAuth) => {
    
    const usersRef = ref(db, `users/${idAuth}`);
    const snapshot = await get(usersRef);
    const data = snapshot.val();
    return data;
 
  };
  //Fetch user
export const getAllUsers = async () => {
    
    const usersRef = ref(db, `users/`);
    const snapshot = await get(usersRef);
    const data = snapshot.val();
    const users = Object.values(data);
    console.log(data)
    return users;
 
  };

//Quiz

export const quizAnswer = async (quizTitle) => {
    const quizRef = ref(db, `/quizzes/${quizTitle}`);
    const snapshot = await get(quizRef);
    const data = snapshot.val();
    return data;
}

//Sign in user
export const signInAuthUserWithEmailAndPassword = async(email, password) => {
    if (!email || !password) return;

    return await signInWithEmailAndPassword(auth, email, password);
       
    
}

//Sign Out User
export const signOutUser = async () => await signOut(auth);

//Auth State Change
export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);