import { Link } from "react-router-dom";
import './breadcrumb.styles.scss';
import {ReactComponent as RightDirection} from '../../assets/breadcrumb-right.svg';
import {ReactComponent as LeftDirection} from '../../assets/breadcrumb-left.svg';
const BreadCrumb = ({prevLink,nextLink,chapterTitle,subTitle,chapterLink,subLink,chapterNumber}) => {
    return (
        <>
        <div className="bread-crumb">
        <div className="breadcrumb-links">
        <Link to={`../../`}>
                
                <p> Chapters <RightDirection style={{marginLeft:"10px"}}/></p>
               
            </Link>
            <Link to={`../../`}>
                
                <p>{chapterNumber}.   {chapterTitle}  <RightDirection style={{marginLeft:"10px"}}/></p>
               
            </Link>
            
                <p>{subTitle}</p>
           
        </div>
        <div className="breadcrumb-nav">
            {prevLink ?  <Link to={`../${prevLink}`}> <LeftDirection style={{marginRight:"10px"}}/>Previous</Link> : <div></div>}
       {nextLink ? <Link to={`../${nextLink}`}>Next <RightDirection style={{marginLeft:"10px"}}/></Link> : null}
        
        </div>
        </div>
        </>
    )
}

export default BreadCrumb;