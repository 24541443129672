import BlueShowText from "../blue-show-text/blue-show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import { useState } from "react";
const ApproachAndSteps = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,0,user.uid,3)
        getUser();
        navigate("../StandardAdvertisementtemplate")
    }
    const [box1,setBox1] = useState(false)
    const [box2,setBox2] = useState(false)
    const [box3,setBox3] = useState(false)
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Approach & Steps"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="ApproachAndSteps"
            prevLink="RoleofILOsSocialSafeguardOfficer"
            nextLink="StandardAdvertisementtemplate"
            chapterNumber='6'
            />
        <h1>Approach & Steps</h1>
        <div onClick={()=>setBox1(true)}> <BlueShowText
            text="Reach out to municipalities and key people"
            status={false}
/></div>
       <div onClick={()=>setBox2(true)}>
       <BlueShowText
            text="Inform & advocate the objectives and benefits of the project for the community"
            status={true}
/>
       </div>

<BlueShowText
            text="Communicate with the public at large through different channels prior to implementation"
            status={false}
/>
<BlueShowText
            text="Explain importance of recruiting men and women on EIIP Projects"
            status={true}
/>
<div >
<BlueShowText
            text="Respect Inclusive Job Application procedures (time, location, languages, etc.)"
            status={true}
/>
</div>

<div onClick={()=>setBox3(true)}>
<BlueShowText
            text="Reach out to as many NGOs and CBOS to increase labour outreach (men and women)"
            status={false}
/>
</div>

<BlueShowText
            text="Highlight the importance and benefit of engaging women and persons with disabilities"
            status={true}
/>
<BlueShowText
            text="Highlight main impacts of the project to the community"
            status={true}
/>

<button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default ApproachAndSteps