import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import MiniCircles from "../mini-circles/mini-circles.component";
const GrievanceProcedure = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,2,user.uid,6);
        getUser();
        navigate("../Quiz")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Grievance Procedure"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="GrievanceProcedure"
            prevLink="GrievanceChannels"
            nextLink="Quiz"
            chapterNumber='6'
            />
        <h1>Grievance Procedure</h1>
        <MiniCircles text="Worker/beneficiary faces an issue" color1="#AB3B77" color2="#E0B6CC" width="20%" height="20%" />
        <MiniCircles text="Worker/beneficiary reports the issues using a grievance channel" color1="#AB3B77" color2="#E0B6CC" width="30%" height="30%" />
        <MiniCircles text="Grievance is reported" color1="#AB3B77" color2="#E0B6CC" width="50%" height="50%" />
        <MiniCircles text="Grievance is dealt with confidentially and with relevant officials" color1="#AB3B77" color2="#E0B6CC" width="60%" height="60%" />
        <MiniCircles text="Necessary measures are taken to address the grievance reporting" color1="#AB3B77" color2="#E0B6CC" width="70%" height="70%" />
        <MiniCircles text="Procedure is logged into a confidential database. And follow-up with worker/beneficiary is conducted to protect against retaliation" color1="#AB3B77" color2="#E0B6CC" width="80%" height="80%" />
       
        
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}
export default GrievanceProcedure;