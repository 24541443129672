import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const LrbtVsCfw = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(1,2,user.uid);
        getUser();
        navigate("../LocalResource-based(LRB)WorkMethods")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Local Resource Based Technology" 
            subTitle="LRBT vs Cash-for-Work"
            chapterLink="LocalResourceBasedTechnology"
            subLink="lrbtvscfw"
            prevLink="Labourvsequipmentinconstruction"
            nextLink="LocalResource-based(LRB)WorkMethods"
            chapterNumber="2"
            />
        <h1>
            LRBT vs Cash-for-Work
        </h1>
        <p>At the centre of the EIIP is local resource based technology, which favours and optimises the use of local resources in delivering and maintaining infrastructure. Local labour and materials are used to the greatest possible extent, but without adversely affecting the costs or duration of the specified works. Appropriate (light) equipment is used for support activities for the sake of time or quality. Labour on site is effectively organised, supervised and motivated, including fair and transparent recruitment and payment procedures. LRBT method can, if well organised be a cost-effective, viable and sustainable delivery means for infrastructure. LRBT aims to optimize the employment content, meaning the labour content is usually lower than in the Cash-for-Work approach, but significantly higher than for conventional construction. The key differences between LRBT vs CfW are:</p>
        <ShowText 
            title="Employment optimization" 
            text="Optimise employment rather than maximise to avoid non-productive labour"
            color="#AB3B77"
            textColor="white"
             />
            <ShowText 
            title="Quality of work" 
            text="Focus on Quality of construction works and importance of assets, we want to have a more sustainable impact on public infrastructure
            - Quality of work (and duration), to avoid disturbing the neighbourhoods for example during road maintenance projects where there might be some reroutes during the works which might result in temporary congestion and traffic"
            color="#FB636F"
            textColor="white"
            />
            <ShowText 
            title="Productivity and task rates" 
            text="Productivity and task rates and output based pay become more important in EIIP"
            color="#3264C8"
            textColor="white"
             />
             <ShowText 
            title="Effective planning and preparation" 
            text="More technical inputs are required in preparation and supervision (engineering)"
            color="#69E4E4"
            textColor="#230050"
             />
             <ShowText 
            title="Skills development" 
            text="Skills development and employability objectives "
            color="#FAB428"
            textColor="#230050"
             />
             <ShowText 
            title="Private sector engagement" 
            text="Private sector engagement through contracting the projects."
            color="#70B450"
            textColor="white"
             />
            <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}
export default LrbtVsCfw;