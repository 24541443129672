import { useState } from "react";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const Quiz = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,0,user.uid,7);
        getUser();
        navigate("../EnvironmentalSafeguardsProvisions")
    }
    const [answer1,setAnswer1] = useState(null);
    const [answer2, setAnswer2] = useState(null)
    const [answer3, setAnswer3] = useState(null)
    const [answer4, setAnswer4] = useState(null)
    const [answer5, setAnswer5] = useState(null)
    const [finalResult,setFinalResult] = useState(null);
    const [showfinalResult,setshowFinalResult] = useState(null);
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(answer1 && answer3 && answer2 && answer4){
            setFinalResult(true);
        }else{
            setFinalResult(false);
        }
       setshowFinalResult(true)
      
    }

    return (
        <>
         <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Quiz"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="Quiz"
            prevLink="GrievanceProcedure"
            nextLink="EnvironmentalSafeguardsProvisions"
            chapterNumber='6'
            />
        <h1>Quiz</h1>
        <h2>Which of the below items are examples of negative environmental impact?(Select all that apply)</h2>
        <form className="exercice-form" onSubmit={handleSubmit}>
        <input onClick={() => setAnswer1(true)}   type="checkbox" name="Degraded wetland that damages the ecosystem and habitat" id="Degraded wetland that damages the ecosystem and habitat" value="Degraded wetland that damages the ecosystem and habitat"  /><label for="Degraded wetland that damages the ecosystem and habitat">Degraded wetland that damages the ecosystem and habitat</label><br />
        <input onClick={() => setAnswer2(true)}   type="checkbox" name="Removal of a cultural heritage asset due to overlapping with project location" id="Removal of a cultural heritage asset due to overlapping with project location" value="Removal of a cultural heritage asset due to overlapping with project location"  /><label for="Removal of a cultural heritage asset due to overlapping with project location">Removal of a cultural heritage asset due to overlapping with project location</label><br />
        <input onClick={() => setAnswer3(true)}   type="checkbox" name="Construction and demolition waste generated through project disposed randomly in undesignated areas" id="Construction and demolition waste generated through project disposed randomly in undesignated areas" value="Construction and demolition waste generated through project disposed randomly in undesignated areas"  /><label for="Construction and demolition waste generated through project disposed randomly in undesignated areas">Construction and demolition waste generated through project disposed randomly in undesignated areas</label><br />


        <input onClick={() => setAnswer4(true)}   type="checkbox" name="Disposal of packaging from used chemicals in the street garbage bin" id="Disposal of packaging from used chemicals in the street garbage bin" value="Disposal of packaging from used chemicals in the street garbage bin"  /><label for="Disposal of packaging from used chemicals in the street garbage bin">Disposal of packaging from used chemicals in the street garbage bin</label><br />
        
        <button type="submit">Submit</button>
        </form>
        {
        showfinalResult ?  finalResult ? 
            <div>
            <h2>Well Done!</h2>
            <button onClick={handleUpdates}>Mark As Completed</button> 
        </div> :  <div>
            <h2>Well No!</h2>
            <h3>All of the items are examples of negative environmental impact </h3>
            <button onClick={handleUpdates}>Mark As Completed</button> 
        </div> : null
        }
        </>
    )
}


export default Quiz;