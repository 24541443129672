import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const Overallplanningreportingandmonitoringsystem = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(2,9,user.uid);
        getUser();
        navigate("../quiz")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Labour Management and Work Plan" 
            subTitle="Overall planning, reporting and monitoring system"
            chapterLink="LocalResourceBasedTechnology"
            subLink="Overallplanningreportingandmonitoringsystem"
            prevLink="PreparingaWorkPlan"
            nextLink="quiz"
            chapterNumber='3'
            />
        <h1>Overall planning, reporting and monitoring system</h1>
        <img src={require('../../assets/overall-planing-2.png')} className="boq-ch3-img" alt=""  />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}


export default Overallplanningreportingandmonitoringsystem;