import RedPolyList from "../red-poly-list/red-poly-list.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const LabourCost = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,6,user.uid);
        getUser();
        navigate("../CostofEquipmentandhandtools")
    }
    const list1 = [
        {
            id:0,
            text:"1 Unskilled labour can do 1.0 m<sup>3 </sup>/day OR  1 m<sup>3 </sup>concrete requires: 1/1= 1.0 Wd"
        },
        {
            id:1,
            text:"1 Semi-skilled labour can do 5 m<sup>3 </sup>/day OR  1 m<sup>3 </sup>concrete requires: 1/5=0.2 Wd"
        },
        {
            id:2,
            text:"1 Semi-skilled labour do formwork-2 m<sup>3 </sup>/day OR  1 m<sup>3 </sup>concrete requires: 1/2=0.5 Wd"
        },

    ]
    const list2 = [
        {
            id:0,
            text:"  Unskilled= 1.0 wd x US$ 10 = US$ 10"
        },
        {
            id:1,
            text:" Semi Skilled = 0.7 wd x US$ 14= US$ 9.4"
        }

    ]
    
    return (
        <>
         <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Labour Cost"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="LabourCost"
            prevLink="DirectCost"
            nextLink="CostofEquipmentandhandtools"
            chapterNumber='5'
            />
        <h1>Labour Cost</h1>
        <p>The task rates are the core of understanding the pricing mechanism, as they directly affect the potential amount of work to be achieved in one work day, which will allow the Labour WD’s cost to be factored in accordingly into the unit rate</p>
        <h2>Example</h2>
        <h4>Producing 1 m<sup>3 </sup>of concrete requires:</h4>
        <RedPolyList list={list1}/>
        <h2>Labour cost to Produce 1 m<sup>3 </sup>of concrete is:</h2>
        <RedPolyList list={list2}/>
        <h3>Total labour cost = US$ 19.8</h3>
        <p>The unit rate analysis table should be the basis for pricing the offers, in order to have a comprehensive breakdown based on a full understanding of the activities and materials to be involved.</p>
        <img src={require('../../assets/labour-cost-one.jpg')} className="boq-ch3-img" alt=""  />
        <button ><a  href={require("../../assets/unit-rate-analysis.xlsx")} download>Download File</a></button>
        <h2>Labour cost-1m3 concrete</h2>
        <img src={require('../../assets/labour-cost-two.jpg')} className="boq-ch3-img" alt=""  />
       
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default LabourCost;