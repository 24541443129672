import {ReactComponent as RedPoly} from '../../assets/polygon.svg';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const UnderstandingofcostinganddetailedUnitRateAnalysis = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,0,user.uid);
        getUser();
        navigate("../SampleofaBoQ")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Understanding the costing and detailed Unit Rate Analysis"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="UnderstandingofcostinganddetailedUnitRateAnalysis"
            prevLink="../TaskRatesandProductivityNorms/quiz"
            nextLink="SampleofaBoQ"
            chapterNumber='5'
            />
        <h1>Understanding the costing and detailed Unit Rate Analysis</h1>
        <p>The understanding of costing and detailed Unit Rate Analysis is essential to ensure a fair competition between bidders, who would therefore price on the same basis</p>
        <h3><RedPoly className='task-rates-poly'/>The purpose of this session is to enable bidders to:</h3>
        <ul>
            <li><p>Analyse Unit rates for LRB work items</p></li>
            <li><p>Prices for LRB works (Bill of Quantities with prices)</p></li>
            
        </ul>
        <h3><RedPoly className='task-rates-poly'/>Unit rates are established for:</h3>
        <ul>
            <li><p>Client’s engineer estimates bid price when preparing contracts</p></li>
            <li><p>Bidders to Price work items when bidding for a LRB contract</p></li>
            <li><p>Detailed Unit Rate Analysis is very important when preparing and submitting a competitive bid</p></li>
        </ul>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default UnderstandingofcostinganddetailedUnitRateAnalysis;