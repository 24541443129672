import RedPolyList from "../red-poly-list/red-poly-list.component"
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const RoleofILOsSocialSafeguardOfficer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,1,user.uid,2);
        getUser();
        navigate("../ApproachAndSteps")
    }
    const list = [
        {
            id:0,
            text:"Is present daily on site"
        },
        {
            id:1,
            text:"Monitors the proper implementation of the Environmental & Social Safeguard Framework"
        },
        {
            id:2,
            text:"Monitors the proper implementation of all OSH measures and PPES"
        },
        {
            id:3,
            text:"Fills regular monitoring checklists"
        },
        {
            id:4,
            text:"Continuously assesses contractor’s site management"
        },
        {
            id:5,
            text:"Mobilizes the Complaint Mechanism"
        },
        {
            id:6,
            text:"Regular Reports to the ESSF compliance team"
        },
        {
            id:7,
            text:"Flags to contractor and/or central teams any items that require prompt action"
        },
        {
            id:8,
            text:"Continuously coordinates with Municipality and/or project owner"
        },
        {
            id:9,
            text:"Mobilizes the Outreach Mechanism"
        },
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Role of ILO’s Social Safeguard Officer"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="RoleofILOsSocialSafeguardOfficer"
            prevLink="SSOVideo"
            nextLink="ApproachAndSteps"
            chapterNumber='6'
            />
        <h1>Role of ILO’s Environmental and Social Safeguard Officers</h1>
        <RedPolyList list={list}/>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default RoleofILOsSocialSafeguardOfficer;