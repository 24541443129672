import RedPolyList from "../red-poly-list/red-poly-list.component";
import './logical-sequencing.styles.scss';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const LogicalSequencing = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(2,2,user.uid);
        getUser();
        navigate("../ImportanceofawelldevelopedWorkPlan")
    }
    const details = [
        {
            id:0,
            text:'LRB site operations must be split into single and simple activities.',
        },
        {
            id:1,
            text:'Each activity is assigned to a separate worker group with its own group Leader.',
        },
        {
            id:2,
            text:'Worker groups must be balanced in terms of numbers of labourers to ensure that each activity is carried out without interfering with other activities.',
        },
        {
            id:3,
            text:'Supervisor and group leader must set out and instruct daily tasks (dimension of works, quantity of work to be done, etc.) and at the end of the day approve completed tasks including quality of works',
        }
    ]
    const list = [
        {id:0,
        text:"Logical sequence of construction / maintenance activities"},
        {id:1,
            text:"Volume of work for each activity"},
            {id:2,
                text:"Applicable production rates (task rates for labourers, production norms for equipment)"},
                {id:3,
                    text:"Time available for the operation"},
                    {id:4,
                        text:"Required and available resources (labour, tools, material and equipment)"},
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Labour Management and Work Plan" 
            subTitle="Logical sequencing of LRB activities"
            chapterLink="LocalResourceBasedTechnology"
            subLink="LogicalsequencingofLRBactivities"
            prevLink="RecruitmentofLabour"
            nextLink="ImportanceofawelldevelopedWorkPlan"
            chapterNumber='3'
            />
        <RedPolyList title="Logical sequencing of LRB activities" list={details}/>
        <h2>The following planning parameters to be considered:</h2>
        {list.map((item) => {return (
            <div className="sequencing-box" key={item.id}>
                <h4>{item.text}</h4>
            </div>
        )})}
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default LogicalSequencing;