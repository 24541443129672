import RedPolyList from "../red-poly-list/red-poly-list.component";
import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const Referrals = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,2,user.uid,3);
        getUser();
        navigate("../OSHVideo")
    }
    const list = [ 
        {
            id:0,
            text:"Many partners and organizations are working in similar fields of work and training programmes"
        },
        {
            id:1,
            text:"To ensure sustainability a referral mechanism will be put in place which will create a win-win situation"
        },

    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Referrals"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="Referrals"
            prevLink="StandardAdvertisementtemplate"
            nextLink="OSHVideo"
            chapterNumber='6'
            />
        <h1>Referrals</h1>
        <RedPolyList list={list}/>
        <ShowText
            title="If contractor recruited referred workers she will have a group of workers who have already been either trained or involved in similar projects
            "
            color="#AB3B77"
        />
        <ShowText
            title="If contractor refers workers to other agencies, the worker will have a good job opportunity due to the skills acquired from the EIIP            "
            color="#AB3B77"
        />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default Referrals;