import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { useRef } from "react";
import {ReactComponent as RightIcon} from '../../assets/right-slider.svg';
import {ReactComponent as LeftIcon} from '../../assets/left-slider.svg';
import 'swiper/css'; 
import 'swiper/css/navigation';
import './examples-application-task.styles.scss'
const ExamplesApplicationoftaskworkandpieceworksystem = () => {
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(3,8,user.uid);
        getUser();
        navigate("../IncentiveschemesforLRBworks1")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Task Rates and Productivity Norms" 
            subTitle="Application of task ..."
            chapterLink="TaskRatesandProductivityNorms"
            subLink="Examples:Applicationoftaskworkandpieceworksystem"
            prevLink="Exampleofsomelabourproductivitynorms"
            nextLink="IncentiveschemesforLRBworks1"
            chapterNumber='4'
            />
        <h1>Examples: Application of task work and piece work system</h1>
        <p>Some examples of group task based activities distributed by teams and group leaders reflecting the installation of kerb stones, excavation, and material spreading – all perfect examples for task based group activities</p>
        <div className="examples-slider">
        <Swiper
          modules={[Navigation]}
        
          slidesPerView={1}
          
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
         onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
         }}
        >
            <SwiperSlide>
            <img src={require('../../assets/slide-1.jpg')} className="boq-ch3-img"  alt=""  />
            </SwiperSlide>
            <SwiperSlide>
            <img src={require('../../assets/slide-2.jpg')} className="boq-ch3-img"  alt=""  />
            </SwiperSlide>
            <SwiperSlide>
            <img src={require('../../assets/slide-3.jpg')} className="boq-ch3-img"  alt=""  />
            </SwiperSlide>
            <div className="left-arr" ref={navigationPrevRef} >
                <LeftIcon/>
                </div>
      <div className="right-arr" ref={navigationNextRef} ><RightIcon/></div>
        </Swiper>
        </div>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default ExamplesApplicationoftaskworkandpieceworksystem