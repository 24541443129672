import './eiip-strategy.styles.scss';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { updateChapters } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import ShowText from '../show-text/show-text.component';
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const EiipStrategy = () => {
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const user = useSelector(selectCurrentUser)
        const getUser = async () => {
            const userr = await getUsers(user.uid);
            dispatch(setUserInfo(userr));
          }
            const handleUpdates = () => {
            updateChapters(0,4,user.uid);
            getUser();
            navigate("../contractor-obligations")
        }
     const MyPoly = ({fill}) => {
        return (
            <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 0L45.5167 39L0.483339 39L23 0Z" fill={fill}/>
</svg>

 )
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Intro to EIIP & LRBT" 
            subTitle="EIIP Strategy"
            chapterLink="IntrotoEIIP&LRBT"
            subLink="eiip-strategy"
            prevLink="eiip-in-lebanon"
            nextLink="contractor-obligations"
            chapterNumber="1"
            />
        
        <div className='strategy-eiip-wrapper'>
        <h1>EIIP Strategy</h1>
        <p>The aim of Employment Intensive methodology is providing short-term employment opportunities, While providing public infrastructure and services that support longer-term social, environmental and economic development.
</p>
<p>Technically, a lot of infrastructure projects that are being implemented today can switch from the traditional way of works to adopt this method that promotes employment and sustainability.
</p>
<p>The core strategies of EIIP Lebanon will be discussed in-depth during this training and they include: 
</p>
<ShowText title="Local Resource Based Technology" 
        color="#AB3B77"
        textColor = "white"
        />
<p>Local Resource Based Technology (LRBT), i.e. work methods and technologies where the use of local resources, is favoured and optimised, without compromising the quality. </p>
<ShowText title="Decent work principles" 
        color="#FB636F"
        textColor = "white"
        />
        <p>Decent Work Principles, i.e. labour contracts, development, working hours and conditions, health and safety.</p>
<ShowText title="Private Sector Engagement" 
        color="#3264C8"
        textColor = "white"
        />
        <p>Complemented by training for contractors and capacity building for public institutions, with gender and environmental concerns as cross cutting themes.</p>
        <ShowText title="Environmental & Social Safeguard Framework" 
        color="#69E4E4"
        textColor = "#230050"
        />
        <p>Framework that regulates the work of the EIIP. It is designed to guide implementation of inclusive and rights-based practices in infrastructure & mitigate negative social & environmental impacts. ESS is developed for the guidance and use by Contractors and Contracts Supervisors, and to be used by designated Social & Environmental Safeguards Officers (SSOs) on employment intensive projects. </p>
        <ShowText title="Capacity Building and Development" 
        color="#FAB428"
        textColor = "#230050"
        />
        <ShowText title="Women’s and PWD’s Effective Participation" 
        color="#70B450"
        textColor = "white"
        />
        <button onClick={handleUpdates} >Mark As Completed</button>
        </div>
        </>
    )
}

export default EiipStrategy;