import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const TypicalhandtoolsforLRBTworks = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,8,user.uid);
        getUser();
        navigate("../exercise-2")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Typical hand tools for LRBT works"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="TypicalhandtoolsforLRBTworks"
            prevLink="CostofEquipmentandhandtools"
            nextLink="exercise-2"
            chapterNumber='5'
            />
        <h1>Typical hand tools for LRBT works</h1>
        <img src={require('../../assets/typical-hand-tools-1.jpg')} className="boq-ch3-img" alt=""  />
        <h2>Cost of Equipments and hand tools</h2>
        <p>A breakdown of the required tools and equipment is to be listed on this same table, to add up to the direct cost</p>
        <img src={require('../../assets/typical-equipment-two.jpg')} className="boq-ch3-img" alt=""  />
        
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}


export default TypicalhandtoolsforLRBTworks