import FormInput from '../form-input/form-input.component';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Button from '../button/button.component';
import './profile-info-form.styles.scss'
import { updatePersonalInfo } from '../../utils/firebase.utils';
import { selectCurrentUser } from '../../store/user/user.selector';
import {useSelector} from 'react-redux';
import { generateVerificationEmail } from '../../utils/firebase.utils';
const defaultFormFields = {
    Occupation: "",
    experienceLevel : "",
    company: "",
    educationDegree : "",
    university: "",
    field: ""
}
const PersonalInfo = () => {
    const navigate = useNavigate();
    const [formField, setFormFields] = useState(defaultFormFields);
    const {Occupation , experienceLevel , company ,educationDegree , university , field} = formField;
    const user = useSelector(selectCurrentUser);
     const handleChange = (event) => {
    const {name , value } = event.target;
    setFormFields({...formField,[name]:value})
 }
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        const id = user.uid;
        
        try {
            await updatePersonalInfo(Occupation , experienceLevel , company ,educationDegree , university , field,id);
            await generateVerificationEmail(user);
            navigate('../../verify-email');
        
        }catch{
            
        }
        
    }
    return (
        <>
        <div className="sign-in-wrapper">
            <div>
                <h1>Personal Info</h1>
                
                <form onSubmit={handleSubmit}>
                    <div className='two-inputs-holder'>
                        <span><FormInput label='Occupation' placeholder="Ex: Product Manager" type='text' onChange={handleChange} name='Occupation' value={Occupation} required/></span>
                       <span><FormInput label='Experience Level' placeholder="Ex: Lead / Manager" type='text' onChange={handleChange} name='experienceLevel' value={experienceLevel} required/></span>
                    </div>
                     <div className='two-inputs-holder'>
                        <span><FormInput required label='Company' placeholder="Company name" type='text' onChange={handleChange} name='company' value={company}/></span>
                        <span><FormInput required label='Education Degree' placeholder="Ex: Bachelor" type='text' onChange={handleChange} name='educationDegree' value={educationDegree}/></span>
                    </div>
                    <div className='two-inputs-holder'>
                        <span><FormInput required label='University' placeholder="Ex: NDU" type='text' onChange={handleChange} name='university' value={university}/></span>
                        <span><FormInput required label='Field or major' placeholder="Ex: Engineering" type='text' onChange={handleChange} name='field' value={field}/></span>
                    </div>
                   
                    
                   
                   
                    <Button children='Continue' style={{width:'100%'}}/>
                    
                   
                </form>

            </div>
         
        </div>
       
        </>
    )
}

export default PersonalInfo;