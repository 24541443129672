import { useState } from "react"
import {ReactComponent as ClickIcon} from '../../assets/finger-touch.svg';
import './blue-show-text.styles.scss';
const BlueShowText = ({text,status}) => {
    const [showText, setShowText] = useState(status);
    return (
        <>
        <div className="blue-text-box" onClick={() => setShowText(true)} style={{backgroundColor : showText ? "#EBF5FD" : "#3264C8" , boxShadow: showText ? "" : "2px 4px 5px rgba(0, 0, 0, 0.25)" }}>
            {showText ? <h4>{text}</h4> : null}
            {!showText ? <ClickIcon/> : null}
        </div>
        </>
    )
}

export default BlueShowText;