
import { useNavigate} from 'react-router-dom';

import { useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/user/user.selector';
import { generateVerificationEmail } from '../../utils/firebase.utils';

const SendVerifyEmail = () => {
    const [errorMessage,setErrorMessage] = useState(null);
    const [showText,setShowText] = useState(false)
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    useEffect(() => {
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == true){
                navigate("../home")
            }
        }
       
    },[user])
   const handleVerify= async () => {
    try {
        await generateVerificationEmail(user);
    } catch (err){
        setErrorMessage(err)
    }
   
   setShowText(true)
   }
    return (
        <>
        <div className="sign-in-wrapper">
        <div className='verify-box'>
            <img src={require("../../assets/gmail-icon.png")} alt="Mail-icon" srcset="" />
            <p>Verify Your Email</p>
            <div className='blue-line'></div>
            <p>Welcome to the LRBT online training.
Please verify your account.</p>
<button onClick={handleVerify}>Send Verification</button>
{
                showText ? <p>Check Your Email!</p> : null
            }
           </div>
          
           
        </div>
       
        </>
    )
}

export default SendVerifyEmail;