
import { useNavigate} from 'react-router-dom';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/user/user.selector';
import { setCurrentUser } from '../../store/user/user.action';
import { useDispatch } from 'react-redux';
const VerifyEmail = () => {
  
    
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
  
    useEffect(() => {
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == true){
                navigate("../home")
            }
        }
       
    },[user])

    
  
   
   
    return (
        <>
        <div className="sign-in-wrapper">
            
           <div className='verify-box'>
            <img src={require("../../assets/gmail-icon.png")} alt="Mail-icon" srcset="" />
            <p>Verify Your Email</p>
            <div className='blue-line'></div>
            <p>Thanks for signing up for LRBT online training.
Please check your email to verify your account.</p>

           </div>
         
        </div>
       
        </>
    )
}

export default VerifyEmail;