import TrueAndFalse from "../trueorfalse/true-or-false.component";
import FalseAndTrue from "../trueorfalse/false-or-true.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import { useState } from "react";
const ExerciceOne = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,4,user.uid);
        getUser();
        navigate("../DirectCost")
    }
    const [exerc1,setExerc1]= useState(false)
    const [exerc2,setExerc2]= useState(false)
    return (
        <>
        <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Exercise"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="exercise-1"
            prevLink="UnitRateComposition"
            nextLink="DirectCost"
            chapterNumber='5'
            />
        <h1>Exercise</h1>
        <div onClick={()=>setExerc1(true)}>
        <TrueAndFalse 
            question='Indirect costs include project supervision costs'
            answer="Indirect costs include project supervision costs"
        />
        </div>
        <div onClick={() => setExerc2(true)}>
        <FalseAndTrue 
            question='Hand tools are considered indirect project costs'
            answer="Hand tools are considered direct project costs"
        />
        </div>
       {
        exerc1 && exerc2 ?  <button onClick={handleUpdates}>Mark As Completed</button> : null
       }
       
        </>
    )
}

export default ExerciceOne;