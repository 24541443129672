import {ReactComponent as ClickIcon} from '../../assets/click-icon.svg';
import './socio-economic.styles.scss';
import { useState } from 'react';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const SocioEconomic = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(1,5,user.uid);
        getUser();
        navigate("../LRB(perceived)Limitations")
    }
    const [square1,setSquare1] = useState(true);
    const [square2,setSquare2] = useState(false);
    const [square3,setSquare3] = useState(true);
    const [square4,setSquare4] = useState(false);
    const [square5,setSquare5] = useState(true);
    const [square6,setSquare6] = useState(false);
    const [square7,setSquare7] = useState(true);
    const [score,setScore] = useState(0)
    return (
        <>
        <BreadCrumb 
            chapterTitle="Local Resource Based Technology" 
            subTitle="Socio-economic advantages of LRBT"
            chapterLink="Socio-economic advantages of LRBT"
            subLink="Socio-economicadvantagesofLRBT"
            prevLink="exercice-1"
            nextLink="LRB(perceived)Limitations"
            chapterNumber="2"
            />
        <h1>Socio-economic advantages of LRBT</h1>
        <p>The LRBT has a multifaceted beneficial scope – through provision of short-term employment to local communities, and the purchase of local materials, and rentals of available equipment, etc. thus turning the economic cycle in the project’s locality. <br /> <br /> Being the ILO’s main area of concern, Decent Work is ensured to be implemented by offering productive and just job opportunities for all the vulnerable community members and allowing them chances to enhance their employability through On-the-Job Training mechanisms.
         <br />  <br /> The LRBT projects are purposed to provide short-term and long-term public benefits to the community, so the workers would be technically building their own assets while encouraging a sustainable approach through participation and engagement.
</p>
        <div className="socio-box-holder">
            <div className="socio-square" style={{backgroundColor: square1 ? "#9EF2F2" : "#05BDBD"}} onClick={()=>setSquare1(true)}>
                <div className="socio-number" style={{backgroundColor: !square1 ? "white" : "#05BDBD" ,color : !square1 ? "#05BDBD" : "white"}} >
                    1
                </div>
                {
                 square1 ? <h3>Employment opportunities</h3> : null
                }
                {
                    !square1 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="socio-square" style={{backgroundColor: square2 ? "#9EF2F2" : "#05BDBD"}} onClick={()=>{setSquare2(true);setScore(score => score + 1)}}>
                <div className="socio-number" style={{backgroundColor: !square2 ? "white" : "#05BDBD" ,color : !square2 ? "#05BDBD" : "white"}} >
                    2
                </div>
                {
                 square2 ? <h3>Local economic development
                 </h3> : null
                }
                {
                    !square2 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="socio-square" style={{backgroundColor: square3 ? "#9EF2F2" : "#05BDBD"}} onClick={()=>setSquare3(true)}>
                <div className="socio-number" style={{backgroundColor: !square3 ? "white" : "#05BDBD" ,color : !square3 ? "#05BDBD" : "white"}} >
                    3
                </div>
                {
                 square3 ? <h3>Money injected into local communities</h3> : null
                }
                {
                    !square3 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="socio-square extra" style={{ backgroundColor: square4 ? "#9EF2F2" : "#05BDBD"}} onClick={()=>{setSquare4(true);setScore(score => score + 1)}}>
                <div className="socio-number" style={{backgroundColor: !square4 ? "white" : "#05BDBD" ,color : !square4 ? "#05BDBD" : "white"}} >
                    4
                </div>
                {
                 square4 ? <h3>Skills development</h3> : null
                }
                {
                    !square4 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="socio-square" style={{backgroundColor: square5 ? "#9EF2F2" : "#05BDBD"}}  onClick={()=>setSquare5(true)}>
                <div className="socio-number" style={{backgroundColor: !square5 ? "white" : "#05BDBD" ,color : !square5 ? "#05BDBD" : "white"}} >
                    5
                </div>
                {
                 square5 ? <h3>Focus on
                 Decent Work</h3> : null
                }
                {
                    !square5 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="socio-square" style={{backgroundColor: square6 ? "#9EF2F2" : "#05BDBD"}}  onClick={()=>{setSquare6(true);setScore(score => score +1)}}>
                <div className="socio-number" style={{backgroundColor: !square6 ? "white" : "#05BDBD" ,color : !square6 ? "#05BDBD" : "white"}} >
                    6
                </div>
                {
                 square6 ? <h3>Little foreign exchange
                 is needed</h3> : null
                }
                {
                    !square6 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
            <div className="socio-square extra" style={{backgroundColor: square7 ? "#9EF2F2" : "#05BDBD"}}   onClick={()=>setSquare7(true)}>
                <div className="socio-number" style={{backgroundColor: !square7 ? "white" : "#05BDBD" ,color : !square7 ? "#05BDBD" : "white"}} >
                    7
                </div>
                {
                 square7 ? <h3>Local ownership
                 </h3> : null
                }
                {
                    !square7 ? <ClickIcon className='click-icon' /> : null
                }
                
            </div>
        </div>
       
        <button disabled={score > 2 ? false : true}  onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default SocioEconomic;