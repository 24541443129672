import CircleShow from "../circle-show/circle-show.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const LegalContext = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
            updateChapterss(5,1,user.uid,0);
        getUser();
        navigate("../ESSFArticlesintheContractTermsConditions")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Legal Context"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="LegalContext"
            prevLink="Overview"
            nextLink="ESSFArticlesintheContractTermsConditions"
            chapterNumber='6'
            />
            <h1>Legal Context</h1>
            <div className="legal-cont">
            <h2>Labour</h2>
            <CircleShow title="Environmental Protection Law"
                        color="#FB636F"
                        circleId="pw"
                        mainCircleId="powe"
                        boxId="po"
                        text="Lebanese Labour Law of 1946: Applicable to all workers except domestic and agricultural workers Ministry of Labour sets guidelines on working conditions and conducts labour inspections"
                       
                        />
      
            </div>
            <p>Lebanese Labour Law of 1946
: Applicable to all workers except domestic and agricultural workers
Ministry of Labour sets guidelines on working conditions and conducts labour inspections
</p>

<div className="legal-cont">
            <h2>Environmental Protection Law</h2>
            
            <CircleShow title="Environmental Protection Law"
                        color="#1E2DBE"
                        circleId="lawSecond"
                        mainCircleId="lawMain"
                        text="The legal basis for the Lebanese EIA system is established in the Environment Protection Law No. 444/2002 and the EIA decree No. 8633/2012 and its annexes. The EIA decree and its annexes include all the requirements for screening, preparation of the environmental assessment and the supervision of the environmental assessment process. As per Lebanon’s Environmental Protection Law possible environmental risks should be assessed and mitigation measures and opportunities for sustainable solutions developed."
                        boxId="law"
                        />
                        
            </div>
            <p>Environmental Protection Law No.444/20022 EIA decree No. 8633/2012 and annexes Annex 1 list for which an EIA study is required for construction Annex 2 list for which an Initial Environmental Examination (IEE) is required for construction Annex 3 list of projects located in sensitive areas</p>

           
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default LegalContext;