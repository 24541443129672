import './eiip-in-lebanon.styles.scss';
import {ReactComponent as RedPoly} from '../../assets/polygon.svg';
import {ReactComponent as BlackLine} from '../../assets/line-black.svg';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { updateChapters } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import RedPolyList from '../red-poly-list/red-poly-list.component';
import TrueAndFalse from '../trueorfalse/true-or-false.component';
import TrueAndTrue from '../trueorfalse/true-or-true.component';
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const EiipInLebanon = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
      }
        const handleUpdates = () => {
        updateChapters(0,3,user.uid);
        getUser();
        navigate("../eiip-strategy")
    }
    const [showInfo, setShowInfo] = useState('True !');
    const [showAnswer ,setShowAnswer] = useState(false)
    const list = [ 
        {
            id:0,
            text:"Quality infrastructure prioritised by municipalities"
        },
        {
            id:1,
            text:"Employment generation"
        },
        {
            id:2,
            text:"Targeting the most vulnerable in the communities"
        },
        {
            id:3,
            text:"Inclusion of women and Persons with Disabilities"
        },
        {
            id:4,
            text:"Opportunities for both Lebanese community members and Syrian refugees (ideally ratio unskilled Syrians/Lebanese workers 50:50)"
        },
    ]
    return (
        <>
         <BreadCrumb 
            chapterTitle="Intro to EIIP & LRBT" 
            subTitle="EIIP In Lebanon"
            chapterLink="IntrotoEIIP&LRBT"
            subLink="eiip-in-lebanon"
            prevLink="the-employment-intensive-infrastructure-programme"
            nextLink="eiip-strategy"
            chapterNumber="1"
            />
       
        <div className='eiip-in-lebanon-wrapper'>
            <h1>EIIP In Lebanon</h1>
            <div className='red-box'>
                <h3>“Strengthen resilience of local host communities by improving livelihoods for host community members and Syrian refugees through job creation and infrastructure development.” 
</h3>
            </div>
            
            <RedPolyList list={list} />
            
            <div onClick={() => {setTimeout(() => {
                setShowAnswer(true)
            },1200)}}><TrueAndTrue  question="EIIP in Lebanon started in 2017 ?" answer="EIIP in Lebanon started in 2017, through funding from Germany through the German Development Bank (KfW), targeting the most vulnerable people from Lebanese hosting communities and Syrian Refugees. Activities are implemented through local and national governments. Projects are focused on asset creation and development. For example: road maintenance, rehabilitation of public buildings, generation of short-term job opportunities, as well as capacity development to workers, private sector partners, and the local and central government. Being implemented under the ILO, the EIIP’s comparative advantage in comparison to other similar programs is the focus on mainstreaming decent working conditions and increasing the employability of the workers through capacity development and ad-hoc trainings."/></div>
            
          {showAnswer ? <button onClick={handleUpdates} >Mark As Completed</button> : null}
        </div>
        </>
    )
}

export default EiipInLebanon;