import {ReactComponent as BlueLogo} from '../../assets/logo-blue.svg';
import {ReactComponent as ProfileIcon} from '../../assets/profile-icon.svg';
import {ReactComponent as AngleIcon} from '../../assets/arrow.svg';
import Button from '../button/button.component';
import { Link , Outlet,useNavigate} from 'react-router-dom';

import { useState } from 'react';
import './navbar.styles.scss';
import { selectCurrentUser,selectUserInfo } from '../../store/user/user.selector';
import {useSelector} from 'react-redux';
import { signOutUser } from '../../utils/firebase.utils';
import { setUserInfo } from '../../store/user/user.action';
import { useDispatch } from 'react-redux';
const Navbar = () => {
    const dispatch = useDispatch()
    const [showMenu, setShowMenu] = useState(false);
    const handleShowMenu = () => {
        setShowMenu(!showMenu);
    }
    const user = useSelector(selectUserInfo);
    const navigate = useNavigate()
   const handleSignOut = async () => {
  await  signOutUser();

 

    navigate('../')
   }
    return (
        <>
        <div className='navbar'>
             <div className='nav-left'>
                <Link to='/home'><BlueLogo /></Link>
                <form>
                    <input type="text" placeholder='Search in course' />
                    <Button children='search' style={{width:'100px'}}/>
                </form>
             </div>
             <div className='nav-right' onClick={handleShowMenu}>
                <ProfileIcon/>
                <p>{user ? user.displayName : ""}</p>
                <AngleIcon/>
                {showMenu ? 
                <div className='submenu'>
                    <Link to="../../home/profile">Profile</Link>
                    <Link to="../../home/chapters">Chapters</Link>
                    <Link to="../../dashboard">Dashboard</Link>
                    <Link to="../../home/profile" onClick={handleSignOut}>Logout</Link>
                </div> : null}
             </div>
             
        </div>
        <Outlet/>
        </>
    )
}

export default Navbar