import TrueAndFalse from "../trueorfalse/true-or-false.component"
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import { useState } from "react";
const ExerciseOne = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(3,2,user.uid);
        getUser();
        navigate("../TaskratesandProductivityPart1")
    }
    const [exerc1,setExerc1] = useState(false)
    return (
        <>
        <BreadCrumb 
            chapterTitle="Task Rates and Productivity Norms" 
            subTitle="Exercise"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="exercise-1"
            prevLink="IncentiveschemesforLRBworks"
            nextLink="TaskratesandProductivityPart1"
            chapterNumber='4'
            />
            <h1>Exercise</h1>
            
            <div onClick={() => setExerc1(true)}>
            <TrueAndFalse 
            question="Labour management should always prioritize decent work over the forced enhancement of productivity" 
            answer="Labour management should always prioritize decent work over the forced enhancement of productivity            "/>
            </div>
           {exerc1 ?  <button onClick={handleUpdates}>Mark As Completed</button> : null }
           
        </>
    )
}

export default ExerciseOne;