import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const SocialandEnvironmentalSafeguards = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,2,user.uid,7);
        getUser();
        navigate("../ScreeningandAnalysis")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Social and Environmental Safeguards"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="SocialandEnvironmentalSafeguards"
            prevLink="EnvironmentalSafeguardsProvisions"
            nextLink="ScreeningandAnalysis"
            chapterNumber='6'
            />
        <h1>Social and Environmental Safeguards</h1>
        <ShowText
            title="Social & Environmental Screening and Analysis"
            text="Social & Environmental Screening and Analysis: when all social and environmental risks are identified during the project selection and design phase and proper mitigation measures are put in place to either eliminate those risks or minimize their consequences during the project implementation and operation phases."
            color="#FAB428"
            textColor="#230050"
        />
         <ShowText
            title="Social & Environmental Safeguards Facilitation"
            text="Social & Environmental Safeguards Facilitation: Refers to the overall communication strategy that is put in place to effectively inform about the social and environmental safeguards compliance measures to all parties involved in the project"
            color="#69E4E4"
            textColor="#230050"
        />
        <ShowText
            title="Social & Environmental Safeguards Compliance"
            text="Social & Environmental Safeguards Compliance: refers to the ESSF monitoring mechanism that allows project participants to identify non-compliance and avoid risks."
            color="#AB3B77"
            textColor="white"
        />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default SocialandEnvironmentalSafeguards;