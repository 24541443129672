import { Routes , Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo,selectCurrentUser } from "../../store/user/user.selector";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ChaptersNav from "../chapters-nav/chapters-nav.component";
import Overview from "./overview.component";
import LegalContext from "./legal-context.component";
import ESSFArticlesintheContractTermsConditions from "./essf-articles.components";
import WhatisDecentWork from "./what-is-decent-work.component";
import QuizQuestion from "./quiz-question-decent-work.component";
import DecentWorkConditions from "./decent-work-conditions.component";
import SSOVideo from "./sso-video.component";
import RoleofILOsSocialSafeguardOfficer from "./role-safeguard.component";
import ApproachAndSteps from "./approach-steps.component";
import StandardAdvertisementtemplate from "./standard-advertisement-template.component";
import Referrals from "./referrals.component";
import OSHVideo from "./osh-video.component";
import GeneralPrinciples from "./general-principles.component";
import PersonalProtectiveEquipment from "./protective-equipment.component";
import RiskAssessment from "./risk-assessment.component";
import DeterminingtheRisk from "./determing-the-risk.component";
import RiskManagementMatrix from "./risk-management-matrix.component";
import ImplementationofOccupationalSafetyHealth from "./implementation-of-safety.component";
import WorkersResponsibility from "./workers-responsibility.component";
import ReportingAccidentsonSite from "./reporting-accidents.component";
import WomensParticipationVIDEO from "./women-participation-video.component";
import Whatcanbedone from "./what-can-be-done.component";
import PromotingInclusiveParticipation from "./promoting-participation.component";
import WhyaGrievanceMechanism from "./grievance-mechanism.component";
import GrievanceChannels from "./grievance-channels.component";
import GrievanceProcedure from "./grievance-procedure.component";
import Quiz from "./quiz.component";
import EnvironmentalSafeguardsProvisions from "./environmental-provisions.component";
import SocialandEnvironmentalSafeguards from "./social-and-environmental.component";
import ScreeningandAnalysis from "./screening-analysis.component";
import ScreeningandAnalysisSocialFeasibility from "./social-feasibility.component";
import ScreeningandAnalysisEnvironmentalScreening from "./screening-analy-eviro.component";
import Facilitationthroughtraining from "./facilitation-training.component";
import Compliance from "./compliance.component";
import QuizTemplate from "../quiz-template/quiz-template.component";
import Attempt from "./attempt";
import ChaptersNavSix from "../chapter-six-nav/chapters-six-nav.component";
const ChapterSix = () => {
    const userInfo = useSelector(selectUserInfo);
    const questions = userInfo.chapters[5].questions;
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    useEffect(() => {
     //  if(userInfo.chapters[4].grade < 80){
     //     navigate("../../chapters")
      // }
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[user])
    const details = [
        {
            id:0,
            secondId:"evironment",
            title:"Environment and Social Safeguard Framework and Social Safeguard Officer",
            questions: [
        
                    {
                        "id": 0,
                        "title":"Overview",
                    "imageUrl" : require('../../assets/reading-icon.png'),
                    "url" :"Overview"
                    },
                    {
                        "id": 1,
                    "title":"Legal Context",
                    "imageUrl" : require('../../assets/reading-icon.png'),
                    "url" :"Legal Context"
                    },
                    {
                        "id": 2,
                    "title":"ESSF Articles in the Contract Terms & Conditions",
                    "imageUrl" : require('../../assets/reading-icon.png'),
                    "url" :"ESSFArticlesintheContractTermsConditions"
                    }
                
            ]
        },
        {
            id:1,
            title:"Decent Work",
            secondId:"decent",
            questions: [
        
                {
                    "id": 0,
                "title":"What is Decent Work?",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"WhatisDecentWork"
                },
                {
                    "id": 1,
                "title":"Exercise 1",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"QuizQuestion"
                },
                {
                    "id": 2,
                "title":"Decent Work Conditions",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"DecentWorkConditions"
                }
                
            ]
        },
        {
            id:2,
            secondId:"role",
            title:"Role of the Social Safeguard Officer",
            questions: [
                {
                    "id": 0,
                "title":"SSO Video",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"SSOVideo"
                },
                {
                    "id": 1,
                "title":"Role of ILO’s Social Safeguard Officer",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"RoleofILOsSocialSafeguardOfficer"
                }
                
            ]
        },
        {
            id:3,
            secondId:"decentt",
            title:"Outreach Mechanism",
            questions: [
                {
                    "id": 0,
                "title":"Approach & Steps",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"ApproachAndSteps"
                },
                {
                    "id": 1,
                "title":"Standard Advertisement template",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"StandardAdvertisementtemplate"
                },
                {
                    "id": 2,
                "title":"Referrals",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"Referrals"
                }
                
            ]
        },
        {
            id:4,
            secondId:"safety",
            title:"Occupational Safety & Health",
            questions: [
                {
                    "id": 0,
                "title":"OSH Video",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"OSHVideo"
                },
                {
                    "id": 1,
                "title":"General Principles",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"GeneralPrinciples"
                },
                {
                    "id": 2,
                "title":"Personal Protective Equipment (PPE)",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"PersonalProtectiveEquipment"
                },
                {
                    "id": 3,
                "title":"Risk Assessment",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"RiskAssessment"
                },
                {
                    "id": 4,
                "title":"Determining the Risk",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"DeterminingtheRisk"
                },
                {
                    "id": 5,
                "title":"Risk Management Matrix",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"RiskManagementMatrix"
                },
                {
                    "id": 6,
                "title":"Implementation of Occupational Safety & Health",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"ImplementationofOccupationalSafetyHealth"
                },
                {
                    "id": 7,
                "title":"Workers’ Responsibility",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"WorkersResponsibility"
                },
                {
                    "id": 8,
                "title":"Reporting Accidents on-Site",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"ReportingAccidentsonSite"
                }
                
            ]
        },
        {
            id:5,
            secondId:"inclusion",
            title:"Effective Inclusion",
            questions: [
                {
                    "id": 0,
                "title":"Women’s Participation VIDEO",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"WomensParticipationVIDEO"
                },
                {
                    "id": 1,
                "title":"What can be done?",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"Whatcanbedone"
                },
                {
                    "id": 2,
                "title":"Promoting Inclusive Participation",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"PromotingInclusiveParticipation"
                }
                
            ]
        },
        {
            id:6,
            secondId:"grievance",
            title:"Grievance Mechanism",
            questions: [
                {
                    "id": 0,
                "title":"Why a Grievance Mechanism?",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"WhyaGrievanceMechanism"
                },
                {
                    "id": 1,
                "title":"Grievance Channels",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"GrievanceChannels"
                },
                {
                    "id": 2,
                "title":"Grievance Procedure",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"GrievanceProcedure"
                }
                
            ]
        },
        {
            id:7,
            secondId:"tal",
            title:"Environmental Safeguarding",
            questions: [
                {
                    "id": 0,
                "title":"Quiz",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"Quiz"
                },
                {
                    "id": 1,
                "title":"Environmental Safeguards Provisions",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"EnvironmentalSafeguardsProvisions"
                },
                {
                    "id": 2,
                "title":"Social and Environmental Safeguards",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"SocialandEnvironmentalSafeguards"
                },
                {
                    "id": 3,
                "title":"Screening and Analysis",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"ScreeningandAnalysis"
                },
                {
                    "id": 4,
                "title":"Screening and Analysis-Social Feasibility",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"ScreeningandAnalysisSocialFeasibility"
                },
                {
                    "id": 5,
                "title":"Screening and Analysis-Environmental Screening",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"ScreeningandAnalysisEnvironmentalScreening"
                },
                {
                    "id": 6,
                "title":"Facilitation through training",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"Facilitationthroughtraining "
                },
                {
                    "id": 7,
                "title":"Compliance",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"Compliance"
                },
                {
                    "id": 8,
                "title":"Quiz",
                "imageUrl" : require('../../assets/reading-icon.png'),
                "url" :"quiz-1"
                }
                
            ]
        }
    ]
    window.scrollTo(0, 0);
    const a3 = details.map((t1) => ({ ...t1, ...questions.find((t2) => t2.id === t1.id)  }));
    const a4 = questions.map((t1) => ({ ...t1, ...details.find((t2) => t2.id === t1.id) }));
   
   
    
    return (
        <>
        <div className="chapter-wrapper">
            <ChaptersNavSix navLinks={a3} chapterTitle="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer" />
           
           <div className="chapters-lessons">
          <Routes>
          <Route index path="overview" element={<Overview/>}/>
          <Route  path="LegalContext" element={<LegalContext/>}/>
          <Route  path="ESSFArticlesintheContractTermsConditions" element={<ESSFArticlesintheContractTermsConditions/>}/>
          <Route  path="WhatisDecentWork" element={<WhatisDecentWork/>}/>
          <Route  path="QuizQuestion" element={<QuizQuestion/>}/>
          <Route  path="DecentWorkConditions" element={<DecentWorkConditions/>}/>
          <Route  path="SSOVideo" element={<SSOVideo/>}/>
          <Route  path="RoleofILOsSocialSafeguardOfficer" element={<RoleofILOsSocialSafeguardOfficer/>}/>
          <Route  path="ApproachAndSteps" element={<ApproachAndSteps/>}/>
          <Route  path="StandardAdvertisementtemplate" element={<StandardAdvertisementtemplate/>}/>
          <Route  path="Referrals" element={<Referrals/>}/>
          <Route  path="OSHVideo" element={<OSHVideo/>}/>
          <Route  path="GeneralPrinciples" element={<GeneralPrinciples/>}/>
          <Route  path="PersonalProtectiveEquipment" element={<PersonalProtectiveEquipment/>}/>
          <Route  path="RiskAssessment" element={<RiskAssessment/>}/>
          <Route  path="DeterminingtheRisk" element={<DeterminingtheRisk/>}/>
          <Route  path="RiskManagementMatrix" element={<RiskManagementMatrix/>}/>
          <Route  path="ImplementationofOccupationalSafetyHealth" element={<ImplementationofOccupationalSafetyHealth/>}/>
          <Route  path="WorkersResponsibility" element={<WorkersResponsibility/>}/>
          <Route  path="ReportingAccidentsonSite" element={<ReportingAccidentsonSite/>}/>
          <Route  path="WomensParticipationVIDEO" element={<WomensParticipationVIDEO/>}/>
          <Route  path="Whatcanbedone" element={<Whatcanbedone/>}/>
          <Route  path="PromotingInclusiveParticipation" element={<PromotingInclusiveParticipation/>}/>
          <Route  path="WhyaGrievanceMechanism" element={<WhyaGrievanceMechanism/>}/>
          <Route  path="GrievanceChannels" element={<GrievanceChannels/>}/>
          <Route  path="GrievanceProcedure" element={<GrievanceProcedure/>}/>
          <Route  path="Quiz" element={<Quiz/>}/>
          <Route  path="EnvironmentalSafeguardsProvisions" element={<EnvironmentalSafeguardsProvisions/>}/>
          <Route  path="SocialandEnvironmentalSafeguards" element={<SocialandEnvironmentalSafeguards/>}/>
          <Route  path="ScreeningandAnalysis" element={<ScreeningandAnalysis/>}/>
          <Route  path="ScreeningandAnalysisSocialFeasibility" element={<ScreeningandAnalysisSocialFeasibility/>}/>
          <Route  path="ScreeningandAnalysisEnvironmentalScreening" element={<ScreeningandAnalysisEnvironmentalScreening/>}/>
          <Route  path="Facilitationthroughtraining" element={<Facilitationthroughtraining/>}/>
          <Route  path="Compliance" element={<Compliance/>}/>
          <Route path="quiz-1" element={<QuizTemplate chapterNumber='6' quizLink='attempt' toPass="90%"
              chapterTitle="Environment and Social Safeguard..." 
              subTitle="Facilitation through training"
              chapterLink="Quiz"
              subLink="quiz-1"
              prevLink="Compliance"
              chapterGrade={(Math.round(userInfo.chapters[5].grade * 100) / 100).toFixed(2)}
              nextLink="../ContractAdministration&ParticularConditions/ContractAdministration"
          />}/>
          <Route path="attempt" element={<Attempt/>}/>
       </Routes>
           </div>
         
        </div>
       
         
        </>
    )

}


export default ChapterSix;