import { useState } from "react";
import { useSelector } from "react-redux";
import { quizAnswer } from "../../utils/firebase.utils";

import { selectCurrentUser,selectUserInfo } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { updateChapters ,startChapterss, updateGrade ,certificatePass} from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


const Attempt = () => {
    
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] =useState(false);
    const [showHints, setShowHints] = useState(false);
    const [hideButton,setHideButton] = useState(false);
    const navigate = useNavigate();
    const userInfo = useSelector(selectUserInfo)
    const questions = [
        {
        id: 0,
        question : "Who is funding the EIIP in Lebanon",
        answer:"",
        score: 100/15,
        checked: false,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint:false,
        multi: false,
        options: [
            {id:0, text:"Germany",checked:false,name:"same"},
            {id:1, text:"Germany through the German Development Bank KfW",checked:false,name:"same"},
            {id:2, text:"Japan",checked:false,name:"same"},
            
          
            ]
    },
    {
        id:1,
        question : "What is meant by Local Resource Based Technology?",
        answer:"",
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"Work methods and technologies where the use of local resources, is favored and optimized, without compromising the quality",checked:false,name:"same1" },
            {id:1, text:"Work methods and technologies where the use of local resources, is favoured and optimised, regardless of the quality",checked:false,name:"same1" },
            {id:2, text:"Utilizing local resources, and work stops if local resources are not available",checked:false,name:"same1" },
            
          
        ]
    },
    {
        id:2,
        question : "Select the appropriate Local Resource Based Work Method/s?",
        answer:[],
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"Employing as many people as possible from within the vicinity of the project while ensuring productivity",name:"Employing as many people as possible from within the vicinity of the project",checked:false },
            {id:1, text:"Using the local resources available, even if they adversely affect the quality of the work", name:"Using the local resources available, even if they adversely affect the quality of the work",checked:false },
            {id:2, text:"Use of Appropriate (light) equipment to support the activities",name:"Use of Appropriate (light) equipment to support the activities",checked:false },
            {id:3, text:"LRB is all about using local resources regardless of outcomes and results",name:"LRB is all about using local resources regardless of outcomes and results",checked:false },
        ]
    },
    {
        id:3,
        question : "What are the socio-economic advantages of LRBT?",
        answer:[],
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"Providing employment opportunities",checked:false, name:"Providing employment opportunities" },
            {id:1, text:"Developing Skills",checked:false,name:"Developing Skills" },
            {id:2, text:"Quick implementation and results",checked:false,name:"Quick implementation and results" },
            {id:3, text:"High foreign exchange is needed",checked:false,name:"High foreign exchange is needed" },
       
           
            
    ]
    },{
        id:4,
        question : "Is the following statement on Work Plans True or False?",
        sentence:"“A well-developed work plan helps the contractor to better plan required inputs and resources, better monitor the progress of work, and help in finalizing work on time and with the least cost”",
        answer:"",
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"Correct",name:"samesix",checked:false },
            {id:1, text:"Incorrect", name:"samesix",checked:false },
            
          
        ]
    }
    ,{
        id:5,
        question : "What should a work plan include",
        answer:[],
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"Bill of Quantities (BoQ)",name:"Bill of Quantities (BoQ)",checked:false },
            {id:1, text:"Percentage completion on a bi-weekly basis", name:"Percentage completion on a bi-weekly basis",checked:false },
            {id:2, text:"Number of workers per activity", name:"Number of workers per activity",checked:false },
            {id:3, text:"Number of workdays per activity", name:"Number of workdays per activity",checked:false },
            {id:4, text:"Map of stakeholders you will be working with", name:"Map of stakeholders you will be working with",checked:false },
          
        ]
    }
    ,{
        id:6,
        question : "Select the correct statement/s on the use of task rates and productivity",
        answer:[],
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"Task Rates and productivities are used to calculate labour or equipment costing for submitting bids",name:"Task Rates and productivities are used to calculate labour or equipment costing for submitting bids",checked:false },
            {id:1, text:"Task Rates and Productivities are used to calculate the profit the contractor will make out of the project", name:"Task Rates and Productivities are used to calculate the profit the contractor will make out of the project",checked:false },
            {id:2, text:"Task Rates and Productivities are used to check whether local resources are available and sufficient", name:"sTask Rates and Productivities are used to check whether local resources are available and sufficient",checked:false },
            {id:3, text:"Task Rates and Productivities are used to manage labour and equipment for construction works", name:"Task Rates and Productivities are used to manage labour and equipment for construction works",checked:false },
            
          
        ]
    }
    ,{
        id:7,
        question : "Which of the below items are among the Decent Work principles",
        answer:[],
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"Fair recruitment",name:"Fair recruitment",checked:false },
            {id:1, text:"Recruitment of women only (prioritizing women over men)", name:"Recruitment of women only (prioritizing women over men)",checked:false },
            {id:2, text:"Provide safe and free of charge transportation", name:"Provide safe and free of charge transportation",checked:false },
            {id:3, text:"Provide training and capacity building", name:"Provide training and capacity building",checked:false },
            {id:4, text:"Work without signing any contract", name:"Work without signing any contract",checked:false },
            {id:5, text:"Recruit only skilled workers and those that are knowledgeable", name:"Recruit only skilled workers and those that are knowledgeable",checked:false },
            
          
        ]
    }
    ,{
        id:8,
        question : "Why is a comprehensive outreach mechanism important?",
        answer:"",
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"It is important because it will enhance the ownership of the municipality on the project.  The contractor should ONLY talk to the municipality and in turn the municipality will reach out for the available outreach outlets in the area",  name:"It is important because it will enhance the ownership of the municipality on the project.  The contractor should ONLY talk to the municipality and in turn the municipality will reach out for the available outreach outlets in the area",checked:false },
            {id:1, text:"To ensure inclusivity of all vulnerable groups (including women and Persons with Disabilities), eliminate bias in recruitment, promote for the project objectives, and optimize local recruitment", name:"To ensure inclusivity of all vulnerable groups (including women and Persons with Disabilities), eliminate bias in recruitment, promote for the project objectives, and optimize local recruitment",checked:false },
            {id:2, text:"A comprehensive outreach mechanism is not important, especially that previously used methodologies (word of mouth, general announcements, etc.) have been working well", name:"A comprehensive outreach mechanism is not important, especially that previously used methodologies (word of mouth, general announcements, etc.) have been working well",checked:false },
            
          
        ]
    }
    ,{
        id:9,
        question : "Select all that are correct with regards to Occupational Safety and Health (OSH) measures on site",
        answer:[],
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"OSH is a collective effort, and it is everyone’s responsibility including managers, supervisors, and workers",name:"OSH is a collective effort, and it is everyone’s responsibility including managers, supervisors, and workers",checked:false },
            {id:1, text:"Risk assessments, OSH plans and OSH measures should be put in place before any works start on site", name:"Risk assessments, OSH plans and OSH measures should be put in place before any works start on site",checked:false },
            {id:2, text:"OSH measures are optional depending on how risky the nature of the project is", name:"OSH measures are optional depending on how risky the nature of the project is",checked:false },
            {id:3, text:"OSH is to be considered a priority only when there are excavation works and/or scaffolding", name:"OSH is to be considered a priority only when there are excavation works and/or scaffolding",checked:false },
            {id:4, text:"Relevant Personal Protective Equipment are to be provided in sufficient amounts to everyone on-site before works start", name:"Relevant Personal Protective Equipment are to be provided in sufficient amounts to everyone on-site before works start",checked:false },
            
          
        ]
    }
    ,{
        id:10,
        question : "Select all that apply in relevance to ensuring effective inclusion of women on EIIP Sites",
        answer:[],
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"Advertise jobs in frequently accessible to women",name:"Advertise in jobs frequently accessible to women",checked:false },
            {id:1, text:"Ensure Equal pay for Equal work", name:"Ensure Equal pay for Equal work",checked:false },
            {id:2, text:"Assign tasks to women that are basic and do not require any skills", name:"Assign tasks to women that are basic and do not require any skills",checked:false },
            {id:3, text:"Be culturally sensitive and understand the specificities of the community you are working at", name:"Be culturally sensitive and understand the specificities of the community you are working at",checked:false },
            {id:4, text:"Do not provide capacity building and training for skilled work as it is a waste of time, instead stick to traditionally accepted tasks for women (coffee making, cleaning site, etc.)", name:"Do not provide capacity building and training for skilled work as it is a waste of time, instead stick to traditionally accepted tasks for women (coffee making, cleaning site, etc.)",checked:false },
            {id:5, text:"Promote women team leaders on site", name:"Promote women team leaders on site",checked:false },
            
          
        ]
    }
    ,{
        id:11,
        question : "Is the following statement True or False",
        sentence:"“A contractor has the right to end a worker’s contract if s/he have submitted a complaint against the contractor or one of their team members”",
        answer:"",
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"Correct",name:"sameee",checked:false },
            {id:1, text:"Incorrect", name:"sameee",checked:false },
       
            
          
        ]
    }
    ,{
        id:12,
        question : "Which statement is correct with regards to Environmental and Social Safeguarding?",
        answer:"",
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"The contractors must implement the required social & environmental safeguard measures only in mobilization phase",checked:false,name:"same13" },
            {id:1, text:"Environmental & Social safeguard measures shall be implemented during planning, design, mobilization, and works implementation phases", name:"same13",checked:false },
            {id:2, text:"The Environmental and Social Safeguards management is developed once during the planning phase, and it cannot be updated beyond that", name:"same13",checked:false },
       
            
          
        ]
    }
    ,{
        id:13,
        question : "Is the following statement True or False",
        sentence:"“A DO NO HARM approach is important as it ensures that no conflict sensitivity issues effect the goals to be achieved by the project”",
        answer:"",
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"Correct",checked:false,name:"sameteh" },
            {id:1, text:"Incorrect", name:"sameteh",checked:false },
           
       
            
          
        ]
    }
    ,{
        id:14,
        question : "Select the correct order of procedures for submitting a bid with EIIP",
        answer:"",
        score:100/15,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"Bid Submission, LRBT Training, Bid Award, Bid clarification",name:"same15",checked:false },
            {id:1, text:"Bid Clarification, LRBT Training, Bid Submission, Bid Award", name:"same15",checked:false },
            {id:2, text:"LRBT Training, Bid Clarification, Bid Submission, Bid Award", name:"same15",checked:false },
            {id:3, text:"LRBT Training, Bid Submission, Bid Clarification, Bid Award", name:"same15",checked:false },
           
       
            
          
        ]
    }
]
    const [answers , setAnswers] = useState(questions);

    const handleChange = async (id,answer,idd) =>  {
        await setAnswers(questions => 
            questions.map(obj => {
                if (obj.id === id){
                    if(!obj.multi){
                        obj.answer = answer
                        obj.options.map((bjj => {
                            if(bjj.id===idd){
                                bjj.checked = true
                            }
                            else{
                                bjj.checked = false
                            }
                        }))
                    }else{
                       if(!obj.answer.includes(answer)){
                        obj.answer.push(answer)
                       
                       }else {
                        
                        for (var i=obj.answer.length-1; i>=0; i--) {
                            if (obj.answer[i] === answer) {
                                obj.answer.splice(i, 1);
                                // break;       //<-- Uncomment  if only the first term has to be removed
                            }
                        }
                       }
                       
                        
                        obj.options.map((bjj) => {
                            
                            if(bjj.id === idd){
                                if(bjj.checked){
                                    bjj.checked = false
                                }else{
                                    bjj.checked = true
                                }
                            }
                        })
                    }
                   
                  
                }
               
                return obj;
                
            })
            )
            
     }
     
     
    
     const dispatch = useDispatch();
     const user = useSelector(selectCurrentUser)
     const getUserr = async () => {
         const userr = await getUsers(user.uid);
         dispatch(setUserInfo(userr));
       }
         const handleUpdates = () => {
         updateChapters(7,0,user.uid);
         try{
            startChapterss(8,9,user.uid);
            certificatePass(user.uid)
        }catch(error) {
            
        }
         getUserr();
     }
   
    const getUser = async () => {
        const quizTitle = "FinalQuiz";
        const userr = await quizAnswer(quizTitle);
        const a3 = answers.map((t1) => ({ ...t1, ...userr.find((t2) => t2.id === t1.id) }));
        a3.map( async (obj)  =>  {
           const od = obj.id;
            if(obj.id == od){
                if(!obj.multi){
                    if(obj.answer === obj.answers){
                        setScore(score => score + obj.score);
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:true}
                                }
                            return obj;
                                
                            })
                            )
                    } else{
                        if(obj.answer===""){
                            setAnswers(questions => questions.map((item) => {
                                if(item.id===od){
                                   
                                    item.hint= "You didnt select any option"
                                    item.showHint= false
                                }
                                return item
                            }))
                        }
                    }
                }else{
                    if(areEqual(obj.answer,obj.answers)){
                        
                        setScore(score => score + obj.score);
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:true}
                                }
                            return obj;
                                
                            })
                            )
                    }else{
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:false,hint:"You didnt select all that applies"}
                                }
                            return obj;
                                
                            })
                            )
                    }
                }
            }
               
               
            setShowScore(true);
            setShowHints(true);
            
        })
        
      }
    const handleSubmit = async (e) => {
        e.preventDefault();
      await   getUser();
         window.scrollTo(0, 0);
    
         setHideButton(true)
    }
    

    useEffect( () => {
        if(score==0){
        return
        }else{
            updateGrade(user.uid,score,7)
        }
      
        if (score >= 93) {
           if(userInfo.chapters[7].status === "completed"){
            return
           }else{
            handleUpdates();
            try{
                startChapterss(7,user.uid);
            }catch(error) {
               
            }
           }
           

           
         getUserr();
        }
    },[score])
    
    const resetQuiz = () => {
        setShowScore(false);
        setShowHints(false);
        setScore(0);
        setHideButton(false)
        setAnswers(questions => 
            questions.map(obj => {
                if(!obj.multi){
                    obj.answer = ""
                }else{
                    obj.answer = []
                }
                obj.options.map((item) => {
                    item.checked = false
                })
                 return obj
            }
            
            )
            
            )
           
    }       

function areEqual(arr1, arr2)
    {
        let N = arr1.length;
        let M = arr2.length;
 
        // If lengths of array are not equal means
        // array are not equal
        if (N != M)
            return false;
 
        // Sort both arrays
        arr1.sort();
        arr2.sort();
 
        // Linearly compare elements
        for (let i = 0; i < N; i++)
            if (arr1[i] != arr2[i])
                return false;
 
        // If all elements were same.
        return true;
    }
    
    return (
        <div>
            
            {
               showScore ?
                <div className={ score >= 93 ? "scoreBox passed" : "scoreBox failed"}>
                    {score > 93 ? "Congratulations!  You passed!" : "Try again once you are ready"}
                    <br /> Grade received : <span>{(Math.round(score * 100) / 100).toFixed(2)}%</span>  
                    to pass 93% or higher
                    {score >= 93 ? <button className="next-item" onClick={() => navigate("../../../profile")}>Check your certificate!</button> : <button className="try-again" onClick={resetQuiz}>Try Again</button> }
                    
                </div> : null
            }
            
            
           
            <form className="attempt" onSubmit={!hideButton ? handleSubmit : null}>
                {answers.map((item) => 
                <>
                <h3>{item.question} {item.multi ? "(select all that apply)" : null}</h3>
                <h3>{item.sentence ? item.sentence : null}</h3>
                {item.options.map((option) => 
                <>
                <input  checked={option.checked ? "checked" : null}  onChange={() => handleChange(item.id,option.text,option.id)} type={!item.multi ? "radio" : "checkbox"} name={option.name}  value={option.answer}  /><label for={option.name}>{option.text}</label><br />
              
                </>
                )}
                {showHints ? 
                <div>
                    {item.showHint ? <div className="correct"><h4>Correct</h4></div> : 
                                     <div className="incorrect"><h4>Incorrect</h4> </div>}
                </div> 
                : null
                }
                
                </>
               
                )}
                {!hideButton ?  <button type="submit">Submit Answers</button> : null}
            </form>
        </div>
    )
}

export default Attempt;