import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const RetainingWall = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(1,10,user.uid);
        getUser();
        navigate("../WhentoapplyLRBTimportantconsiderationsatplanningstage")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Local Resource Based Technology" 
            subTitle="Employment Intensive Infrastructure..."
            chapterLink="Socio-economic advantages of LRBT"
            subLink="EmploymentIntensiveInfrastructureProgrammeinLebanon"
            prevLink="LocalResource-based(LRB)WorkMethod"
            nextLink="WhentoapplyLRBTimportantconsiderationsatplanningstage"
            chapterNumber="2"
            />
        <h1>Employment Intensive Infrastructure Programme in Lebanon (EIIP), Retaining Walls (2020)</h1>
        <p>Some activities are labour intensive by nature, and rely on local materials, such as stone masonry walls.</p>
        <p>The purpose of the LRBT mainstreaming is to try and adopt traditional activities, and adapt the “modern” ones to labour and local resource methods</p>
        <img className="retaining-walls" src={require('../../assets/retaining-1-2.jpg')} alt="" />
        <img className="retaining-walls" src={require('../../assets/retaining-2-2.jpg')} alt="" />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}
export default RetainingWall;