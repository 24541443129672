import ChaptersNav from "../chapters-nav/chapters-nav.component";
import { Routes , Route } from "react-router-dom";
import Description from "./description.component";
import VideoChapterOne from "./video-chapter-one.component";
import InternationalLabourOrganization from "./International-labour.component";
import IntensiveInfrastructure from "./intensive-infrastructure";
import EiipInLebanon from "./eiip-in-lebanon.component";
import EiipStrategy from "./eiip-strategy.component";
import ContractorObligations from "./contractor-obligations.component";
import ContractorTraining from "./contractor-training.component";
import QuizTemplate from "../quiz-template/quiz-template.component";
import Attempt from "./attempt";
import { useSelector } from "react-redux";
import { selectUserInfo ,selectCurrentUser} from "../../store/user/user.selector";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import './chapter-one.styles.scss';
const ChapterOne = () => {
    const userinfo = useSelector(selectUserInfo);
    const user = useSelector(selectCurrentUser);
    const questions = userinfo.chapters[0].questions;
    const navigate = useNavigate()
    useEffect(() => {
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[user])
    
    const details = [
        {
            "id": 0,
            "title":"Video: EIIP",
        "imageUrl" : require('../../assets/video-icon.png'),
        "url" :"video-eiip"
        },
        {
            "id": 1,
        "title":"What is the International Labour Organization?",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"what-is-the-international-labour-organization"
        },
        {
            "id": 2,
        "title":"The Employment Intensive Infrastructure Programme (EIIP)",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"the-employment-intensive-infrastructure-programme"
        },{
            "id": 3,
            "title":"EIIP in Lebanon",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"eiip-in-lebanon"
        },{
            "id": 4,
            "title":"EIIP Strategy",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"eiip-strategy"
        },{
            "id": 5,
            "title":"Contractor Obligations",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"contractor-obligations"
        },{
            "id": 6,
            "title":"EIIP Contractor Training Strategy",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"contractor-training-strategy"
        },{
            "id": 7,
            "title":"Quiz",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"quiz"
        },
    
    ]
    const a3 = details.map((t1) => ({ ...t1, ...questions.find((t2) => t2.id === t1.id) })); 
    window.scrollTo(0, 0);
    return (
      <>
      <div className="chapter-wrapper">
         <ChaptersNav title="Intro to EIIP & LRBT" navLinks={a3} chapterTitle="IntrotoEIIP&LRBT"/>
         <div className="chapters-lessons">
        <Routes>
        <Route index path="description" element={<Description/>}/>
        <Route path="video-eiip" element={<VideoChapterOne/>}/>
        <Route path="what-is-the-international-labour-organization" element={<InternationalLabourOrganization/>}/>
        <Route path="the-employment-intensive-infrastructure-programme" element={<IntensiveInfrastructure/>}/>
         <Route path="eiip-in-lebanon" element={<EiipInLebanon/>}/>
         <Route path="eiip-strategy" element={<EiipStrategy/>}/>
         <Route path="contractor-obligations" element={<ContractorObligations/>}/>
        <Route path="contractor-training-strategy" element={<ContractorTraining/>}/>
        <Route path="quiz" element={<QuizTemplate 
                                        chapterNumber='1' 
                                        quizLink='attempt' 
                                        toPass="80%"
                                        chapterTitle="Intro to EIIP & LRBT" 
                                        subTitle="Quiz"
                                        chapterLink="IntrotoEIIP&LRBT"
                                        subLink="quiz"
                                        prevLink="contractor-training-strategy"
                                        chapterGrade={userinfo.chapters[0].grade}
                                        nextLink="../LocalResourceBasedTechnology/lrbt-video"
                                        />}/>
        <Route path="attempt" element={<Attempt/>}/>
     </Routes>
         </div>
       
      </div>
     
       
      </>
       
    )
}


export default ChapterOne;