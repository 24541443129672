import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const KeyContractDocuments = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(6,1,user.uid);
        getUser();
        navigate("../ParticularConditions")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Contract Administration..." 
            subTitle="Key Contract Documents"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="KeyContractDocuments"
            prevLink="ContractAdministration"
            nextLink="ParticularConditions"
            chapterNumber="7"
            />
        <h1>Key Contract Documents</h1>
        <ShowText
        title="BoQ"
        color="#BEDCFA"
        textColor="#230050"
        />
        <ShowText
        title="Technical Specifications"
        color="#BEDCFA"
        textColor="#230050"
        />
        <ShowText
        title="Drawings & Work Plan"
        color="#BEDCFA"
        textColor="#230050"
        />
        <ShowText
        title="General Conditions"
        color="#BEDCFA"
        textColor="#230050"
        />
        <ShowText
        title="Particular Conditions"
        color="#5A87CD"
        textColor="white"
        />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default KeyContractDocuments;