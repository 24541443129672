import './form-input.styles.scss'
const FormInput = ({label, ...otherProps}) => {
    return (
        <div className='inputs-holder'>
            <label>{label}</label>
            <input {...otherProps}/>
        </div>
       
    )
}

export default FormInput;