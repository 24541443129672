import { useState } from "react";
import { useSelector } from "react-redux";
import { quizAnswer } from "../../utils/firebase.utils";

import { selectCurrentUser,selectUserInfo } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { updateChapters ,startChapters, updateGrade } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Attempt = () => {
    
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] =useState(false);
    const [showHints, setShowHints] = useState(false);
    const [hideButton,setHideButton] = useState(false);
    const navigate = useNavigate();
    const userInfo = useSelector(selectUserInfo)
    const questions = [
        {
        id: 0,
        question : "What is the minimum required personnel with LRBT training per company?",
        answer:"",
        score: 100/6,
        checked: false,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint:false,
        multi: false,
        options: [
            {id:0, text:"One",checked:false,name:"same"},
            {id:1, text:"Two",checked:false,name:"same"},
            {id:2, text:"Four",checked:false,name:"same"},
            
            ]
    },
    {
        id:1,
        question : "Who is responsible for the recruitment of labors?",
        answer:"",
        score:100/6,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"ILO",checked:false,name:"same1" },
            {id:1, text:"Municipality",checked:false,name:"same1" },
            {id:2, text:"Contractor",checked:false,name:"same1" },
          
        ]
    },
    {
        id:2,
        question : "What are the criteria to identify the number of workers per activity and as total workdays generated?",
        answer:"",
        score:100/6,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"A fixed number of labors on site will be divided by the activities equally.",name:"same2",checked:false },
            {id:1, text:"Put the highest number of labors on the important activities", name:"same2",checked:false },
            {id:2, text:"The number of laborers required for different activities vary with the type of works and the expected productivity", name:"same2",checked:false },
          
        ]
    },
    {
        id:3,
        question : "Three major workplan consideration",
        answer:[],
        score:100/6,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"Complexity and logical sequencing of activities",checked:false, name:"Complexity and logical sequencing of activities" },
            {id:1, text:"Minimum wage of the workers",checked:false,name:"Minimum wage of the workers" },
            {id:2, text:"Number of workers",checked:false,name:"Number of workers" },
            {id:3, text:"Duration of the project",checked:false,name:"Duration of the project" },
            
            {id:4, text:"Location of the project",checked:false,name:"Location of the project" },
            
    ]
    },{
        id:4,
        question : "What are the criteria to identify the number of workers per activity and as total workdays generated?",
        answer:"",
        score:100/6,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"WDs = Quantity of each activity/ Task rate (productivity of the worker)",name:"same4",checked:false },
            {id:1, text:"WDs = Total price of the activity / The minimum wage of the workers", name:"same4",checked:false },
            {id:2, text:"WDs = 35% of the contract value / Number of activities", name:"same4",checked:false },
          
        ]
    },
    {
        id:5,
        question : "Who is responsible for the supervision of the work during execution?",
        answer:"",
        score:100/6,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"The ILO",name:"same5",checked:false },
            {id:1, text:"The Municipality and the ILO", name:"same5",checked:false },
            {id:2, text:"The Municipality", name:"same5",checked:false },
            {id:3, text:"The Contractor and the ILO",name:"same5",checked:false },
            {id:4, text:"The Contractor",name:"same5",checked:false },
          
        ]
    },

        ]
    const [answers , setAnswers] = useState(questions);

    const handleChange = async (id,answer,idd) =>  {
        await setAnswers(questions => 
            questions.map(obj => {
                if (obj.id === id){
                    if(!obj.multi){
                        obj.answer = answer
                        obj.options.map((bjj => {
                            if(bjj.id===idd){
                                bjj.checked = true
                            }
                            else{
                                bjj.checked = false
                            }
                        }))
                    }else{
                       if(!obj.answer.includes(answer)){
                        obj.answer.push(answer)
                       
                       }else {
                        
                        for (var i=obj.answer.length-1; i>=0; i--) {
                            if (obj.answer[i] === answer) {
                                obj.answer.splice(i, 1);
                                // break;       //<-- Uncomment  if only the first term has to be removed
                            }
                        }
                       }
                       
                        
                        obj.options.map((bjj) => {
                            
                            if(bjj.id === idd){
                                if(bjj.checked){
                                    bjj.checked = false
                                }else{
                                    bjj.checked = true
                                }
                            }
                        })
                    }
                   
                  
                }
               
                return obj;
                
            })
            )
            
     }
     
     
    
     const dispatch = useDispatch();
     const user = useSelector(selectCurrentUser)
     const getUserr = async () => {
         const userr = await getUsers(user.uid);
         dispatch(setUserInfo(userr));
       }
         const handleUpdates = () => {
         updateChapters(2,10,user.uid);
         getUserr();
     }
   
    const getUser = async () => {
        const quizTitle = "LabourManagement&WorkPlan";
        const userr = await quizAnswer(quizTitle);
        const a3 = answers.map((t1) => ({ ...t1, ...userr.find((t2) => t2.id === t1.id) }));
        a3.map( async (obj)  =>  {
           const od = obj.id;
            if(obj.id == od){
                if(!obj.multi){
                    if(obj.answer === obj.answers){
                        setScore(score => score + obj.score);
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:true}
                                }
                            return obj;
                                
                            })
                            )
                    } else{
                        if(obj.answer===""){
                            setAnswers(questions => questions.map((item) => {
                                if(item.id===od){
                                    
                                    item.hint= "You didnt select any option"
                                    item.showHint= false
                                }
                                return item
                            }))
                        }
                    }
                }else{
                    if(areEqual(obj.answer,obj.answers)){
                        
                        setScore(score => score + obj.score);
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:true}
                                }
                            return obj;
                                
                            })
                            )
                    }else{
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:false,hint:"You didnt select all that applies"}
                                }
                            return obj;
                                
                            })
                            )
                    }
                }
            }
               
               
            setShowScore(true);
            setShowHints(true);
            
        })
        
      }
    const handleSubmit = async (e) => {
        e.preventDefault();
      await   getUser();
         window.scrollTo(0, 0);
    setHideButton(true)
      
    }
    

    useEffect( () => {
        if(score==0){
        return
        }else{
            updateGrade(user.uid,score,2)
        }
    
        if (score >= 83.33) {
            if(userInfo.chapters[2].status === "completed"){
                return
            }else{
                handleUpdates();

            try{
                startChapters(2,3,user.uid);
            }catch(error) {
               
            }
            }
            
         getUserr();
        }
    },[score])
    
    const resetQuiz = () => {
        setShowScore(false);
        setShowHints(false);
        setScore(0)
        setHideButton(false)
        setAnswers(questions => 
            questions.map(obj => {
                if(!obj.multi){
                    obj.answer = ""
                }else{
                    obj.answer = []
                }
                obj.options.map((item) => {
                    item.checked = false
                })
                 return obj
            }
            
            )
            
            )
           
    }       

function areEqual(arr1, arr2)
    {
        let N = arr1.length;
        let M = arr2.length;
 
        // If lengths of array are not equal means
        // array are not equal
        if (N != M)
            return false;
 
        // Sort both arrays
        arr1.sort();
        arr2.sort();
 
        // Linearly compare elements
        for (let i = 0; i < N; i++)
            if (arr1[i] != arr2[i])
                return false;
 
        // If all elements were same.
        return true;
    }
    
    return (
        <div>
            
            {
               showScore ?
                <div className={ score >= 83.33 ? "scoreBox passed" : "scoreBox failed"}>
                    {score > 83.33 ? "Congratulations!  You passed!" : "Try again once you are ready"}
                    <br /> Grade received : <span>{(Math.round(score * 100) / 100).toFixed(2)}%</span>   
                    to pass 83.33% or higher
                    {score >= 83.33 ? <button className="next-item" onClick={() => navigate("../../TaskRatesandProductivityNorms/LabourmanagementforLRBworks")}>Go to next item</button> : <button className="try-again" onClick={resetQuiz}>Try Again</button> }
                    
                </div> : null
            }
            
            
           
            <form className="attempt" onSubmit={handleSubmit}>
                {answers.map((item) => 
                <div key={item.id}>
                <h3>{item.question} {item.multi ? "(select all that apply)" : null}</h3>
                
                {item.options.map((option) => 
                <div key={option.id} >
                <input  checked={option.checked ? "checked" : null}  onChange={() => handleChange(item.id,option.text,option.id)} type={!item.multi ? "radio" : "checkbox"} name={option.name} id={option.text} value={option.answer}  /><label for={option.text}>{option.text}</label><br />
               
                </div>
                )}
                {showHints ? 
                <div>
                    {item.showHint ? <div className="correct"><h4>Correct</h4></div> : 
                                     <div className="incorrect"><h4>Incorrect</h4> </div>}
                </div> 
                : null
                }
                
                </div>
               
                )}
                {!hideButton ?  <button type="submit">Submit Answers</button> : null}
               
            </form>
        </div>
    )
}

export default Attempt;