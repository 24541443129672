import { Route, Routes  } from 'react-router-dom';
import { useEffect } from 'react';
import { createUserDocumentFromAuth, onAuthStateChangedListener } from './utils/firebase.utils';
import './App.scss';
import Home from './routes/home/home.component';
import Auth from './routes/Auth/auth.component';
import Navbar from './components/navbar/navbar.component';
import Chapters from './routes/chapters/chapters.component';
import ChapterOne from './components/chapter-one/chapter-one.component';
import ChapterTwo from './components/chapter-two/chapter-two.component';
import ChapterThree from './components/chapter-three/chapter-three.component';
import ChapterFour from './components/chapter-four/chapter-four.component';
import ChapterFive from './components/chapter-five/chapter-five.component';
import ChapterSix from './components/chapter-six/chapter-six.component';
import ChapterSeven from './components/chapter-seven/chapter-seven.component';
import ChapterEight from './components/chapter-eight/chapter-eight.component';
import Profile from './components/profile/profile.component';
import Dashboard from './components/dashboard/dashboard.component';
import {useDispatch} from 'react-redux';
import { setCurrentUser, setUserInfo } from './store/user/user.action';
import { getUsers, quizAnswer } from './utils/firebase.utils';
import {useSelector} from 'react-redux';
import { selectCurrentUser } from './store/user/user.selector';
const App = () => {

  const dispatch = useDispatch();

  
  useEffect(()=> {
    
    const unsubscribe = onAuthStateChangedListener((user) => {
     
      dispatch(setCurrentUser(user));
      const getUser = async () => {
        if(user){
          const userr = await getUsers(user.uid);
          dispatch(setUserInfo(userr));
        }
        
       
       
      }
      getUser();
      return unsubscribe;
    });
    
  },[])
  const userAuth = useSelector(selectCurrentUser);

 
  

  return (
    <>
   
    <div className="App">
      </div>
      <Routes>
      <Route path="dashboard" element={<Dashboard/>}/>
        <Route path='/*' element={<Auth/>}/>
         
        
        <Route path='home' element={<Navbar/>}>
       
        <Route path='profile' element={<Profile/>}/>
        <Route index element={<Home/>}/>
        <Route path='chapters/*'>
        <Route index element={<Chapters/>}/>
        <Route path="IntrotoEIIP&LRBT/*" element={<ChapterOne/>}/>
        <Route path="LocalResourceBasedTechnology/*" element={<ChapterTwo/>}/>
        <Route path="LabourManagementandWorkPlan/*" element={<ChapterThree/>}/>
        <Route path="TaskRatesandProductivityNorms/*" element={<ChapterFour/>}/>
        <Route path="UnitRateAnalysisandPricingBoQforLRBWorks/*" element={<ChapterFive/>}/>
        <Route path="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer/*" element={<ChapterSix/>}/>
        <Route path="ContractAdministration&ParticularConditions/*" element={<ChapterSeven/>}/>
        <Route path="final-quiz/*" element={<ChapterEight/>}/>
        
        </Route>
         </Route>
      </Routes>
     </>
  );
}

export default App;
