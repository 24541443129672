import RedPolyList from "../red-poly-list/red-poly-list.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const ESSFArticlesintheContractTermsConditions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
            updateChapterss(5,2,user.uid,0);
        getUser();
        navigate("../WhatisDecentWork")
    }
    const list = [ 
        {
            id:0,
            text:"The use of local resources (labour, material, services, etc.)"
        },
        {
            id:1,
            text:"The use of labor intensity approach"
        },
        {
            id:2,
            text:"Prohibition of forced labour"
        },
        {
            id:3,
            text:"Equal treatment & equality in opportunities"
        },
        {
            id:4,
            text:"Prohibition of employment of children"
        },
        {
            id:5,
            text:"Provision of accident insurance"
        },
        {
            id:6,
            text:"Payment of wages on time and in full"
        },
        {
            id:7,
            text:"Targets (Lebanese, Syrian, Women and PWD)"
        },
        {
            id:8,
            text:"Decent working hours"
        },
        {
            id:9,
            text:"Occupation Safety and Health & Environmental Safety"
        },
        {
            id:10,
            text:"Zero tolerance policy on sexual harassment, exploitation and abuse"
        },
        {
            id:11,
            text:"Abide to the Sustainable Procurement Principles the ILO is adopting"
        },
        {
            id:12,
            link2:"http://documents.worldbank.org",
            link:"http://documents.worldbank.org/curated/en/157871484635724258/Environmental-health-and-safety-general-guidelines",
            text:"Particular Conditions Clause 4.1.13 Environmental, Safey and Health in addition to the world bank Environmental, Health and Safety General Guidelines"
        },
        {
            id:13,
            text:"EIIP Lebanon Social and Environmental Safeguard Framework"
        },
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="ESSF Articles in the Contract Terms & Conditions "
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="ESSFArticlesintheContractTermsConditions"
            prevLink="LegalContext"
            nextLink="WhatisDecentWork"
            chapterNumber='6'
            />
        <h1>ESSF Articles in the Contract Terms & Conditions</h1>
        <p>The ESSF document, with all its articles is contractually binding, given that it is a significant annex under the contract signed by each and every contractor. Thus, all contractors/implementing partners must familiarize themselves with the details of the ESSF to ensure highest compliance during implementation.</p>
        <p>Examples of articles present in the contract (but not limited to):</p>
        <RedPolyList list={list} />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}


export default ESSFArticlesintheContractTermsConditions;