import { useState } from "react";
import { useSelector } from "react-redux";
import { quizAnswer } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { selectCurrentUser,selectUserInfo } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { updateChapters ,startChapters, updateGrade } from "../../utils/firebase.utils";
import './attempt.styles.scss';
import { useEffect } from "react";

const Attempt = () => {
    const navigate = useNavigate();
    const [score, setScore] = useState(0);
    const [hideButton,setHideButton] = useState(false);
    const [showScore, setShowScore] =useState(false);
    const [showHints, setShowHints] = useState(false);
    const userInfo = useSelector(selectUserInfo)
    const questions = [
        {
        id: 0,
        question : "EIIP is implemented under which UN agency?",
        answer :"",
        score: 20,
        checked: false,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint:false,
        options: [
            {id:0, text:"WHO",checked:false },
            {id:1, text:"ILO",checked:false},
            {id:2, text:"UNICEF",checked:false},
            ]
    },
    {
        id:1,
        question : "Who is funding the EIIP in Lebanon?",
        answer:"",
        score:20,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        options: [
            {id:0, text:"Germany",checked:false },
            {id:1, text:"Japan",checked:false },
            {id:2, text:"Germany through the German Development Bank KfW",checked:false },
            ]
    },
    {
        id:2,
        question : "Being implemented under the ILO, what is EIIP’s comparative advantage?",
        answer:"",
        score:20,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        options: [
            {id:0, text:"Promoting cash for work and human rights",checked:false },
            {id:1, text:"Mainstreaming decent working conditions and increasing employability of workers through capacity building",checked:false },
            {id:2, text:"Employing workers from the most vulnerable communities",checked:false },
            ]
    },
    {
        id:3,
        question : "What is the most important element under the EIIP strategy?",
        answer:"",
        score:20,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        options: [
            {id:0, text:"Local Resource Based Technology",checked:false },
            {id:1, text:"Decent Work Principles",checked:false },
            {id:2, text:"Engagement of the Private sector",checked:false },
            {id:3, text:"Capacity building to workers",checked:false },
            {id:4, text:"Effective participation of Women and Persons with Disabilities",checked:false },
            {id:5, text:"All of the Above",checked:false },
            {id:6, text:"None of the above",checked:false },
    ]
    },
    {
        id:4,
        question : "What is meant by Local Resource Based Technology?",
        answer:"",
        score:20,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        options: [
            {id:0, text:"Work methods and technologies where the use of local resources, is favoured and optimised, without compromising the quality",checked:false },
            {id:1, text:"Work methods and technologies where the use of local resources, is favoured and optimised, regardless of the quality",checked:false },
            {id:2, text:"Utilizing local resources, and work stops if local resources are not available.",checked:false },
            ]
    },
        ]
    const [answers , setAnswers] = useState(questions);

    const handleChange = async (id,answer,idd) =>  {
        await setAnswers(questions => 
            questions.map(obj => {
                if (obj.id === id){
                   obj.options.map((objj) => {
                    if(objj.id === idd){
                        objj.checked = true
                    }else{
                        objj.checked = false
                    }
                   })
                    return {...obj, answer:answer  }
                }
               
                return obj;
                
            })
            )
     }
     
     
    
     const dispatch = useDispatch();
     const user = useSelector(selectCurrentUser)
     const getUserr = async () => {
         const userr = await getUsers(user.uid);
         dispatch(setUserInfo(userr));
       }
         const handleUpdates = () => {
         updateChapters(0,7,user.uid);
         getUserr();
     }
   
    const getUser = async () => {
        const quizTitle = "Intro to EIIP & LRBT";
        const userr = await quizAnswer(quizTitle);
        const a3 = answers.map((t1) => ({ ...t1, ...userr.find((t2) => t2.id === t1.id) }));
        a3.map( async (obj)  =>  {
           const od = obj.id;
            if(obj.answer === obj.trueAnswer){
                
           await setAnswers(questions => 
                    questions.map(obj => {
                        if (obj.id === od){
                            return {...obj, showHint:true}
                        }
                    return obj;
                        
                    })
                    )
                    setScore(score => score + obj.score);
        
          } else if(obj.answer === "") {
                setAnswers(questions => 
                    questions.map(obj => {
                        if (obj.id === od){
                            return {...obj, showHint:false,hint:"you didnt select any option"}
                        }
                    return obj;
                        
                    })
                    )
            }
            else{
                setAnswers(questions => 
                    questions.map(obj => {
                        if (obj.id === od){
                            return {...obj, showHint:false}
                        }
                    return obj;
                }))}
               
               
            setShowScore(true);
            setShowHints(true);
            
        })
        
      }
    const handleSubmit = async (e) => {
        e.preventDefault();
      await   getUser();
      setHideButton(true)
         window.scrollTo(0, 0);
    
      
    }
    

    useEffect( () => {
        if(score==0){
        return
        }else{
            updateGrade(user.uid,score,0)
            getUserr();
        }
    
        if (score >= 80 ) {
           
            if(userInfo.chapters[0].status === "completed"){
                return
            }else{
                handleUpdates();

            try{

                startChapters(0,1,user.uid);
            }catch(error) {
                
            }
            }
            
         getUserr();
        }
    },[score])
    
    const resetQuiz = () => {
        setShowScore(false);
        setShowHints(false);
        setScore(0);
        setHideButton(false)
        setAnswers(questions => 
            questions.map(obj => {
                obj.options.map((item) => {
                    item.checked = false
                })
                 return {...obj, answer:""}
            }
            
            )
            
            )
           
    }       
  
    return (
        <div>
            
            {
               showScore ?
                <div className={ score >= 80 ? "scoreBox passed" : "scoreBox failed"}>
                  <h2>{score >= 80 ? "Congratulations!  You passed!" : "Try again once you are ready"}</h2>  
                    <br />
                    <div> Grade received : <b> <span>{(Math.round(score * 100) / 100).toFixed(2)}%</span> </b> </div>
                     
                    To pass 80% or higher
                    {score >= 80 ? <button onClick={() => navigate('../../LocalResourceBasedTechnology/lrbt-video')}>Go to next item</button> :  <button onClick={resetQuiz}>Try Again!</button>}
 
                </div> : null
            }
            
            
           
            <form className="attempt" onSubmit={!hideButton ? handleSubmit : null}>
                {answers.map((item) => 
                <div key={item.id}>
                <h3>{item.question}</h3>
                
                {item.options.map((option) => 
                <div key={option.id} className="input-att">

                <input  checked={option.checked}  onChange={() => handleChange(item.id,option.text,option.id)} type="radio" name={item.question} id={option.text} value={option.answer}  /><label for={option.text}>{option.text}</label><br />
               
                </div>
                )}
                {showHints ? 
                <div>
                    {item.showHint ? <div className="correct"><h4>Correct</h4></div> : 
                                     <div className="incorrect"><h4>Incorrect</h4> </div>}
                </div> 
                : null
                }
                
                </div>
               
                )}
                {!hideButton ?  <button type="submit">Submit Answers</button> : null}
               
            </form>
        </div>
    )
}

export default Attempt;