import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const IncentiveschemesforLRBworks1 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(3,9,user.uid);
        getUser();
        navigate("../quiz")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Task Rates and Productivity Norms" 
            subTitle="Incentive schemes for LRB works"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="IncentiveschemesforLRBworks1"
            prevLink="Examples:Applicationoftaskworkandpieceworksystem"
            nextLink="quiz"
            chapterNumber='4'
            />
        <h1>Incentive schemes for LRB works</h1>
        <p>Daily paid activities should be the last resort, and applied only when task based activities are impossible.</p>
        <img src={require('../../assets/lrb-works-1-2.jpg')} className="boq-ch3-img" alt=""  />
        <img src={require('../../assets/lrb-works-2-2.jpg')} className="boq-ch3-img" alt=""  />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default IncentiveschemesforLRBworks1;