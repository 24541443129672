import {ReactComponent as RedPoly} from '../../assets/polygon.svg';
import './importance-work-plan.styles.scss';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const ImportanceofawelldevelopedWorkPlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(2,3,user.uid);
        getUser();
        navigate("../exercice-1")
    }
    const list1 = [
        {
            id:0,
            text:"Allows the Contractor to plan required inputs and resources (required labour-force, equipment, materials)"
        },
        {
            id:1,
            text:"Important management tool for Contractor to enable them to monitor the progress and take corrective action if delays happen"
        },
        {
            id:2,
            text:"Helps the Contractor to complete the work in time and at the least cost (i.e. maintaining a healthy profit margin)"
        },
        {
            id:3,
            text:"During work execution, the work plan is used for preparing daily site plan and weekly plan"
        },
    ]
    const list2 = [
        {
            id:0,
            text:"Compliance with minimum requirements, e.g. level of detail, timing and sequencing of activities."
        },
        {
            id:1,
            text:"Quality and practicality of the Work Plan (including resource plan, ie labour and equipment plan)"
        },
        {
            id:2,
            text:"Whether the Work Plan clearly demonstrates how the work and employment targets will be achieved."
        }
    ]
    
    return (
        <>
        <BreadCrumb 
            chapterTitle="Labour Management and Work Plan" 
            subTitle="Importance of a well-developed Work Plan"
            chapterLink="LocalResourceBasedTechnology"
            subLink="ImportanceofawelldevelopedWorkPlan"
            prevLink="LogicalsequencingofLRBactivities"
            nextLink="exercice-1"
            chapterNumber='3'
            />
        <h1>Importance of a well-developed Work Plan</h1>
        <h3><RedPoly className='svg-poly'/> A well-developed, comprehensive and realistic work plan is very important for a number of reasons: </h3>
        {
            list1.map((item) => {return (
                <div key={item.id} className='work-plan-box'>
                    <h4>{item.text}</h4>
                </div>
            )})
        }
        <h3><RedPoly className='svg-poly'/> When evaluating bids, ILO will assess</h3>
        {
            list2.map((item) => {return (
                <div key={item.id} className='work-plan-box'>
                    <h4>{item.text}</h4>
                </div>
            )})
        }
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default ImportanceofawelldevelopedWorkPlan;