import './lrbt-work.styles.scss';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const LrbWorkMethods = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(1,9,user.uid);
        getUser();
        navigate("../EmploymentIntensiveInfrastructureProgrammeinLebanon")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Local Resource Based Technology" 
            subTitle="Local Resource-based (LRB) Work Methods"
            chapterLink="Socio-economic advantages of LRBT"
            subLink="LocalResource-based(LRB)WorkMethod"
            prevLink="exercice-2"
            nextLink="EmploymentIntensiveInfrastructureProgrammeinLebanon"
            chapterNumber="2"
            />
        <h1>Local Resource-Based (LRB) Work Methods</h1>
        <p>Different types of activities can be aided by specific categories of light equipment, but these must be approved by the supervising EIIP engineers before any use
</p>
<div className='tables-holder'>
<table className="work-table">
                <tr>
                    <th style={{width:"25%"}}>Type of infrastructure</th>
                    <th style={{width:"50%"}}>Activities suitable for LRBT</th>
                    <th style={{width:"25%"}}>Supporting light equipment</th>
                </tr>
                <tr className='tr-1'>
                    <td>
                        <h4>- Agricultural roads</h4>
                        <h4>- Irrigation canals</h4>
                        <h4>- Road maintenance</h4>
                    </td>
                    <td>
                        <h4>- Bush and grass clearing</h4>
                        <h4>- Excavation work(side drain or canal)</h4>
                        <h4>- Filling embankment and levelling</h4>
                        <h4>- Spreading road sub-base and base course</h4>
                        <h4>- Concrete work for drainage structure</h4>
                    </td>
                    <td>
                       <h4>- Truck</h4>
                       <h4>- Roller</h4>
                       <h4>- Water truck</h4>
                        </td>
                </tr>

                 <tr style={{height:"500px"}}>
                    <td className='tr-2'><h4>Structural works</h4></td>
                    <td>
                    <tr className='db-t'>
                    <td style={{height:"250px",display:"flex",alignItems:"center",justifyContent:"center"}} className='tr-2'>
                 
                 <div> <h4>- Excavation for foundation</h4> 
                <h4>- Mixing and placing concrete work</h4>
                <h4>- Cement stone masonry work (retaining walls, lined drains for agricultural roads)</h4></div>
               
                    
                   
                   
                    </td>
                    <td style={{height:"260px", marginBottom:0,display:"flex",alignItems:"center",justifyContent:"center"}} className='tr-2'>
                   <div>
                   <h4>- Dry stone masonry work</h4>
                    <h4>- Gabion work (slope and erosion protection)</h4>
                    <h4>- Bending, fixing and placing steel bars</h4>
                    <h4>- Cutting, placing and fixing formwork</h4>
                    <h4>- Back filling and compaction of the structure</h4>
                   </div>
                    
                    </td>
                    </tr>
                    </td>
                   
                    <td>
                        <tr className='db-t'>
                            <td style={{height:"250px",display:"flex",alignItems:"center",justifyContent:"center"}} className='tr-2'>
                                <div>
                                <h4>- Concrete mixer</h4>
                                <h4>- Vibrator</h4>
                                </div>
                                
                            </td>

                            <td style={{height:"260px", marginBottom:0,display:"flex",alignItems:"center",flexDirection:"column",justifyContent:"center"}} className='tr-2'>
                                 <h4>- Hand Temper</h4>
                            </td>
                        </tr>
                    </td>
                </tr>
                 <tr style={{height:"300px"}} >
                    <td  className='tr-3'>
                        <h4>- Public infrastructure maintenance</h4>
                    </td>
                    <td>
                    <tr className='db-t'>
                        <td style={{height:"150px",display:"flex",alignItems:"center",justifyContent:"center"}} className='tr-3'>
                            <h4>- Road routine maintenance ( bush and grass, shoulder and slope repair, patching pothole, repair surface…)</h4>
                        </td>
                        <td style={{height:"160px",marginBottom:0,display:"flex",alignItems:"center",justifyContent:"center"}} className='tr-3'>
                            <div><h4>- River bed repair </h4>
                            <h4>- Irrigation canal maintenance </h4>
                            <h4>- Building repair  etc </h4></div>
                            
                        </td>
                        
                    </tr>
                    </td>
                  
                    <tr className='db-t'>
                    <td style={{height:"150px",display:"flex",alignItems:"center",justifyContent:"center"}} className='tr-3'>
                           <div>
                           <h4>- Truck </h4>
                            <h4>- Pedestrian roller </h4>
                           </div>
                           
                            
                        </td>
                        <td style={{height:"160px",marginBottom:0 ,display:"flex",alignItems:"center",justifyContent:"center"}} className='tr-3'>
                            <div>
                            <h4>- Truck </h4>
                            <h4>- Concrete Mixer </h4>
                            </div>
                         
                            
                        </td>
                    </tr>
                    
                </tr>
                <tr className='tr-4'>
                    <td>
                        <h4 style={{color:"white"}}>- Bio-engineering works</h4>
                    </td>
                    <td  style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <div>
                        <h4 style={{color:"white"}}>- Slope trimming</h4>
                        <h4 style={{color:"white"}}>- Grass, tree and shrub planting</h4>
                        <h4 style={{color:"white"}}>- Plantation on slopes</h4>
                        <h4 style={{color:"white"}}>- Sub soil drain construction</h4>
                        <h4 style={{color:"white"}}>- Live check-dam</h4>
                        <h4 style={{color:"white"}}>- Stone soling</h4>
                        </div>
                        
                        </td>
                    <td >
                        <h4 style={{color:"white"}}>- Truck</h4>
                    </td>
                </tr>
                
            </table>
</div>

            <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default LrbWorkMethods;