import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const DecentWorkConditions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,2,user.uid,1);
        getUser();
        navigate("../SSOVideo")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Decent Work Conditions"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="DecentWorkConditions"
            prevLink="QuizQuestion"
            nextLink="SSOVideo"
            chapterNumber='6'
            />
        <h1>Decent Work Condtions</h1>
        <ShowText
            title="Fair & Transparent Recruitment"
            text="Contractor should use diversified outreach channels, and should have a clear selection criteria that is inclusive for the most vulnerable population. The advertisement, selection and recruitment procedure should be inclusive and non-discriminatory (considering time, location, language, etc.)"
            color="#3264C8"
            textColor="white"
/>
<ShowText
            title="Service Contracts"
            text="All workers must read and sign a service contract before they start working on the project. The contract must be in a Language understood by the worker (usually Arabic). For workers that do not read and write the contract must be read to them by someone they trust, the contractor or the Social Safeguard Officer (Based on their preference). The contract must include details on all work conditions and terms. Contract must include details on payment amount and schedule."
            color="#3264C8"
            textColor="white"
/>
<ShowText
            title="Legal Identification"
            text="All workers that are to be employed under the EIIP must have legal identification documents, and it is the contractor’s responsibility to verify the availability of the same before they start working on the project."
            color="#3264C8"
            textColor="white"
/>
<ShowText
            title="Payment Procedures"
            text="Payments to be settled on a weekly/Bi-weekly payments, through a financial institution. The payment schedule should be agreed on with the workers before work is initiated; any changes should be discussed and agreed upon with workers ahead of time. Payments are to be made to the worker directly, no one can get paid on behalf of anyone else."
            color="#3264C8"
            textColor="white"
/>
<ShowText
            title="Transportation"
            text="Transportation should be compensated to all workers equally, and to be considered as an additional fee, and NOT to be deducted from salaries under any circumstance."
            color="#3264C8"
            textColor="white"
/>
<ShowText
            title="Working Hours & Rest Times"
            text="In compliance with the Labour Law, daily working hours should consist of 7 to 8 hours maximum with 1 hour break, and one full day of rest per week. To ensure productivity, the Task based work approach is to be promoted on EIIP projects."
            color="#3264C8"
            textColor="white"
/>
<ShowText
            title="Productive Work/ Training & Development"
            text="Work that is done on site should be productive and useful. On-the-job training, coaching, and skills development should be part of the contractor’s plan to increase the employability of the workers (from unskilled to skilled)."
            color="#3264C8"
            textColor="white"
/>
<ShowText
            title="Elimination of all kinds of Child Labour"
            text="No Person under the age of 18 should be present on site no matter the circumstances (worker, visitor, family member, neighbouring community, etc.)"
            color="#3264C8"
            textColor="white"
/>
<ShowText
            title="Elimination of Forced Labour"
            text="All workers should be well informed about the work and willing to work voluntarily on the project (regardless of circumstances). Contractor’s team on site must be alert to any case of forced labour and must be reported immediately to Social Safeguard Officer to take necessary measures."
            color="#3264C8"
            textColor="white"
/>
<ShowText
            title="Complaint Mechanism"
            text="Proper, confidential and various grievance mechanism is put in place. Proper follow-up and measures are conducted by the EIIP team."
            color="#3264C8"
            textColor="white"
/>
<ShowText
            title="Workers’ insurance"
            text="All workers onsite should be covered by a recognized insurance covering all work accidents, relevant treatments, and compensation for lost working days."
            color="#3264C8"
            textColor="white"
/>
<ShowText
            title="Occupational Safety & Health (OSH)"
            text=" Contractors are responsible for putting in place proper OSH measures and assigning an OSH focal point to the project site. An OSH plan should be submitted with the bidding documents, and all OSH measures should be put in place prior to the initiation of work. The OSH plan is to be reviewed and updated regularly as and when needed."
            color="#3264C8"
            textColor="white"
/>
<button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default DecentWorkConditions;