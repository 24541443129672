import RedPolyList from '../red-poly-list/red-poly-list.component';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const SiteOrganization = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(2,0,user.uid);
        getUser();
        navigate("../RecruitmentofLabour")
    }
    const details = [
        {
            id: 0,
            text : "This Site organization Chart should be submitted in the tender and must present the dedicated personnel that will be responsible on site for this specific project/lot."
        },
        {
            id: 1,
            text : "Group leader can be employed as casual skilled worker, and should be trained/coached by the contractor."
        },
        {
            id: 2,
            text : "Supervisors and engineer should attend the LRB training before commencing the works."
        }
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Labour Management and Work Plan" 
            subTitle="Typical site organization for LRB works"
            chapterLink="LocalResourceBasedTechnology"
            subLink="TypicalsiteorganizationforLRBworks"
            prevLink="../LocalResourceBasedTechnology/quiz"
            nextLink="RecruitmentofLabour"
            chapterNumber='3'
            />
        <h1>Typical site organization for LRB works</h1>
       
<img src={require('../../assets/site-organization-2.jpg')} className="site-organization-img" alt="" srcset="" />
        <h1>Note:</h1>
        <RedPolyList  list={details}/>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default SiteOrganization;