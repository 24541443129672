import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const SampleBoq = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(2,7,user.uid);
        getUser();
        navigate("../PreparingaWorkPlan")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Labour Management and Work Plan" 
            subTitle="Sample of BoQ"
            chapterLink="LocalResourceBasedTechnology"
            subLink="SampleofaBoQ"
            prevLink="WorkPlan"
            nextLink="PreparingaWorkPlan"
            chapterNumber='3'
            />
        <h1>Sample of BoQ</h1>
        <p>Below is a non-indicative sample of BoQ</p>
        <img src={require('../../assets/sample-boq-2.jpg')} className="boq-ch3-img"  alt="" />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}


export default SampleBoq;