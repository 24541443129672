
import { Routes , Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo,selectCurrentUser } from "../../store/user/user.selector";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ChaptersNav from "../chapters-nav/chapters-nav.component";
import SiteOrganization from "./site-organization.component";
import RecruitmentOfLabour from "./recruitment-labour.component";
import LogicalSequencing from "./logical-sequencing.component";
import ImportanceofawelldevelopedWorkPlan from "./importance-work-plan.component";
import ExerciceWorkPlan from "./exercice.component";
import WorkPlanConsiderations from "./work-plan-consideration.component";
import WorkPlan from "./work-plan.component";
import SampleBoq from "./sample-boq.component";
import PreparingaWorkPlan from "./preparing-work-plan.component";
import Overallplanningreportingandmonitoringsystem from "./overall-planning.component";
import QuizTemplate from "../quiz-template/quiz-template.component";
import Attempt from "./attempt";
const ChapterThree = () => {
    const userInfo = useSelector(selectUserInfo);
    const user = useSelector(selectCurrentUser)
    const questions = userInfo.chapters[2].questions;
    const navigate = useNavigate();
    useEffect(() => {
        if(userInfo.chapters[1].grade < 70){
            navigate("../../chapters")
        }
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[user])
    const details = [
        {
            "id": 0,
            "title":"Typical site organization for LRB works",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"TypicalsiteorganizationforLRBworks"
        },
        {
            "id": 1,
        "title":"Recruitment of Labour",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"RecruitmentofLabour"
        },
        {
            "id": 2,
        "title":"Logical sequencing of LRB activities",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"LogicalsequencingofLRBactivities"
        },{
            "id": 3,
            "title":"Importance of a well-developed Work Plan",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"ImportanceofawelldevelopedWorkPlan"
        },{
            "id": 4,
            "title":"Exercise",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"exercice-1"
        },{
            "id": 5,
            "title":"Work Plan Considerations",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"WorkPlanConsiderations"
        },{
            "id": 6,
            "title":"Work Plan:",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"WorkPlan"
        },{
            "id": 7,
            "title":"Sample of a BoQ",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"SampleofaBoQ"
        },
        {
            "id": 8,
            "title":"Preparing a Work Plan",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"PreparingaWorkPlan"
        },
        {
            "id": 9,
            "title":"Overall planning, reporting and monitoring system",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"Overallplanningreportingandmonitoringsystem"
        },
        {
            "id": 10,
            "title":"Quiz",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"quiz"
        }
    
    ]
    const a3 = details.map((t1) => ({ ...t1, ...questions.find((t2) => t2.id === t1.id) }));
    window.scrollTo(0, 0);
    return (
        <>
        <div className="chapter-wrapper">
           <ChaptersNav title="Labour Management and Work Plan " navLinks={a3} chapterTitle="LabourManagementandWorkPlan"/>
           <div className="chapters-lessons">
          <Routes>
          <Route index path="TypicalsiteorganizationforLRBworks" element={<SiteOrganization/>}/>
          <Route  path="RecruitmentofLabour" element={<RecruitmentOfLabour/>}/>
          <Route  path="LogicalsequencingofLRBactivities" element={<LogicalSequencing/>}/>
          <Route  path="ImportanceofawelldevelopedWorkPlan" element={<ImportanceofawelldevelopedWorkPlan/>}/>
          <Route  path="exercice-1" element={<ExerciceWorkPlan/>}/>
          <Route  path="WorkPlanConsiderations" element={<WorkPlanConsiderations/>}/>
          <Route  path="WorkPlan" element={<WorkPlan/>}/>
          <Route  path="SampleofaBoQ" element={<SampleBoq/>}/>
          <Route  path="PreparingaWorkPlan" element={<PreparingaWorkPlan/>}/>
          <Route  path="Overallplanningreportingandmonitoringsystem" element={<Overallplanningreportingandmonitoringsystem/>}/>
          <Route path="quiz" element={<QuizTemplate chapterNumber='3' quizLink='attempt' toPass="83.33%"
           chapterTitle="Labour Management and Work Plan" 
           subTitle="Quiz"
           chapterLink="LocalResourceBasedTechnology"
           subLink="quiz"
           chapterGrade={(Math.round(userInfo.chapters[2].grade * 100) / 100).toFixed(2)}
           prevLink="Overallplanningreportingandmonitoringsystem"
           nextLink="../TaskRatesandProductivityNorms/LabourmanagementforLRBworks"
          />}/>
          <Route path="attempt" element={<Attempt/>}/>
       </Routes>
           </div>
         
        </div>
       
         
        </>
    )
}


export default ChapterThree;