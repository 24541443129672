import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import MiniCircles from "../mini-circles/mini-circles.component";
const ScreeningandAnalysisSocialFeasibility = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,4,user.uid,7);
        getUser();
        navigate("../ScreeningandAnalysisEnvironmentalScreening")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Screening and Analysis- Social Feasibility"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="ScreeningandAnalysisSocialFeasibility"
            prevLink="ScreeningandAnalysis"
            nextLink="ScreeningandAnalysisEnvironmentalScreening"
            chapterNumber='6'
            />
        <h1>Social and Environmental Safeguards - Screening and Analysis- Social Feasibility</h1>
        <p>Social Feasibility Analysis: The initial social feasibility is conducted by the EIIP team in consultation with stakeholders 
 to identify potential social risks that relate to existing tensions within the community, community responsiveness, labour availability and land issues. 
</p>
<p>Projects that have high risk of increasing tension between refugees and the host communities, or those whereby the labour interest of the community is less than 50% of the estimated required workforce will not be included.
</p>
<p>Once the project is selected for the phase of detailed design, EIIP team together with proper stakeholders will work on identifying potential social issues or concerns and their respective mitigation measures. 
</p>
<p>Proper design measures will be identified to avoid negative effects to sensitive areas or agricultural lands. 
</p>
<p>
A “Do No Harm” approach shall be adopted during the project implementation phase to ensure that no conflict sensitivity issues affect the goals to be reached.
</p>
<p>A “Community-Based” approach shall also be adopted while designing and implementing the funded projects. The importance of a community-based participatory approach is now generally recognised in the field of employability and infrastructure, whereby stakeholders have realised the significance of supporting the most vulnerable actors in the locations where the projects are being implemented 
</p>
<MiniCircles text="Initial social feasibility" color1="#3264C8" color2="#BEDCFA" width="20%" height="20%" />
        <MiniCircles text="Elimination of potential high-risk projects" color1="#AB3B77" color2="#E0B6CC" width="30%" height="30%" />
        <MiniCircles text="Identification of social issues and mitigation measures" color1="#FAB428" color2="#FFEBAB" width="50%" height="50%" />
        <MiniCircles text="Consultations with stakeholders-project owners" color1="#69E4E4" color2="#D2FBFB" width="60%" height="60%" />
  
       <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default ScreeningandAnalysisSocialFeasibility;