import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const IncentiveschemesforLRBworks = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(3,1,user.uid);
        getUser();
        navigate("../exercise-1")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Task Rates and Productivity Norms" 
            subTitle="Incentive schemes for LRB works"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="IncentiveschemesforLRBworks"
            prevLink="LabourmanagementforLRBworks"
            nextLink="exercise-1"
            chapterNumber='4'
            />
        <h1>Incentive schemes for LRB works</h1>
        <p>Incentive schemes are meant to enhance workers’ productivity and ensure completion of works in a timely manner, and as per project’s plan</p>
        <img src={require('../../assets/intensive-scheme-2.png')} className="boq-ch4-img" alt=""  />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}


export default IncentiveschemesforLRBworks;