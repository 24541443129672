import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const PromotingInclusiveParticipation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,2,user.uid,5);
        getUser();
        navigate("../WhyaGrievanceMechanism")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Promoting Inclusive Participation"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="PromotingInclusiveParticipation"
            prevLink="Whatcanbedone"
            nextLink="WhyaGrievanceMechanism"
            chapterNumber='6'
            />
        <h1>Promoting Inclusive Participation</h1>
        <ShowText
            title="A strong Communication Campaign"
            text="The campaign should be inclusive for women, and for persons with disabilities taking into account the advertisement location, application procedure, and outreach outlets used"
            color="#AB3B77"
            textColor="white"
        />
        <ShowText
            title="Advertise Jobs in frequently accessible places to all"
            text="Make sure that advertisements are posted in various locations, public spaces mostly visited by women and/or persons with disabilities, using appropriate languages, etc."
            color="#CB85AA"
            textColor="#230050"
        />
         <ShowText
            title="Outreach to Organizations for Persons with Disabilities (OPDs)"
            text="To ensure effective inclusion of PWDs make sure you outreach for OPDs for proper guidance and effective outreach"
            color="#CB85AA"
            textColor="#230050"
            
        />
        <ShowText
            title="Application requirements are inclusive"
            text="Make sure that application procedure is inclusive and accessible to all in terms of location, language, timing, duration, and personnel filling out the application"
            color="#CB85AA"
            textColor="#230050"
        />
        <ShowText
            title="Inclusive Working conditions"
            textColor="#230050"
            color="#FAB428"
        />
        <ShowText
            title="Equal Pay for Equal Work"
            text=" All workers must be paid equally for similar work conducted. Discrimination on this regards is not tolerated under the EIIP."
            color="#FFEBAB"
            textColor="#230050"
        />
        <ShowText
            title="Close Working Sites"
            text="Close and safe working sites are to be provided"
            color="#FFEBAB"
            textColor="#230050"
        />
        <ShowText
            title="Separate working places"
            text="Each area has its own specificities and requirements for enhancing women’s participation, thus it would be good to assess the requirements and situation in each area and address the challenges accordingly. In some cases it is more effective to have separate working places for men and women in others it is not necessary."
            color="#FFEBAB"
            textColor="#230050"
        />
        <ShowText
            title="Piece-Work in planning tasks"
            text="Introducing piece-work for women and workers that require flexible work arrangements is promoted under the EIIP. This will enhance the productivity of the workers, while ensuring that they can still accommodate to other commitments they might have. This methodology is encouraged especially in circumstances where workers otherwise may not be able to be part of the work."
            color="#FFEBAB"
            textColor="#230050"
        />
        <ShowText
            title="Women Team leaders"
            text="promote the participation of women team leaders on EIIP project sites (team leaders, supervisors, site engineers, etc.) this will pave the way and motivate women to work on EIIP project sites."
            color="#FFEBAB"
            textColor="#230050"
        />
        <ShowText
            title="Close Working Sites"
            text="On EIIP minimum quotas are implemented (they change from phase to the other), the quotas are important to monitor progress, however, EIIP team will monitor and asses the effective participation on sites rather only numbers achieved."
            color="#FFEBAB"
            textColor="#230050"
        />
        <ShowText
            title="Inclusive Promotion"
            text="Promotion should be inclusive to all workers equally, and should not discriminate between workers. There should be specific, clear and transparent criteria for the promotion that is communicated to everyone equally."
            color="#FFEBAB"
            textColor="#230050"
        />
         <ShowText
            title="Childcare when possible"
            text="EIIP, when needed, should provide a safe childcare facility for working women. This can be either through an already established entity (SDC, CDC, NGO) or through skilled and well-trained women."
            color="#FFEBAB"
            textColor="#230050"
        />
         <ShowText
            title="On-site Capacity building"
            text="Given that women might not have high skills in the field of construction, it is the responsibility of the contractor to ensure that on-site trainings are provided to all equally to support that they have the needed skills to efficiently and safely perform their tasks, as well as increasing their employability for future work opportunities beyond the EIIP."
            color="#FFEBAB"
            textColor="#230050"
        />
        <ShowText
            title="Safe Working Conditions"
            textColor="#230050"
            color="#69E4E4"
        />
        <ShowText
            title="Sexual harassment and Exploitation"
            text="ILO through all its programmes and projects adopt a 0 tolerance policy regarding sexual harassment and exploitation. It is the responsibility of the contractor to make sure that all work sites are safe and free of harassment and exploitation. Awareness materials must at all times be present on the site locations."
            color="#D2FBFB"
            textColor="#230050"
        />
        <ShowText
            title="Complaint Mechanism"
            text="The ILO’s Social Safeguard Officers are responsible for mobilizing the complaint mechanism, through a confidential and prompt approach (as shown in the following chapter). The complaint mechanism does not only target workers on site, but also contractor’s team and community members within the vicinity of the project"
            color="#D2FBFB"
            textColor="#230050"
        />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default PromotingInclusiveParticipation;