import { useSelector } from "react-redux";
import { selectUserInfo } from "../../store/user/user.selector";
import ChaptersNav from "../chapters-nav/chapters-nav.component";
import { Routes , Route } from "react-router-dom";
import LrbtVideo from "./lrbt-video.component";
import LaboursVsEquipment from "./labourvsequipment.component";
import LrbtVsCfw from "./LrbtvsCfw.component";
import LocalResourceBased from "./LocalResource-based.component";
import ExerciceOne from "./exercice-one.component";
import SocioEconomic from "./socio-economic.component";
import LrbLimitations from "./lrb-limitations.component";
import ChoiceTechnology from "./choice-technology.component";
import ExerciceTwo from "./exercice-two.component";
import LrbWorkMethods from "./lrb-work.component";
import RetainingWall from "./retaining-walls.component";
import ApplyLrbt from "./apply-lrbt.component";
import Attempt from "./attempt";
import QuizTemplate from '../quiz-template/quiz-template.component';
import { useEffect } from "react";
import { selectCurrentUser } from "../../store/user/user.selector";
import { useNavigate } from "react-router-dom";
const ChapterTwo = () => {
    const userInfo = useSelector(selectUserInfo);
    const user = useSelector(selectCurrentUser)
    const questions = userInfo.chapters[1].questions;
    const navigate = useNavigate();
    useEffect(() => {
       
        if(userInfo.chapters[0].grade < 70){
            navigate("../../chapters")
        }
        if(!user){
            navigate("../../")
        }else{
            if(user.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[user]);
    window.scrollTo(0, 0);
    const details = [
        {
            "id": 0,
            "title":"LRBT Video",
        "imageUrl" : require('../../assets/video-icon.png'),
        "url" :"lrbt-video"
        },
        {
            "id": 1,
        "title":"Labour vs equipment in construction",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"Labourvsequipmentinconstruction"
        },
        {
            "id": 2,
        "title":"LRBT vs CfW",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"lrbtvscfw"
        },{
            "id": 3,
            "title":"Local Resource-Based (LRB) Work Methods",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"LocalResource-based(LRB)WorkMethods"
        },{
            "id": 4,
            "title":"Exercise 1",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"exercice-1"
        },{
            "id": 5,
            "title":"Socio-economic advantages of LRBT",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"Socio-economicadvantagesofLRBT"
        },{
            "id": 6,
            "title":"LRB (perceived) Limitations",
        "imageUrl" : require('../../assets/reading-icon.png'),
        "url" :"LRB(perceived)Limitations"
        },{
            "id": 7,
            "title":"Choice of technology – LRBT vs equipment",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"ChoiceoftechnologyLRBTvsequipment"
        },
        {
            "id": 8,
            "title":"Exercise 2",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"exercice-2"
        },
        {
            "id": 9,
            "title":"Local Resource-Based (LRB) Work Methods",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"LocalResource-based(LRB)WorkMethod"
        },
        {
            "id": 10,
            "title":"Employment Intensive Infrastructure Programme in Lebanon (EIIP), Retaining Walls (2020)",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"EmploymentIntensiveInfrastructureProgrammeinLebanon"
        },
        {
            "id": 11,
            "title":"When to apply LRB important considerations at planning stage",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"WhentoapplyLRBTimportantconsiderationsatplanningstage"
        },
        {
            "id": 12,
            "title":"Quiz",
        "imageUrl" : require('../../assets/quiz-icon.png'),
        "url" :"quiz"
        },
    
    ]
    const a3 = details.map((t1) => ({ ...t1, ...questions.find((t2) => t2.id === t1.id) })); 
    return (
        <>
        <div className="chapter-wrapper">
           <ChaptersNav title="Local Resource Based Technology" navLinks={a3} chapterTitle="LocalResourceBasedTechnology"/>
           <div className="chapters-lessons">
          <Routes>
          <Route index path="lrbt-video" element={<LrbtVideo/>}/>
          <Route path="Labourvsequipmentinconstruction" element={<LaboursVsEquipment/>}/>
          <Route path="lrbtvscfw" element={<LrbtVsCfw/>}/>
          <Route path="LocalResource-based(LRB)WorkMethods" element={<LocalResourceBased/>}/>
          <Route path="exercice-1" element={<ExerciceOne/>}/>
          <Route path="Socio-economicadvantagesofLRBT" element={<SocioEconomic/>}/>
          <Route path="LRB(perceived)Limitations" element={<LrbLimitations/>}/>
          <Route path="ChoiceoftechnologyLRBTvsequipment" element={<ChoiceTechnology/>}/>
          <Route path="exercice-2" element={<ExerciceTwo/>}/>
          <Route path="LocalResource-based(LRB)WorkMethod" element={<LrbWorkMethods/>}/>
          <Route path="EmploymentIntensiveInfrastructureProgrammeinLebanon" element={<RetainingWall/>}/>
          <Route path="WhentoapplyLRBTimportantconsiderationsatplanningstage" element={<ApplyLrbt/>}/>
          <Route path="quiz" element={<QuizTemplate chapterNumber='2' quizLink='attempt' toPass="75%"
             chapterTitle="Local Resource Based Technology" 
             subTitle="When to apply LRBT"
             chapterLink="Socio-economic advantages of LRBT"
             subLink="quiz"
             chapterGrade={userInfo.chapters[1].grade}
             prevLink="WhentoapplyLRBTimportantconsiderationsatplanningstage"
             nextLink="../LabourManagementandWorkPlan/TypicalsiteorganizationforLRBworks"
          />}/>
          <Route path="attempt" element={<Attempt/>}/>
       </Routes>
           </div>
         
        </div>
       
         
        </>
    )
}

export default ChapterTwo;