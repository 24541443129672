import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const DeterminingtheRisk = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,4,user.uid,4);
        getUser();
        navigate("../RiskManagementMatrix")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Determing the Risk"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="DeterminingtheRisk"
            prevLink="RiskAssessment"
            nextLink="RiskManagementMatrix"
            chapterNumber='6'
            />
        <h1>Determing the Risk</h1>
        <p>The risk is the chance, high or low, that somebody could be harmed by hazards, together with an indication of how serious the harm could be:
</p>
<img src={require("../../assets/determining-the-risk-2.jpg")} style={{width:"100%",marginBottom:"20px"}} alt="" srcset="" />
<p>Risk matrices can be used to quantify risk and thus assist in prioritizing actions</p>
<button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default DeterminingtheRisk;