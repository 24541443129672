import RedPolyList from "../red-poly-list/red-poly-list.component";
import {ReactComponent as RedPoly} from '../../assets/polygon.svg';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const TaskratesandProductivityPart2 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(3,5,user.uid);
        getUser();
        navigate("../exercise-3")
    }
    const details = [
        {
            id:0,
            text:"Tasks must be fair!"
        },
        {
            id:1,
            text:"A correctly set task rate should allow most workers to complete their daily task in about six hours."
        },
        {
            id:2,
            text:"The task rates should be reviewed regularly to ensure that they are fair"
        },
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Task Rates and Productivity Norms" 
            subTitle="Task rates and Productivity"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="TaskratesandProductivityPart2"
            prevLink="exercice-2"
            nextLink="exercise-3"
            chapterNumber='4'
            />
        <h1>Task rates and Productivity</h1>
        <p>It is very important to know the task rates of labour and equipment for:</p>
        <h3><RedPoly className='task-rates-poly'/>Preparing Tender:</h3>
        <ul>
            <li><p>Estimate labour cost and equipment cost</p></li>
            <li><p>Prepare Work program (Resource plan)</p></li>
        </ul>
        <h3><RedPoly className='task-rates-poly'/>Work execution:</h3>
        <ul>
            <li><p>Prepare daily, and weekly site plan</p></li>
            <li><p>Managing labour force on site</p></li>
            <li><p>Work monitoring and reporting</p></li>
            <li><p>Estimate labour cost for price negotiation with labour sub-contractors</p></li>
        </ul>
        <h3><RedPoly className='task-rates-poly'/>Labour productivity are determined by</h3>
        <ul>
            <li><p>Task rates</p></li>
            <li><p>Climatic conditions in the area of operation</p></li>
            <li><p>General physical abilities of the available labour force</p></li>
            <li><p>Nature of the works</p></li>
            <li><p>Organisational and technical challenges</p></li>
            <li><p>Quality of tools, equipment and materials</p></li>
            <li><p>Capacity of site supervisors for organising the labour force and providing on-the-job training</p></li>
        </ul>
        <h3 className="red-title">Remember</h3>
        <RedPolyList list={details} />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}
export default TaskratesandProductivityPart2;