import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const WorkPlanConsiderations = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(2,5,user.uid);
        getUser();
        navigate("../WorkPlan")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Labour Management and Work Plan" 
            subTitle="Work Plan Considerations"
            chapterLink="LocalResourceBasedTechnology"
            subLink="WorkPlanConsiderations"
            prevLink="exercice-1"
            nextLink="WorkPlan"
            chapterNumber='3'
            />
        <h1>Work Plan Consideration</h1>
        <ShowText title="Overall Time Frame" 
                    text="The maximum duration provided in the tender documents" 
                    color="#AB3B77"
                    textColor="white"
                    />
        <ShowText title="Complexity and logical sequencing of activities" 
                    color="#FB636F"/>
        <ShowText title="LRBT - number of workers and type of equipment" 
                    text="The contractor should be able to identify a reasonable number of workers needed for each activity." 
                    textColor="white"
                    color="#3264C8"/>
        <ShowText title="Productivity (of labour)" 
                    text="The number of workers identified for each activity should be able to finish the task within the estimated duration taking into consideration that workers’ ultimate productivity is 6h/day." 
                    color="#69E4E4"
                    textColor="#230050"
                    />
        <ShowText title="Materials supply lead times" 
                    text="The contractor should take into consideration the shortage of material in the country and the risks in the current situation." 
                    color="#FAB428"
                    textColor="#230050"
                    />
        <ShowText title="Financial considerations" 
                    text="The contractor should be aware of the needed financial flow knowing that workers should be paid on a biweekly basis and some suppliers are not accepting to provide materials on site unless they are paid in full." 
                    textColor="white"
                    color="#70B450"/>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}


export default WorkPlanConsiderations;