import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const PreparingaWorkPlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(2,8,user.uid);
        getUser();
        navigate("../Overallplanningreportingandmonitoringsystem")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Labour Management and Work Plan" 
            subTitle="Preparing a Work Plan"
            chapterLink="LocalResourceBasedTechnology"
            subLink="PreparingaWorkPlan"
            prevLink="SampleofaBoQ"
            nextLink="Overallplanningreportingandmonitoringsystem"
            chapterNumber='3'
            />
        <h1>Preparing a Work Plan</h1>
        <img src={require('../../assets/preparing-work-plan-2.jpg')} className="boq-ch3-img" />
        <button style={{display:"block"}}><a  href={require("../../assets/preparing-work-plan.xls")} download>Download File</a></button>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}


export default PreparingaWorkPlan