import FormInput from '../form-input/form-input.component';
import { Link , useNavigate} from 'react-router-dom';
import Button from '../button/button.component';
import { useState } from 'react';
import { signInAuthUserWithEmailAndPassword,generateResetPassword ,generateVerificationEmail} from '../../utils/firebase.utils';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/user/user.selector';
const defaultFormFields = {
   
    email:'',
    password:"",
    
}
const ResetPassword = () => {
    const [formFields ,setFormFields] = useState(defaultFormFields);
    const {email,password} =formFields;
    const [errorMessage,setErrorMessage] = useState(null);
    const user = useSelector(selectCurrentUser);
   
    const [showText,setShowText] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          await  generateResetPassword(email);
          setShowText(true)
        }catch (err){
           setErrorMessage(err)
        }
    }

    const handleChange = (event) => {
        const {name,value} = event.target;
        setFormFields({...formFields, [name]:value})
    }
   
   
    return (
        <>
        <div className="sign-in-wrapper">
            
            <div>
            <Link to='../'> <p>Go Back</p></Link>
                <h1>Forget Password ?</h1>
                <p>Enter Your Email Address to reset your password</p>
                <form onSubmit={handleSubmit}>
                    <FormInput placeholder="Please enter your email" type='email' onChange={handleChange} name="email" value={email}/>
                    <p style={{color:"red",fontWeight:"700"}}>{errorMessage}</p>
                    <div className='forgot-holder'>
                   
                     <Button children='Send Email' style={{width:'30%',marginTop:"0"}}/>
                    </div>
                    {
                        showText ?<p >Check your E-mail and follow the steps to reset your password.  </p> : null
                    }
                    
                </form>
              
            </div>
         
        </div>
       
        </>
    )
}

export default ResetPassword;