import './circle-show.styles.scss'

import { gsap } from "gsap";
import { useState, useLayoutEffect,useRef,useEffect } from "react";
import {ReactComponent as CloseCircle} from '../../assets/close-cir.svg';
const CircleShow = ({text,color,circleId,mainCircleId,boxId,textId}) => {
   
   
   const tl = useRef();
   const app = useRef();
   const boxRef = useRef();
   const textRef = useRef();
   const textBoxRef = useRef();
   const ewr = mainCircleId;
   const [windowSize, setWindowSize] = useState(getWindowSize());

   function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

   useEffect(() => {
     function handleWindowResize() {
      handleFeww(boxRef.current,textBoxRef.current,textRef.current)
     }
 
     window.addEventListener('resize', handleWindowResize);
     
     
   }, []);



    const handleFew = (target,target2,target3) => {
        const ctx = gsap.context(() => {
            const element = document.getElementById(circleId);
                const ose = element.getBoundingClientRect();
                if (windowSize.innerWidth < 1025){
                    tl.current = gsap.timeline()
                    .to(target,{position:"fixed",x:ose.left,y:ose.top,display:"block",opacity:1,duration:0,ease:"ease-in"})
                    
                    .to(target ,{width:"200vmax",height:"200vmax",x:"-500px",y:-300,duration:0.6,ease:"ease-in"})
                    .to(target2,{display:"flex",duration:0,ease:"ease-in"})
                    .to(target2,{opacity:1,duration:0.3,ease:"ease-in"})
                    .to(target2,{y:0,opacity:1,duration:0.7,ease:"ease-in",delay:-0.3})
                } else
               
                if (windowSize.innerWidth < 1200){
                    tl.current = gsap.timeline()
                    .to(target,{position:"fixed",x:ose.left,y:ose.top,display:"block",opacity:1,duration:0,ease:"ease-in"})
                    
                    .to(target ,{width:"100vmax",height:"100vmax",x:"500px",y:-100,duration:0.6,ease:"ease-in"})
                    .to(target2,{display:"flex",duration:0,ease:"ease-in"})
                    .to(target2,{opacity:1,duration:0.3,ease:"ease-in"})
                    .to(target2,{y:0,opacity:1,duration:0.7,ease:"ease-in",delay:-0.3})
                } else
                if(windowSize.innerWidth < 1400) {
                    tl.current = gsap.timeline()
                    .to(target,{position:"fixed",x:ose.left,y:ose.top,display:"block",opacity:1,duration:0,ease:"ease-in"})
                    
                    .to(target ,{width:"100vmax",height:"100vmax",x:"600px",y:-100,duration:0.6,ease:"ease-in"})
                    .to(target2,{display:"flex",duration:0,ease:"ease-in"})
                    .to(target2,{opacity:1,duration:0.3,ease:"ease-in"})
                    .to(target2,{y:0,opacity:1,duration:0.7,ease:"ease-in",delay:-0.3})
                } else if (windowSize.innerWidth > 1500){
                    tl.current = gsap.timeline()
                    .to(target,{position:"fixed",x:ose.left,y:ose.top,display:"block",opacity:1,duration:0,ease:"ease-in"})
                    
                    .to(target ,{width:"100vmax",height:"100vmax",x:"800px",y:-100,duration:0.6,ease:"ease-in"})
                    .to(target2,{display:"flex",duration:0,ease:"ease-in"})
                    .to(target2,{opacity:1,duration:0.3,ease:"ease-in"})
                    .to(target2,{y:0,opacity:1,duration:0.7,ease:"ease-in",delay:-0.3})
                } else {
                    tl.current = gsap.timeline()
                    .to(target,{position:"fixed",x:ose.left,y:ose.top,display:"block",opacity:1,duration:0,ease:"ease-in"})
                    
                    .to(target ,{width:"100vmax",height:"100vmax",x:"400px",y:-100,duration:0.6,ease:"ease-in"})
                    .to(target2,{display:"flex",duration:0,ease:"ease-in"})
                    .to(target2,{opacity:1,duration:0.3,ease:"ease-in"})
                    .to(target2,{y:0,opacity:1,duration:0.7,ease:"ease-in",delay:-0.3})
                }
                  
                    
                    
        },app);
        return () => ctx.revert();
       }
     
       const handleFeww = (target,target2,target3) => {
        const ctx = gsap.context(() => {
            const element = document.getElementById(circleId);
              
                
               
                const ose = element.getBoundingClientRect();
                tl.current = gsap.timeline()
                .to(target2,{y:100,opacity:0,duration:0.2,ease:"ease-out"})
                .to(target2,{opacity:0,duration:0.3,ease:"ease-out"})
                
                .to(target ,{width:"15px",height:"15px",x:ose.left,y:ose.top,duration:0.7,ease:"ease-in"})
                
                .to(target,{display:"none",duration:0,ease:"ease-in",delay:0}) 
                
               
                .to(target2,{display:"none",duration:0,ease:"ease-in"})
                  
                    
                    
        },app);
        return () => ctx.revert();
       }
      
    return (
        <>
        <div className="circle-show-wrapper">
            
            <div ref={app}>
                <div  onClick={() => handleFew(boxRef.current,textBoxRef.current,textRef.current)}  id={circleId} style={{backgroundColor:color}}  className="circleShow">
                </div> 
                <div  ref={boxRef} id={mainCircleId} className='inCircle' style={{backgroundColor:color}}></div>
                <div id={boxId} ref={textBoxRef} className='text-wrap'>
                <h4 id={textId} ref={textRef}  >{text}</h4>
                <div  onClick={() => handleFeww(boxRef.current,textBoxRef.current,textRef.current)}  className='close-cir'>
                    <CloseCircle/>
                </div>
                 </div>
            </div>
           
            
         
        </div>
        </>
    )
}

export default CircleShow;