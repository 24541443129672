import TrueAndFalse from "../trueorfalse/true-or-false.component";
import FalseAndTrue from "../trueorfalse/false-or-true.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import { useState } from "react";
const ExerciceOne = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(1,4,user.uid);
        getUser();
        navigate("../Socio-economicadvantagesofLRBT")
    }

    const [exerc1,setExerc1] = useState(false)
    const [exerc2,setExerc2] = useState(false)
    return (
        <>
        <BreadCrumb 
            chapterTitle="Local Resource Based Technology" 
            subTitle="Exercise"
            chapterLink="LocalResourceBasedTechnology"
            subLink="exercice-1"
            prevLink="LocalResource-based(LRB)WorkMethods"
            nextLink="Socio-economicadvantagesofLRBT"
            chapterNumber="2"
            />
            <h1>Exercise</h1>
            <div onClick={() => setExerc1(true)}>
            <FalseAndTrue 
                question='Local Resource Based Technologies only aim to employ a large number of local workers, and are not directly related to construction activities.'
                answer ="Local Resource Based Technologies aim to employ a large number of local workers, and are directly related to construction activities."
                />
            </div>
            <div onClick={() => setExerc2(true)}><FalseAndTrue 
                question='No equipment is permitted on an LRBT construction site'
                answer ="Equipments are permitted on an LRBT construction site"
                /></div>
            
                {
                    exerc1 && exerc2 ? <button onClick={handleUpdates}>Mark As Completed</button> : null
                }
               
            
        </>
    )
}

export default ExerciceOne;