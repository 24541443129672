import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const Exampleofsomelabourproductivitynorms = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(3,7,user.uid);
        getUser();
        navigate("../Examples:Applicationoftaskworkandpieceworksystem")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Task Rates and Productivity Norms" 
            subTitle="Examples of some labour productivity norms"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="Exampleofsomelabourproductivitynorms"
            prevLink="exercise-3"
            nextLink="Examples:Applicationoftaskworkandpieceworksystem"
            chapterNumber='4'
            />
        <h1>Here are example of some labour productivity norms</h1>
        <p>Some task rate examples are shown in the above table. While these can be a good general indication, it should be noted that each bidder should base their rates on their own experience and contextual background for the project.</p>
        <p>In some cases, the semi-skilled or skilled worker can be a group leader/foreman – so his/her task rate is calculated as the sum of all the task rates of the workers he supervises</p>
        <p>i.e. Leader Task Rate = Unskilled Task Rate x Number of Workers in the Group</p>
        <img src={require('../../assets/labour-productivity-1.jpg')} className="boq-ch3-img" alt=""  />
        <p>In other cases, the semi-skilled or skilled worker is a specialist in a specific trade, with a higher pay than unskilled workers, but he doesn’t supervise anyone. In this case, his/her task rate is calculated based on the amount of work he can achieve individually per day</p>
        <img src={require('../../assets/labour-productivity-2.jpg')} className="boq-ch3-img"  alt=""  />
        <p>The equipment task rate is the quantity of an activity that can be achieved per day per equipment. <br />i.e. A small concrete mixer can cover 10 – 12 m<sup>3 </sup>of concrete per day, setting its task rate.</p>
        <p>P.S. Hand tools are estimated as a percentage on top of labour cost since this is a miscellaneous item. The percentage is to be added while costing (to be covered in the URA session)</p>
        <img src={require('../../assets/labour-productivity-3.jpg')} className="boq-ch3-img" alt=""  />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}


export default Exampleofsomelabourproductivitynorms;