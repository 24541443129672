
import './auth.styles.scss'
import { Routes,Route,Outlet} from 'react-router-dom';
import SignInForm from "../../components/sign-in-form/sign-in-form.component";
import SignUpForm from "../../components/sign-up-form/sign-up-form.component";
import PersonalInfo from '../../components/profile-info-form/profile-info-form.component';
import AuthBanner from "../../components/auth-banner/auth-banner.component";
import ResetPassword from '../../components/reset-password/reset-password.component';
import VerifyEmail from '../../components/verify-email/verify-email.component';
import SendVerifyEmail from '../../components/send-verification/send-verification.component';
const Auth = () => {
    return (
        <div className="auth-wrapper">
        <AuthBanner/>
        <Routes>
          <Route index element={<SignInForm/>}/>
          <Route path='sign-up' element={<SignUpForm/>}/>
          <Route path='personal-info' element={<PersonalInfo/>}/>
          <Route path='reset-password' element={<ResetPassword/>}/>
          <Route path='verify-email' element={<VerifyEmail/>}/>
          <Route path='send-verify-email' element={<SendVerifyEmail/>}/>
        </Routes>
        <Outlet/>
        </div>
    )
}

export default Auth;