import {ReactComponent as RedPoly} from '../../assets/polygon.svg';
import BlueShowText from '../blue-show-text/blue-show-text.component';
import './labour-management-lrb.styles.scss';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import ShowText from '../show-text/show-text.component';
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const LabourmanagementforLRBworks = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(3,0,user.uid);
        getUser();
        navigate("../IncentiveschemesforLRBworks")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Task Rates and Productivity Norms" 
            subTitle="Labour management for LRB works"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="LabourmanagementforLRBworks"
            prevLink="../LabourManagementandWorkPlan/quiz"
            nextLink="IncentiveschemesforLRBworks"
            chapterNumber='4'
            />
            <h1>Labour management for LRB works</h1>
            <p>Human resources are the main pillar for LRBT projects, and the proper management of the labour force is not to be undermined.</p>
            <p>Planning and costing are based on the potential productivity of each worker per day, so it is very important to meet the full potential when implementing the works in order to stay within cost and timeframe.</p>
            <p>This should not overshadow the importance of decent work standards, and the obligations to remain within these guidelines i.e. work intensity and schedules should still be within decent norms.</p>
            <h3 className='labour-h3'><RedPoly className='labour-poly'/>Applying local resource-based methods means not only to employ more labourers, but also manage the labour-force and works effectively and efficiently. </h3>
            <ShowText 
            title='Introduction of appropriate incentive schemes, i.e. production instead of time based methods, i.e. task work, piece work' 
            color="#BEDCFA"
            textColor="#230050"
            
            />
            <ShowText 
            title='Detailed daily site planning and reporting of work activities and resources.' 
            color="#BEDCFA"
            textColor="#230050"
            />
             <ShowText 
            title='Managing the labour force on site including instructing and on-the-job training' 
            color="#BEDCFA"
            textColor="#230050"
            />
            <ShowText 
            title='Effective inclusion of decent work conditions to labour employment and daily site operations' 
            color="#BEDCFA"
            textColor="#230050"
            />
            <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default LabourmanagementforLRBworks;