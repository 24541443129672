import BigRectangle from "../big-rectangle/big-rectangle.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const RiskAssessment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,3,user.uid,4);
        getUser();
        navigate("../DeterminingtheRisk")
    }
    return (
        <>
        <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Risk Assessment"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="RiskAssessment"
            prevLink="PersonalProtectiveEquipment"
            nextLink="DeterminingtheRisk"
            chapterNumber='6'
            />
        <h1>Risk Assessment</h1>
        <p>The main purpose of OSH is the management of occupational risks. The below five step risk assessment method is a simple approach that has been endorsed globally to manage risks</p>
        <BigRectangle
                numbre="1"
                title="Identify the hazard"
                color="#AB3B77"
                textColor="white"
/>
<BigRectangle
                numbre="2"
                title="Decide who might be harmed and how"
                color="#FB636F"
                textColor="white"
/>
<BigRectangle
                numbre="3"
                title="Evaluate the risks and decide on precautions"
                color="#3264C8"
                textColor="white"
/>
<BigRectangle
                numbre="4"
                title="Record findings and implement them"
                color="#69E4E4"
                textColor="#230050"
/>
<BigRectangle
                numbre="5"
                title="Review the assessment and update regularly if needed"
                color="#FAB428"
                textColor="#230050"
/>
<button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default RiskAssessment;