import SquareShow from "../square-show/square-show.component";
import './apply-lrbt.styles.scss';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import { useState } from "react";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const ApplyLrbt = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [score,setScore ] = useState(0)
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(1,11,user.uid);
        getUser();
        navigate("../quiz")
    }
    const [exerc1, setExerc1] = useState(0);
    return (
        <>
        <BreadCrumb
        chapterTitle="Local Resource Based Technology" 
        subTitle="When to apply LRB"
        chapterLink="Socio-economic advantages of LRBT"
        subLink="WhentoapplyLRBTimportantconsiderationsatplanningstage"
        prevLink="EmploymentIntensiveInfrastructureProgrammeinLebanon"
        nextLink="quiz"
        chapterNumber="2"
        />
        <h1>When to apply LRB</h1>
        <h2>Important considerations during the planning stage</h2>
        <p>Is it technically possible in terms of expected activities?
           <br /> Which approach would be less expensive? 
           <br /> Is the project site remote, or close to potential labour and local resources?
           <br /> Are there factors that challenge the project implementation?</p>
           <div className="squares-holder" onClick={() => setExerc1(exerc1=> exerc1+ 1)}>
            
           <SquareShow color="#FAB428" title="Technical feasibility" textColor="#230050" showTextBox={true}/>
          
           <SquareShow  color="#FB636F" title="Economic feasibility" textColor="white" showTextBox={false}/>
           <SquareShow color="#3264C8" title="Availability of labour" textColor="white" showTextBox={true}/>
           <SquareShow color="#AB3B77" title="Project implementation capacity" textColor="white" showTextBox={false}/>
           <SquareShow color="#70B450" title="Competent contractors" textColor="white" showTextBox={true}/>
           <SquareShow color="#69E4E4" title="Availability of local resources" textColor="#230050" showTextBox={false}/>
           </div>
           {
            exerc1 >= 3 ? <button onClick={handleUpdates}>Mark As Completed</button> : null
           }
           
        </>
    )
}

export default ApplyLrbt;