import { useState } from "react";
import { useSelector } from "react-redux";
import { quizAnswer } from "../../utils/firebase.utils";

import { selectCurrentUser,selectUserInfo } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { updateChapters ,startChapters, updateGrade } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Attempt = () => {
    
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] =useState(false);
    const [showHints, setShowHints] = useState(false);
    const [hideButton,setHideButton] = useState(false);
    const userInfo = useSelector(selectUserInfo)
    const navigate = useNavigate();
    const questions = [
        {
        id: 0,
        question : "A well organized LRB project with trained supervisors is",
        answer:"",
        score: 100/4,
        checked: false,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint:false,
        multi: false,
        options: [
            {id:0, text:"Slow",checked:false ,name:"same",reter:false },
            {id:1, text:"Expensive",checked:false,name:"same"},
            {id:2, text:"Below specifications",checked:false,name:"same"},
            {id:3, text:"On par with conventional construction methods",checked:false,name:"same"},
            ]
    },
    {
        id:1,
        question : "LRBT projects involve",
        answer:[],
        score:100/4,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"Ample employment of local beneficiaries",checked:false,name:"Ample employment of local beneficiaries" },
            {id:1, text:"The use of heavy machinery if preferred by the contractors",checked:false,name:"The use of heavy machinery if preferred by the contractors" },
            {id:2, text:"The use of local materials and implementation of decent work standards",checked:false,name:"The use of local materials and implementation of decent work standards" },
            {id:3, text:"The support of light equipment",checked:false,name:"The support of light equipment" },
        ]
    },
    {
        id:2,
        question : "Specific LRBT environmental practices can be implemented through",
        answer:[],
        score:100/4,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"Precise and accurate work using hand tools instead of heavy machinery",name:"Precise and accurate work using hand tools instead of heavy machinery",checked:false },
            {id:1, text:"The use of local materials instead of imported products", name:"The use of local materials instead of imported products",checked:false },
            {id:2, text:"The adoption of bioengineering activities for sustainable infrastructure solutions", name:"The adoption of bioengineering activities for sustainable infrastructure solutions",checked:false },
            {id:3, text:"The inclusion of large-scale renewable energy solutions regardless of the investment cost",name:"The inclusion of large-scale renewable energy solutions regardless of the investment cost",checked:false },
        ]
    },
    {
        id:3,
        question : "Capacity building is achieved by the LRBT projects through",
        answer:[],
        score:100/4,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"The construction of vocational training schools",checked:false, name:"same1" },
            {id:1, text:"Provision of theoretical and on the job training opportunities",checked:false,name:"same1" },
            {id:2, text:"Distribution of educational pamphlets within communities",checked:false,name:"same1" },
            
    ]
    }
        ]
    const [answers , setAnswers] = useState(questions);

    const handleChange = async (id,answer,idd) =>  {
        await setAnswers(questions => 
            questions.map(obj => {
                if (obj.id === id){
                    if(!obj.multi){
                        obj.answer = answer
                        obj.options.map((bjj => {
                            if(bjj.id===idd){
                                bjj.checked = true
                            }
                            else{
                                bjj.checked = false
                            }
                        }))
                    }else{
                       if(!obj.answer.includes(answer)){
                        obj.answer.push(answer)
                       
                       }else {
                        
                        for (var i=obj.answer.length-1; i>=0; i--) {
                            if (obj.answer[i] === answer) {
                                obj.answer.splice(i, 1);
                                // break;       //<-- Uncomment  if only the first term has to be removed
                            }
                        }
                       }
                       
                        
                        obj.options.map((bjj) => {
                            
                            if(bjj.id === idd){
                                if(bjj.checked){
                                    bjj.checked = false
                                }else{
                                    bjj.checked = true
                                }
                            }
                        })
                    }
                   
                  
                }
               
                return obj;
                
            })
            )
            
     }
     
     
    
     const dispatch = useDispatch();
     const user = useSelector(selectCurrentUser)
     const getUserr = async () => {
         const userr = await getUsers(user.uid);
         dispatch(setUserInfo(userr));
       }
         const handleUpdates = () => {
         updateChapters(1,12,user.uid);
         getUserr();
     }
   
    const getUser = async () => {
        const quizTitle = "LocalResourceBasedTechnology";
        const userr = await quizAnswer(quizTitle);
        const a3 = answers.map((t1) => ({ ...t1, ...userr.find((t2) => t2.id === t1.id) }));
        a3.map( async (obj)  =>  {
           const od = obj.id;
            if(obj.id == od){
                if(!obj.multi){
                    if(obj.answer === obj.answers){
                        setScore(score => score + obj.score);
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:true}
                                }
                            return obj;
                                
                            })
                            )
                    } else{
                        if(obj.answer===""){
                            setAnswers(questions => questions.map((item) => {
                                if(item.id===od){
                                    
                                    item.hint= "You didnt select any option"
                                    item.showHint= false
                                }
                                return item
                            }))
                        }
                    }
                }else{
                    if(areEqual(obj.answer,obj.answers)){
                        
                        setScore(score => score + obj.score);
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:true}
                                }
                            return obj;
                                
                            })
                            )
                    }else{
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:false,hint:"You didnt select all that applies"}
                                }
                            return obj;
                                
                            })
                            )
                    }
                }
            }
               
               
            setShowScore(true);
            setShowHints(true);
            
        })
        
      }
    const handleSubmit = async (e) => {
        e.preventDefault();
      await   getUser();
         window.scrollTo(0, 0);
         setHideButton(true)
      
    }
    

    useEffect( () => {
        if(score==0){
        return
        }else{
            updateGrade(user.uid,score,1)
        }
    
        if (score >= 75) {
            
            if(userInfo.chapters[1].status === "completed"){
                return
            }else {
                handleUpdates();

            try{
                startChapters(1,2,user.uid);
            }catch(error) {
                
            }
            }
            
         getUserr();
        }
    },[score])
    
    const resetQuiz = () => {
        setShowScore(false);
        setShowHints(false);
        setScore(0)
        setHideButton(false)
        setAnswers(questions => 
            questions.map(obj => {
                if(!obj.multi){
                    obj.answer = ""
                }else{
                    obj.answer = []
                }
                obj.options.map((item) => {
                    item.checked = false
                })
                 return obj
            }
            
            )
            
            )
           
    }       

function areEqual(arr1, arr2)
    {
        let N = arr1.length;
        let M = arr2.length;
 
        // If lengths of array are not equal means
        // array are not equal
        if (N != M)
            return false;
 
        // Sort both arrays
        arr1.sort();
        arr2.sort();
 
        // Linearly compare elements
        for (let i = 0; i < N; i++)
            if (arr1[i] != arr2[i])
                return false;
 
        // If all elements were same.
        return true;
    }
    
    return (
        <div>
            
            {
               showScore ?
                <div className={ score >= 75 ? "scoreBox passed" : "scoreBox failed"}>
                    {score >= 75 ? "Congratulations!  You passed!" : "Try again once you are ready"}
                    <br /> Grade received : <span>{(Math.round(score * 100) / 100).toFixed(2)}%</span> 
                    to pass 75% or higher
                    {score >= 75 ? <button className="next-item" onClick={() => navigate("../../LabourManagementandWorkPlan/TypicalsiteorganizationforLRBworks")}>Go to next item</button> : <button className="try-again" onClick={resetQuiz}>Try Again</button> }
                    
                </div> : null
            }
            
            
           
            <form className="attempt" onSubmit={handleSubmit}>
                {answers.map((item) => 
                <>
                <h3>{item.question} {item.multi ? "(select all that apply)" : null}</h3>
                
                {item.options.map((option) => 
                <>
                <input  checked={option.checked ? "checked" : null}  onChange={() => handleChange(item.id,option.text,option.id)} type={!item.multi ? "radio" : "checkbox"} name={option.name} id={option.text} value={option.answer}  /><label for={option.text}>{option.text}</label><br />
               
                </>
                )}
                {showHints ? 
                <div>
                    {item.showHint ? <div className="correct"><h4>Correct</h4></div> : 
                                     <div className="incorrect"><h4>Incorrect</h4> </div>}
                </div> 
                : null
                }
                
                </>
               
                )}
                {!hideButton ?  <button type="submit">Submit Answers</button> : null}
               
            </form>
        </div>
    )
}

export default Attempt;