import { useState } from "react";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const ExerciceTwo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,12,user.uid);
        getUser();
        navigate("../IndirectCosts")
    }
    const [answer1,setAnswer1] = useState(null);
    const [answer2, setAnswer2] = useState(null)
    const [answer3, setAnswer3] = useState(null)
    const [answer4, setAnswer4] = useState(null)
    const [finalResult,setFinalResult] = useState(null);
    const [showfinalResult,setshowFinalResult] = useState(null);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(answer1 && answer3 && answer4 && !answer2){
            setFinalResult(true);
        } else {
            setFinalResult(false);
        }
       setshowFinalResult(true)
      
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Exercise"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="exercise-3"
            prevLink="TotalDirectCost"
            nextLink="IndirectCosts"
            chapterNumber='5'
            />
        <h1>Exercise</h1>
        <h2>When pricing material cost, (select all that apply)</h2>
        <form className="exercice-form" onSubmit={handleSubmit}>
        <input onClick={() => setAnswer1(true)}   type="checkbox" name="You should calculate the total amount of material needed for your project then divide by the total quantity" id="You should calculate the total amount of material needed for your project then divide by the total quantity" value="You should calculate the total amount of material needed for your project then divide by the total quantity"  /><label for="You should calculate the total amount of material needed for your project then divide by the total quantity">You should calculate the total amount of material needed for your project then divide by the total quantity</label><br />
        <input onClick={() => setAnswer2(true)}   type="checkbox" name="You should only calculate the amount needed per unit of a specific activity" id="You should only calculate the amount needed per unit of a specific activity" value="You should only calculate the amount needed per unit of a specific activity"  /><label for="You should only calculate the amount needed per unit of a specific activity">You should only calculate the amount needed per unit of a specific activity</label><br />
        <input onClick={() => setAnswer3(true)}   type="checkbox" name="You should include the cost of transport and delivery to site" id="You should include the cost of transport and delivery to site" value="You should include the cost of transport and delivery to site"  /><label for="You should include the cost of transport and delivery to site">You should include the cost of transport and delivery to site</label><br />
        <input onClick={() => setAnswer4(true)}   type="checkbox" name="You don’t have to include a detailed breakdown of needed materials" id="You don’t have to include a detailed breakdown of needed materials" value="You don’t have to include a detailed breakdown of needed materials"  /><label for="You don’t have to include a detailed breakdown of needed materials">You don’t have to include a detailed breakdown of needed materials</label><br />
        <button type="submit">Submit</button>
        </form>
        {
        showfinalResult ?  finalResult ? 
            <div>
            <h2>Well Done!</h2>
            <button onClick={handleUpdates}>Mark As Completed</button> 
        </div> :  <div>
            <h2>Well No!</h2>
            <h3>To properly price material cost you should calculate the total amount of material needed for your project then divide it by the total quantity, include the cost of transport and delivery to site, and without having the need to include a detailed breakdown of needed materials</h3>
            <button onClick={handleUpdates}>Mark As Completed</button> 
        </div> : null
        }
        </>
    )
}
export default ExerciceTwo;