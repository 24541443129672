import ReactPlayer from "react-player/youtube";
import {ReactComponent as PlayIcon} from '../../assets/play.svg';
import { useState } from "react";
import { updateChapterss } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const SSOVideo = () => {
    
    const [time, setTime] = useState('');
    const [play ,setPLay] = useState(false);
    const [videoEnd, setVideoEnd] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,0,user.uid,2);
        getUser();
        navigate("../RoleofILOsSocialSafeguardOfficer")
    }
    
    const handleProgress = async (item) => {
        
      setTime(item.playedSeconds);
       
       if(time >= 6){
        setPLay(false);
       }
    }
    return (
      <>
      <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="SSO Video"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="SSOVideo"
            prevLink="DecentWorkConditions"
            nextLink="RoleofILOsSocialSafeguardOfficer"
            chapterNumber='6'
            />
     
        <div className="video-wrapper">
            <h1>SSO Video</h1>
             <ReactPlayer 
        playing={play}
        onEnded={() => setVideoEnd(true)}
        controls={true}
        
        onProgress={handleProgress}
        url='https://www.youtube.com/watch?v=s7Op2woLNdM&ab_channel=InternationalLabourOrganization'
        config={{
    youtube: {
      playerVars: { showinfo: 1 }
    }
  }}
        
        />
        {
            !videoEnd ? undefined :  <div className="video-end">
                
                <h4>Up Next</h4>
            <h2>What is the International Labour Organization ?</h2>
            <button onClick={handleUpdates}>Mark As Completed</button>
        </div>
        }
       
        </div>
        </>
    )
}

export default SSOVideo;