import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const MaterialCost = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,10,user.uid);
        getUser();
        navigate("../TotalDirectCost")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Material Cost"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="MaterialCost"
            prevLink="exercise-2"
            nextLink="TotalDirectCost"
            chapterNumber='5'
            />
        <h1>Material Cost</h1>
        <p>A full understanding of the materials required for the BoQ activity is essential to break them down into raw materials and accommodate their respective costs</p>
        <h2>Quantities of Materials Required for 1 m<sup>3 </sup>of Concrete</h2>
        <img src={require('../../assets/material-cost-1.jpg')} style={{marginBottom:"30px"}} className="boq-ch3-img" alt=""  />
        <img src={require('../../assets/material-cost-two.jpg')} className="boq-ch3-img" alt=""  />
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default MaterialCost;