import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const Compliance = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,7,user.uid,7);
        getUser();
        navigate("../quiz-1")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Facilitation through training"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="Compliance"
            prevLink="Facilitationthroughtraining"
            nextLink="quiz-1"
            chapterNumber='6'
            />
        <h1>Compliance</h1>
        <ShowText
            title="Why"
            color="#AB3B77"
            textColor="white"
            text="• To ensure the implementation and application of Social and Environmental Safeguards"
            
        />
        
        <ShowText
            title="When"
            color="#3264C8"
            textColor="white"
            text="• Mobilization"
            text2="• Project Implementation"
        />
        
        
        <ShowText
            title="How"
            color="#69E4E4"
            textColor="#230050"
            text="• Mobilization Checklist completed by SSO"
            text2="• Meetings with stakeholders"
            text3="• SSO supervision throughout implementation"
            text4="• Periodic Compliance Checklist completed by EIIP designated official"
        />
         
        
        <button onClick={handleUpdates}>Mark As Completed</button>

        </>
    )
}

export default Compliance;