import ShowText from "../show-text/show-text.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const EnvironmentalSafeguardsProvisions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,1,user.uid,7);
        getUser();
        navigate("../SocialandEnvironmentalSafeguards")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Environmental Safeguards Provisions"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="EnvironmentalSafeguardsProvisions"
            prevLink="Quiz"
            nextLink="SocialandEnvironmentalSafeguards"
            chapterNumber='6'
            />
        <h1>Environmental Safeguards Provisions</h1>
        <p>A matrix of all activities that might pose an impact to the environment should be prepared during the project planning and design phase, as well as continuously updated during the implementation and operation and maintenance phases.
The risk will be analysed based on the likelihood of occurrence and the severity of impact, according to which mitigation measures are to be developed.
</p>
        <ShowText 
            title="Activity"
            color="#AB3B77"
            textColor="white"
            text="• Planning and Design  phase"
            text2="• Construction"
            text3="• Operation  and Maintenance"
        />
        
        <ShowText 
            title="Impact"
            color="#FB636F"
            textColor="white"
            text="• Soil pollution"
            text2="• Biodiversity"
            text3="• Air pollution"
            text4="• Water pollution (aquifers and nearby water bodies)"
            text5="• Noise"
        />
        
        <ShowText 
            title="Risk Analysis"
            color="#3264C8"
            textColor="white"
            text="• Impact vs likelihood of occurrence"
        />
        
        <ShowText 
            title="Mitigation measures"
            color="#69E4E4"
            textColor="#230050"
            text="• Planning and Design phase"
            text2="• Construction"
            text3="• Operation and Maintenance"
        />

        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default EnvironmentalSafeguardsProvisions;