import { createAction } from "../../utils/reducer/reducer.utils"
import { USER_ACTION_TYPES } from "./user.types"


export const setCurrentUser = (user) => 
    createAction(USER_ACTION_TYPES.SET_CURRENT_USER,user);

export const setUserInfo = (user) => 
    createAction(USER_ACTION_TYPES.SET_USER_INFO, user);

    export const setAllUsers = (user) => 
    createAction(USER_ACTION_TYPES.SET_ALL_USERS, user);