import RedPolyList from "../red-poly-list/red-poly-list.component";

import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapterss } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import './workers-responsibility.styles.scss';
import { gsap } from "gsap";
import { useState, useLayoutEffect,useRef,useEffect } from "react";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const WorkersResponsibility = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapterss(5,7,user.uid,4);
        getUser();
        navigate("../ReportingAccidentsonSite")
    }
    const list1 = [
        {
            id:0,
            text:"OSH will function properly without proper joint committees and participatory OSH arrangements"
        }
    ]
    const list2 = [
        {
            id:0,
            text:"Co-operate with their employer in the fulfilment of the obligations placed upon them and follow Occupational Safety and Health measures guidelines"
        },
        {
            id:1,
            text:"Report to their supervisor any situation that poses danger to their safety or health"
        }
    ]
    const [reversed,setReversed] = useState(false);
   const tl = useRef();
   const app = useRef();
   const [canClick ,setCanClick ] = useState(true)
   const [canClickk ,setCanClickk ] = useState(true)
   const handleFew = () => {
    setCanClick(false)
    const ctx = gsap.context(() => {
      
        tl.current = gsap.timeline({
            paused:true
        })
        tl.current = gsap.timeline()
        .to("#red-circle-r",{display:"none",opacity:0,duration:0.1})
        .to("#blue-semi-circle",{borderTopRightRadius:0,borderTopLeftRadius:0,duration:0,ease:"ease-in"})
            .to("#blue-semi-circle",{height:"100%",width:"100%",borderTopRightRadius:0,borderTopLeftRadius:0,duration:0.5,left:0,top:0,duration:0.5,ease:"ease-in"})
            
            
            .to("#yes-answer",{opacity:0,display:"none"})
            .to("#well-no",{opacity:1,display:"block"})
            .to("#well-no",{opacity:0,display:"none",delay:0.3})
            .to("#no-answer",{opacity:1,display:"block"})
            .to("#workers-res-holder",{height:"auto"})
            
    },app);
    return () => ctx.revert();
   }
   const handleFeww = () => {
    setCanClickk(false)
    const ctx = gsap.context(() => {
      
        tl.current = gsap.timeline({
            paused:true
        })
        tl.current = gsap.timeline()
        .to("#blue-semi-circle",{display:"none",opacity:0,duration:0.1})
        .to("#red-circle-r",{borderBottomRightRadius:0,borderBottomLeftRadius:0,duration:0,ease:"ease-in"})
            .to("#red-circle-r",{height:"100%",width:"100%",borderBottomRightRadius:0,borderBottomLeftRadius:0,duration:0.5,left:0,top:0,ease:"ease-in"})
            
            .to("#yes-answer-r",{opacity:0,display:"none"})
            .to("#well-no-r",{opacity:1,display:"block"})
            .to("#well-no-r",{opacity:0,display:"none",delay:0.3})
            .to("#no-answer-r",{opacity:1,display:"block"})
            .to("#workers-res-holder",{height:"auto"})
            
    },app);
    return () => ctx.revert();
   }
   
    return ( 
        <>
         <BreadCrumb 
            chapterTitle="Environment and Social Safeguard..." 
            subTitle="Workers' Responsibility"
            chapterLink="EnvironmentandSocialSafeguardFrameworkandSocialSafeguardOfficer"
            subLink="WorkersResponsibility"
            prevLink="ImplementationofOccupationalSafetyHealth"
            nextLink="ReportingAccidentsonSite"
            chapterNumber='6'
            />
        <h1>Workers' Responsibility</h1>
        <RedPolyList list={list1}/>
        <h2>What do you think about it</h2>
        <div ref={app}>
        <div  className="workers-res-holder" id="workers-res-holder">
            <div onClick={canClick ? handleFew : null} id="blue-semi-circle" className="blue-half-circle">
                <h3 id="yes-answer">Yes <br /> completely</h3>
                <h1 id="well-no">Well <br /> No !</h1>
                <h2 id="no-answer">No, OSH will not function properly without proper joint committees and participatory OSH arrangements;
</h2>
               
            </div>
            <div onClick={canClickk ? handleFeww : null} id="red-circle-r" className="red-half-circle">
            <h3 id="yes-answer-r">No <br /> Certainly</h3>
                <h1 id="well-no-r">Well <br /> Yes !</h1>
                <h2 id="no-answer-r">Yes, OSH will  function properly with proper joint committees and participatory OSH arrangements;
</h2>
            </div>
        </div>
        </div>
        
        <RedPolyList list={list2}/>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default WorkersResponsibility;