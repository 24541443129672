import TrueAndFalse from "../trueorfalse/true-or-false.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
import { useState } from "react";
const ExerciseFour = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,14,user.uid);
        getUser();
        navigate("../UnitRate")
    }
    const [exerc1,setExerc1]= useState(false)
    const [exerc2,setExerc2]= useState(false)
    return (
        <>
         <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Exercise"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="exercise-4"
            prevLink="IndirectCosts"
            nextLink="UnitRate"
            chapterNumber='5'
            />
        <h1>Exercise</h1>
        <div onClick={() => setExerc1(true)}><TrueAndFalse
            question="Company costs are to be spread among several projects, over the period of completion of the priced project"
            answer="Company costs are to be spread among several projects, over the period of completion of the priced project"
        /></div>
        <div onClick={() => setExerc2(true)}> <TrueAndFalse
            question="Indirect costs should be added as a percentage to all unit rates regardless of the type of activity"
            answer="Indirect costs should be added as a percentage to all unit rates regardless of the type of activity"
        /></div>
       {exerc1 && exerc2 ? <button onClick={handleUpdates}>Mark As Completed</button> : null}
        
        </>
    )
}

export default ExerciseFour;