import './show-text.styles.scss';

import { gsap } from "gsap";
import { useState,useRef} from "react";
const UpArrow = ({fill}) => {
    return (
        <svg width="8" height="10" viewBox="0 0 8 10"  xmlns="http://www.w3.org/2000/svg">
<path d="M7 4L4 1M4 1L1 4M4 1L4 9" stroke={fill} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


)
}
const ShowText = ({title,text,color,textColor,fontS,text2,text3,text4,text5,text6,text7}) => {
    const [showText,setShowText] = useState(false)
    const tl = useRef();
    const app = useRef();
    
    const handleFew = () => {
     const ctx = gsap.context(() => {
       
         tl.current = gsap.timeline({
             paused:true
         })
         tl.current = gsap.timeline()
         
         .to("#box",{height:"auto",opacity:1,duration:0.2,ease:"ease-in"})
         .to("#text",{display:"block",opacity:1,duration:0.4,ease:"ease"})
         .to("#arrow",{rotate:"0deg",duration:0.5,ease:"ease",delay:-0.7})
             
             
     },app);
     return () => ctx.revert();
    }
    const handleFeww = () => {
        const ctx = gsap.context(() => {
          
            tl.current = gsap.timeline({
                paused:true
            })
            tl.current = gsap.timeline()
            .to("#text",{display:"block",opacity:0,duration:0.1,ease:"ease"})
            .to("#box",{height:"50px",opacity:1,duration:0.2,ease:"ease"})
            .to("#arrow",{rotateZ:"180deg",duration:0.5,ease:"ease",delay:-0.5})
                
                
        },app);
        return () => ctx.revert();
       }
       const handleShow = async () => {
       await setShowText(!showText)
       if(showText) {
        handleFeww()
       }else{
        handleFew()
       }
       }
    return (
        <>
        <div style={{position:"relative"}} ref={app}>
        {text ? <div id="arrow" className='showText-butt'>
                <UpArrow  fill={color}/>
            </div> : null}
        <div  id="box" className={text ? "show-text-box" : "show-text-box mar"} style={{backgroundColor:color}} onClick={text ? handleShow : null}>
            <h4 className={text ? "mar" : ""} style={{color:textColor,fontSize:fontS}}>{title}</h4>
           
            {
                text  ? 
                <>
                 <p style={{color:textColor}} id='text' >{text}</p>  
                 {text2 ? <p style={{color:textColor}} id='text' >{text2}</p> : null}
                 {text3 ? <p style={{color:textColor}} id='text' >{text3}</p> : null}
                 {text4 ? <p style={{color:textColor}} id='text' >{text4}</p> : null}
                 {text5 ? <p style={{color:textColor}} id='text' >{text5}</p> : null}
                 {text6 ? <p style={{color:textColor}} id='text' >{text6}</p> : null}
                 {text7 ? <p style={{color:textColor}} id='text' >{text7}</p> : null}
                </>
               
                
                : ""
            }
           
        
        </div>
        </div>
        
        </>
    )
}

export default ShowText;