import FormInput from "../form-input/form-input.component";
import { Link } from "react-router-dom";
import Button from "../button/button.component";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createAuthUserWithEmailAndPassword , createUserDocumentFromAuth} from "../../utils/firebase.utils";
import { setCurrentUser } from "../../store/user/user.action";
const defaultFormFields = {
    displayName:'',
    email:'',
    password:"",
}
const SignUpForm = () => {
 const navigate = useNavigate();
 const [errorMessage,setErrorMessage] = useState(null);
 const [formFields, setFormFields] = useState(defaultFormFields);
 const {displayName,email,password} = formFields;
 const resetFormFields = () => {
    setFormFields(defaultFormFields);
 }

 const handleSubmit = async (event) => {
    event.preventDefault();

    try {
        const {user} = await createAuthUserWithEmailAndPassword(email,password);
        await createUserDocumentFromAuth(user,displayName);
        await setCurrentUser(user);
        navigate('../../personal-info');
    }catch(error){
        if(error.code === "auth/email-already-in-use"){
            setErrorMessage("Email already in use")
         }
         if(error.code === "auth/weak-password"){
            setErrorMessage("Password should be at least 6 characters")
         }
        
        
    }
 }

 const handleChange = (event) => {
    const {name , value } = event.target;
    setFormFields({...formFields,[name]:value})
 }
    return (
        <>
       
         <div className="sign-in-wrapper">
            <div>
                <h1>Create An Account</h1>
                <span>
                    <p>Already have an account ?</p>
                    <Link to='/'>Log in</Link>
                </span>
                <form onSubmit={handleSubmit}>
                    <FormInput label='Full Name' placeholder="Full Name" type='text' onChange={handleChange} name='displayName' value={displayName} required/>
                    <FormInput label='Email address' placeholder="Please enter your email" type='email' onChange={handleChange} name="email" value={email} required/>
                    <FormInput label='Password' placeholder="Password" type='password' onChange={handleChange} name="password" value={password} required/>
                    <p style={{color:"red",fontWeight:"700"}}>{errorMessage}</p>
                    <div className='forgot-holder'>
                   
                     <Button children='Create' style={{width:'100%'}}/>
                    </div>
                   
                </form>

            </div>
         
        </div>
        
        </>
    )
}

export default SignUpForm;