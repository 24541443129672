import {ReactComponent as RedPoly} from '../../assets/polygon.svg';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from '../breadcrumb/breadcrumb.component';
const DirectCost = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,5,user.uid);
        getUser();
        navigate("../LabourCost")
    }
    return (
        <>
         <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Direct Cost"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="DirectCost"
            prevLink="exercise-1"
            nextLink="LabourCost"
            chapterNumber='5'
            />
        <h1>Direct Cost</h1>
        <h2>Direct cost consist of:</h2>
        <h3><RedPoly className='task-rates-poly'/>A. Labour costs:</h3>
        <ul>
            <li><p>Skilled / semi-skilled labour</p></li>
            <li><p>Un-skilled labour</p></li>
        </ul>
        <p>To calculate labour cost we need to know</p>
        <ul>
            <li><p>Daily wage rate for labourers ( skilled, semi-skilled and unskilled)</p></li>
            <li><p>Labour task rate or productivity of labour</p></li>
        </ul>
        <h3><RedPoly className='task-rates-poly'/>B. Cost of Equipment</h3>
        <ul>
            <li><p>Equipment task rate</p></li>
            <li><p>Daily rate if equipment is owned or daily hire rate</p></li>
        </ul>
        <h3><RedPoly className='task-rates-poly'/>C. Material</h3>
        <ul>
            <li><p>Purchase cost plus, handling and transport to site</p></li>
            
        </ul>
        <button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default DirectCost;