import { useNavigate } from "react-router-dom";
import './quiz-template.styles.scss';
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const QuizTemplate = ({chapterNumber,quizLink,chapterGrade,toPass,prevLink,nextLink,chapterTitle,subTitle,chapterLink,subLink}) => {
    const navigate = useNavigate()
    return (
        <>
        <BreadCrumb 
            chapterTitle={chapterTitle} 
            subTitle={subTitle}
            chapterLink={chapterLink}
            subLink={subLink}
            prevLink={prevLink}
            nextLink={nextLink}
            chapterNumber={chapterNumber}
            />
      
        <div className="quiz-template-wrapper">
            <h1>Chapter {chapterNumber} Quiz</h1>
            <div className="submit-assignment-wrapper">
                <h2>Submit your assignment</h2>
                <button  onClick={() => navigate(`../${quizLink}`)}>Start assignment</button>
            </div>
            <div className="grade-wrapper">
                <div className="receive-grade-wrapper">
                <h3>Receive grade</h3>
                <div className="grade-to-pass-wrapper">
                <h3>To pass</h3>
                <p>{toPass} or higher</p>
                </div>
                </div>
                <div className="grade-holder">
                   <h2>Your grade</h2> 
                   <h2>{chapterGrade === undefined ? '-' : chapterGrade}%</h2>
                   
                </div>
                
            </div>
        </div>
        </>
    )
}

export default QuizTemplate;