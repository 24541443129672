import RedPolyList from "../red-poly-list/red-poly-list.component";
import {ReactComponent as RedPoly} from '../../assets/polygon.svg';
import { selectCurrentUser } from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { useNavigate } from "react-router-dom";
import { updateChapters } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import BreadCrumb from "../breadcrumb/breadcrumb.component";
const IndirectCosts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const getUser = async () => {
        const userr = await getUsers(user.uid);
        dispatch(setUserInfo(userr));
       
      }
        const handleUpdates = () => {
        updateChapters(4,13,user.uid);
        getUser();
        navigate("../exercise-4")
    }
    const list1 = [
        {
            id:0,
            text:"Supervision cost"
        },
        {
            id:1,
            text:"Company cost"
        },
        {
            id:2,
            text:"Risk allowance"
        },
    ]
    const list2 = [
        {
            id:0,
            text:"Salary of engineer and his/her transport, inclusive"
        },
        {
            id:1,
            text:"Salary of supervisor and his or her transport, inclusive"
        }
    ]
    const list3 = [
        {
            id:0,
            text:"Staff salaries→total costs for permanent staff"
        },
        {
            id:1,
            text:"Property→office, workshop, store→hire charges→operational costs (electricity, water, telephone,waste collection, taxes, etc.)"
        },
        {
            id:2,
            text:"Insurances→any type of insurance required to run the business"
        },
        {
            id:3,
            text:"Vehicles→owner plus operational costs"
        },
    ]
    const list4 = [
        {
            id:0,
            text:"Sudden increase of price on materials"
        },
        {
            id:1,
            text:"Delays due to bad weather"
        },
        {
            id:2,
            text:"Shortage of particular materials"
        },
        {
            id:3,
            text:"Unexpected technical problems"
        },
        {
            id:4,
            text:"Inaccurate Estimating, etc"
        },
    ]
    return (
        <>
        <BreadCrumb 
            chapterTitle="Unit Rate Analysis and Pricing/BoQ for LRB Works" 
            subTitle="Indirect Cost"
            chapterLink="TaskRatesandProductivityNorms"
            subLink="IndirectCosts"
            prevLink="exercise-3"
            nextLink="exercise-4"
            chapterNumber='5'
            />
        <h1>Indirect Cost</h1>
        <p>The indirect costs are not linked to a specific project activity (or BoQ item) but are the necessary overhead and relevant expenses to complete the project.</p>
        <h2>The indirect costs consist of:</h2>
        <RedPolyList list={list1}/>
        <h2>Supervision cost</h2>
        <p>These costs cover supervisory staff and their means of transport through out the contract duration</p>
        <RedPolyList list={list2}/>
        <h2>Company cost includes</h2>
        <p>These are basic costs that a company goes through during the contract duration to be able to run the business These costs should be equally shared with other projects within the company</p>
        <RedPolyList list={list3}/>
        <h2>Risk allowance includes</h2>
        <RedPolyList list={list4}/>
        <p>Generally the risk allowance is about 1-3 % of the direct cost depending on the context and general circumstances</p>
        <p>Indirect costs are calculated as a total sum to be incurred during the full contract period, and then split into additional percentages on top of individual unit rates</p>
        <img src={require('../../assets/indirect-cost-1.jpg')} className="boq-ch3-img" alt=""  />
        <button><a  href={require("../../assets/indirect-cost-1.jpg")} download>Download File</a></button>
        <p>The total contract direct cost doesn’t have to be assumed, since it is possible for the bidder to calculate this number based on the BoQ
All the stated numbers are for the sake of examples and are irrelevant to any current bid</p>
        <p>Total Company cost + supervision cost 			(USD)				38,998
Assumed a total Direct cost of this project		(USD)				500,000
So, the Supervision + company cost			(%)						8
Assumed Risk allowance						(%)						3
Total In-direct cost							(%)						11
Add Profit 									(%)					       7-10 % 
For each unit rate – to be added as a percentage of the direct costs
</p>
<button onClick={handleUpdates}>Mark As Completed</button>
        </>
    )
}

export default IndirectCosts;