import { useState } from "react";
import { useSelector } from "react-redux";
import { quizAnswer } from "../../utils/firebase.utils";

import { selectCurrentUser ,selectUserInfo} from "../../store/user/user.selector";
import { getUsers } from "../../utils/firebase.utils";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/user/user.action";
import { updateChapterss ,startChapters, updateGrade } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


const Attempt = () => {
    
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] =useState(false);
    const [showHints, setShowHints] = useState(false);
    const [hideButton,setHideButton] = useState(false);
    const navigate = useNavigate();
    const userInfo = useSelector(selectUserInfo)
    const questions = [
        {
        id: 0,
        question : "Select the sentence that is correct about the Environmental and Social Safeguard Framework (ESSF).",
        answer:"",
        score: 100/10,
        checked: false,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint:false,
        multi: false,
        options: [
            {id:0, text:"The ESSF is a guidance document that the EIIP works under, but it is not mandatory for the contractors to comply with, and it is used just for information",checked:false,name:"same"},
            {id:1, text:"The ESSF is the responsibility of the ILO and the EIIP team to implement, the contractor has nothing to do with it",checked:false,name:"same"},
            {id:2, text:"The ESSF governs all EIIP Projects, it is contractually binding, and mandatory for the contractors to work on its basis",checked:false,name:"same"},
            
          
            ]
    },
    {
        id:1,
        question : "Decent Work is",
        answer:"",
        score:100/10,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"A job opportunity that provides a fair income, safe and secure working environment, supports legality of status, ensures a signature of employment contract, provides capacity building and skills development, and promotes social dialogue",checked:false,name:"same1" },
            {id:1, text:"A job opportunity that prioritizes the recruitment of women over men, provides flexible working arrangements for women and overworks men",checked:false,name:"same1" },
            {id:2, text:"A job opportunity that obliges workers to work overtime and without breaks, allowing them to make more money",checked:false,name:"same1" },
            
          
        ]
    },
    {
        id:2,
        question : "Under a decent work opportunity transportation is provided to workers, but the fees are deducted from their salaries",
        answer:"",
        score:100/10,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"Correct",name:"same2",checked:false },
            {id:1, text:"Incorrect", name:"same2",checked:false },
            
          
        ]
    },
    {
        id:3,
        question : "The Social Safeguard Officers (SSO) on the EIIP Project sites do not have any decision-making role, and are there only to provide guidance and advise, compliance with the same are optional depending on the contractor’s team and assessment",
        answer:"",
        score:100/10,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"True, the contractor does not have to abide by any guidance provided by the SSO.",checked:false, name:"same3" },
            {id:1, text:"False, the SSO has a leading role on site in terms of providing instructions on the ESSF, and in cases on non-compliance the SSO alerts the central team to take necessary measures",checked:false,name:"same3" },
       
           
            
    ]
    },{
        id:4,
        question : "Select the correct statement on Outreach Mechanism",
        answer:"",
        score:100/10,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"The contractor should only coordinate with the municipality for outreaching for workers",name:"same4",checked:false },
            {id:1, text:"The contractor should only post job advertisements at the municipal building and depend solely on the word of mouth to outreach for the workers", name:"same4",checked:false },
            {id:2, text:"The contractor with the support of the Social Safeguard Officer is supposed to utilize different outreach outlets, make sure that job advertisements are posted in different locations, make sure the application process is inclusive to reach out for the most vulnerable people within the vicinity of the project", name:"same4",checked:false },
          
        ]
    }
    ,{
        id:5,
        question : "Select the correct statement on Occupational Safety and Health",
        answer:"",
        score:100/10,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"Occupational Safety and Health is optional, especially that workers might not feel comfortable wearing their PPEs while working",name:"same5",checked:false },
            {id:1, text:"The basics of construction state that Occupational Safety and Health measures should not be put in place at the onset of the project; on the contrary, initiating the discussion on safety measures should start after works start to properly assess the risks while workers are working", name:"same5",checked:false },
            {id:2, text:"Occupational safety and health is a priority, an OSH plan is to be submitted with the bidding documents, safety measures should be put in place before work starts and before workers are mobilized on site. A risk assessment and safety plan should be regularly reviewed by an OSH focal point on site", name:"same5",checked:false },
          
        ]
    }
    ,{
        id:6,
        question : "Inclusive participation means that contractors are only responsible for achieving targets on employing women and employing persons with disability regardless of the type of task that is being conducted.",
        answer:"",
        score:100/10,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:false,
        options: [
            {id:0, text:"True – The contractor signs on a target quota, thus is only supposed to reach the target regardless of what the workers are doing on site.",name:"same6",checked:false },
            {id:1, text:"False – Inclusive participation is not only about reaching targets and numbers, but also about productivity of work and tasks conducted on site by women and persons with disabilities. Inclusive participation includes as well increase of employability to workers involved in the project", name:"same6",checked:false },
            
          
        ]
    }
    ,{
        id:7,
        question : "Select the correct statement/s on Grievance Mechanism",
        answer:[],
        score:100/10,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"The EIIP team share all grievances and complaints with the contractor and other stakeholders as part of being transparent",name:"The EIIP team share all grievances and complaints with the contractor and other stakeholders as part of being transparent",checked:false },
            {id:1, text:"The EIIP team treats all grievances and complaints with utmost confidentiality and shares the necessary information with relevant colleagues to address the issue", name:"The EIIP team treats all grievances and complaints with utmost confidentiality and shares the necessary information with relevant colleagues to address the issue",checked:false },
            {id:2, text:"The EIIP team protects all workers/beneficiaries that raise a grievance or a complaint from all types of retaliation", name:"The EIIP team protects all workers/beneficiaries that raise a grievance or a complaint from all types of retaliation",checked:false },
            
          
        ]
    }
    ,{
        id:8,
        question : "Select the correct statement/s on proper screening processes for social and environmental risks",
        answer:[],
        score:100/10,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"The social and environmental screening is done during the project identification and design phases",name:"The social and environmental screening is done during the project identification and design phases",checked:false },
            {id:1, text:"An Environmental screening is sent to the Ministry of Environment for approval prior to the start of any project", name:"An Environmental screening is sent to the Ministry of Environment for approval prior to the start of any project",checked:false },
            {id:2, text:"The screening is usually carried out once the project implementation phase kicks off", name:"The screening is usually carried out once the project implementation phase kicks off",checked:false },
            
          
        ]
    }
    ,{
        id:9,
        question : "Select the correct statement/s on proper implementation processes for social and environmental safeguards",
        answer:[],
        score:100/10,
        hint: "Incorrect. You may want to review the EIIP in Lebanon section",
        showHint: false,
        multi:true,
        options: [
            {id:0, text:"It is the responsibility of the contractor to implement the ESSF with no specific need to adhering to the EIIP Environmental and Social Management Plan",name:"It is the responsibility of the contractor to implement the ESSF with no specific need to adhering to the EIIP Environmental and Social Management Plan",checked:false },
            {id:1, text:"The designated EIIP official will monitor and document compliance by completing compliance check lists periodically and communicating with contractor and/or specific project partner on proper corrective measure", name:"The designated EIIP official will monitor and document compliance by completing compliance check lists periodically and communicating with contractor and/or specific project partner on proper corrective measure",checked:false },
            {id:2, text:"The ESSF facilitation process assists contractors and/or implementing agencies in understanding the proper compliance mechanisms and ensures that they are fully informed about the procedures specific to the EIIP projects", name:"The ESSF facilitation process assists contractors and/or implementing agencies in understanding the proper compliance mechanisms and ensures that they are fully informed about the procedures specific to the EIIP projects",checked:false },
            
          
        ]
    }
]
    const [answers , setAnswers] = useState(questions);

    const handleChange = async (id,answer,idd) =>  {
        await setAnswers(questions => 
            questions.map(obj => {
                if (obj.id === id){
                    if(!obj.multi){
                        obj.answer = answer
                        obj.options.map((bjj => {
                            if(bjj.id===idd){
                                bjj.checked = true
                            }
                            else{
                                bjj.checked = false
                            }
                        }))
                    }else{
                       if(!obj.answer.includes(answer)){
                        obj.answer.push(answer)
                       
                       }else {
                        
                        for (var i=obj.answer.length-1; i>=0; i--) {
                            if (obj.answer[i] === answer) {
                                obj.answer.splice(i, 1);
                                // break;       //<-- Uncomment  if only the first term has to be removed
                            }
                        }
                       }
                       
                        
                        obj.options.map((bjj) => {
                            
                            if(bjj.id === idd){
                                if(bjj.checked){
                                    bjj.checked = false
                                }else{
                                    bjj.checked = true
                                }
                            }
                        })
                    }
                   
                  
                }
               
                return obj;
                
            })
            )
            
     }
     
     
    
     const dispatch = useDispatch();
     const user = useSelector(selectCurrentUser)
     const getUserr = async () => {
         const userr = await getUsers(user.uid);
         dispatch(setUserInfo(userr));
       }
         const handleUpdates = () => {
         updateChapterss(5,8,user.uid,7)
         getUserr();
     }
   
    const getUser = async () => {
        const quizTitle = "Environmental&SocialSafeguardFramework";
        const userr = await quizAnswer(quizTitle);
        const a3 = answers.map((t1) => ({ ...t1, ...userr.find((t2) => t2.id === t1.id) }));
        a3.map( async (obj)  =>  {
           const od = obj.id;
            if(obj.id == od){
                if(!obj.multi){
                    if(obj.answer === obj.answers){
                        setScore(score => score + obj.score);
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:true}
                                }
                            return obj;
                                
                            })
                            )
                    } else{
                        if(obj.answer===""){
                            setAnswers(questions => questions.map((item) => {
                                if(item.id===od){
                                    
                                    item.hint= "You didnt select any option"
                                    item.showHint= false
                                }
                                return item
                            }))
                        }
                    }
                }else{
                    if(areEqual(obj.answer,obj.answers)){
                        
                        setScore(score => score + obj.score);
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:true}
                                }
                            return obj;
                                
                            })
                            )
                    }else{
                        setAnswers(questions => 
                            questions.map(obj => {
                                if (obj.id === od){
                                    return {...obj, showHint:false,hint:"You didnt select all that applies"}
                                }
                            return obj;
                                
                            })
                            )
                    }
                }
            }
               
               
            setShowScore(true);
            setShowHints(true);
            
        })
        
      }
    const handleSubmit = async (e) => {
        e.preventDefault();
      await   getUser();
         window.scrollTo(0, 0);
         setHideButton(true)
      
    }
    

    useEffect( () => {
        if(score==0){
        return
        }else{
            updateGrade(user.uid,score,5)
        }
    
        if (score >= 75) {
            if(userInfo.chapters[5].status === "completed"){
                return
            }else{
                handleUpdates();

                try{
                    startChapters(5,6,user.uid);
                }catch(error) {
                    
                }
            }
           
         getUserr();
        }
    },[score])
    
    const resetQuiz = () => {
        setShowScore(false);
        setShowHints(false);
        setScore(0);
        setHideButton(false)
        setAnswers(questions => 
            questions.map(obj => {
                if(!obj.multi){
                    obj.answer = ""
                }else{
                    obj.answer = []
                }
                obj.options.map((item) => {
                    item.checked = false
                })
                 return obj
            }
            
            )
            
            )
           
    }       

function areEqual(arr1, arr2)
    {
        let N = arr1.length;
        let M = arr2.length;
 
        // If lengths of array are not equal means
        // array are not equal
        if (N != M)
            return false;
 
        // Sort both arrays
        arr1.sort();
        arr2.sort();
 
        // Linearly compare elements
        for (let i = 0; i < N; i++)
            if (arr1[i] != arr2[i])
                return false;
 
        // If all elements were same.
        return true;
    }
    
    return (
        <div>
            
            {
               showScore ?
                <div className={ score >= 90 ? "scoreBox passed" : "scoreBox failed"}>
                    {score > 90 ? "Congratulations!  You passed!" : "Try again once you are ready"}
                    <br /> Grade received :  <span>{(Math.round(score * 100) / 100).toFixed(2)}%</span>  
                    to pass 90% or higher
                    {score >= 90 ? <button className="next-item" onClick={() => navigate("../../ContractAdministration&ParticularConditions/ContractAdministration")}>Go to next item</button> : <button className="try-again" onClick={resetQuiz}>Try Again</button> }
                    
                </div> : null
            }
            
            
           
            <form className="attempt" onSubmit={!hideButton ? handleSubmit : null}>
                {answers.map((item) => 
                <>
                <h3>{item.question} {item.multi ? "(select all that apply)" : null}</h3>
                
                {item.options.map((option) => 
                <>
                <input  checked={option.checked ? "checked" : null}  onChange={() => handleChange(item.id,option.text,option.id)} type={!item.multi ? "radio" : "checkbox"} name={option.name} id={option.text} value={option.answer}  /><label for={option.text}>{option.text}</label><br />
               
                </>
                )}
                {showHints ? 
                <div>
                    {item.showHint ? <div className="correct"><h4>Correct</h4></div> : 
                                     <div className="incorrect"><h4>Incorrect</h4> </div>}
                </div> 
                : null
                }
                
                </>
               
                )}
               {!hideButton ?  <button type="submit">Submit Answers</button> : null}
            </form>
        </div>
    )
}

export default Attempt;