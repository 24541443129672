import FormInput from "../form-input/form-input.component";
import { useState, useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './profile.styles.scss';
import {useSelector} from 'react-redux';
import {selectUserInfo,selectCurrentUser } from "../../store/user/user.selector";
import {ReactComponent as BlueLogo} from '../../assets/logo-blue.svg';
import { updateProfileInfo } from "../../utils/firebase.utils";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
const Profile = () => {
    const userr = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    useEffect(() => {
        if(!userr){
            navigate("../../")
        }else{
            if(userr.emailVerified == false){
                navigate("../../")
            }
        }
       
    },[userr])
    const inputRef = useRef(null);
    const printDocument = () => {
      html2canvas(inputRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p","mm","a4");
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("certificate.pdf");
      });
    };
const user = useSelector(selectUserInfo);

const defaultFormFields = {
    Occupation: user ? user.Occupation : "",
    experienceLevel : user ? user.experienceLevel : "",
    company: user ? user.company : "",
    educationDegree :user ? user.educationDegree : "",
    university: user ? user.university : "",
    field: user ? user.field : ""
}
const [formFields,setFormFields] = useState(defaultFormFields);

const {Occupation , experienceLevel , company ,educationDegree , university , field} = formFields;
  const handleChange = (event) => {
    const {name , value } = event.target;
    setFormFields({...formFields,[name]:value})
 }

 const handleUpdateInfo = (event) => {
    event.preventDefault();
    updateProfileInfo(Occupation , experienceLevel , company ,educationDegree , university , field,user.id)
 }
    return (
        <div className="profile-wrapper">
            <div className="profile-banner">
                <h1>{user ? user.displayName : ""}</h1>
                <p>Manage your info and check your certificate right here.</p>
            </div>
            <div className="certificate-wrapper">
                <h3>Certificate</h3>
                {
                    user.certificate ? 
                    <>
                    <div className="certificate-box" ref={inputRef}>
                        
                       <img  className="logo-cer" src={require("../../assets/certi-logo-2.png")} alt="" srcset="" />
                        <h1 style={{color:"#1E2DBE"}}>LRBT Training Certificate</h1>
                        <h1>{user ? user.displayName : ""}</h1>
                        <p>Has successfully completed the online, <br />
LRBT Training Certificate
</p>
                        <p>From <br /> {user.certificateDate}  <br /> To <br /> {user.certificateDateEnd}</p>
                        <img className="certificate-ban" src={require("../../assets/certificate-banner.png")} alt="" srcset="" />
                    </div> 
                    <button onClick={printDocument}>Download Certificate</button>
                    </>
                    :
                    <div className="certificate-holder">
                    <p>When you finish the course,you will find your certificate here.</p>
                </div>
                }
                
            </div>
            <div className="profile-info-holder">
                <h3>Profile Info</h3>
                <form onSubmit={handleUpdateInfo}>
                        <div className="cl-3-inputs">
                        <FormInput label='Occupation' placeholder={user ? user.Occupation : ""} type='text' onChange={handleChange} name='Occupation' value={Occupation}/>
                        <FormInput label='Experience Level' placeholder="Ex: Lead / Manager" type='text' onChange={handleChange} name='experienceLevel' value={experienceLevel}/>
                        <FormInput label='Company' placeholder="Ex: Lead / Manager" type='text' onChange={handleChange} name='company' value={company}/>
                        </div>
                        <div className="cl-3-inputs">
                        <FormInput label='Education Degree' placeholder="Ex: Bachelor" type='text' onChange={handleChange} name='educationDegree' value={educationDegree}/>
                        <FormInput label='University' placeholder="Ex: NDU" type='text' onChange={handleChange} name='university' value={university}/>
                        <FormInput label='Field or major' placeholder="Ex: Engineering" type='text' onChange={handleChange} name='field' value={field}/>
                        </div>
                        <button>Edit Profile</button>
                </form>
            </div>
            
        </div>
    )
}

export default Profile;